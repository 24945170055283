import { Checkbox, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import React from "react"
import styled, { useTheme } from "styled-components"

export type CheckboxHugeFieldsProps = {
  checked: boolean
  handleChange(checked: boolean): Promise<void> | void
  title: string
  description: string
  children?: React.ReactNode
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  border-radius: 8px;
  gap: 10px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const CheckboxHugeField = ({
  checked,
  handleChange,
  title,
  description,
  children,
}: CheckboxHugeFieldsProps) => {
  const theme = useTheme()

  return (
    <Container>
      <div style={{ height: "min-content" }}>
        <Checkbox
          checked={checked}
          onChange={(_, checked) => handleChange(checked)}
        />
      </div>
      <Wrapper>
        <Typography
          variant="body3"
          variantColor={theme.colors.neutral[30]}
          weight={600}
        >
          {title}
        </Typography>
        <Typography
          variant="body4"
          variantColor={theme.colors.neutral[50]}
          weight={400}
          style={children ? { marginBottom: 10 } : undefined}
        >
          {description}
        </Typography>

        {children}
      </Wrapper>
    </Container>
  )
}
