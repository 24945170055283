import {
  DatePicker,
  dayjs,
  Divider,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { ContentProps } from "./Content"
import { Wrapper } from "./styles"
import { useTheme } from "styled-components"
import { useTranslation } from "react-i18next"
import { Upload } from "@components/common"
import { useFormik } from "formik"
import * as yup from "yup"
import i18n from "@i18n"
import { DocumentValidation } from "./DocumentValidation"
import React from "react"
import { trpc } from "@api/client"
import { ResignationExam, ResignationExamStatus } from "bff/src/types/hiring"
import { Nullable } from "bff/src/types/shared"

const validationSchema = yup.object({
  date: yup
    .string()
    .required(i18n.t("page.resignation.exam.requiredFieldError")),
  file: yup
    .object({
      key: yup.string().required(),
      path: yup.string().required(),
      value: yup.string(),
      type: yup.string().required(),
    })
    .required(i18n.t("page.resignation.exam.requiredFieldError")),
})

export const AlreadyHaveContent = ({ card }: ContentProps) => {
  const theme = useTheme()
  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignation.exam.manualAso",
  })

  const formik = useFormik({
    initialValues: {
      date: card?.resignationExam?.date,
      file: card?.resignationExam?.file,
    },
    validationSchema,
    onSubmit: async () => {},
  })

  const { mutateAsync: updateCard } =
    trpc.card.updateResignationCard.useMutation()

  const handleUpdateCard = React.useCallback(
    async (params: Partial<Nullable<ResignationExam>>) => {
      await updateCard({
        flowCardId: card._id,
        resignationExam: { status: ResignationExamStatus.manual, ...params },
      })
    },
    [],
  )

  return (
    <>
      <Wrapper>
        <div>
          <Typography
            variant="headline8"
            variantColor={theme.colors.neutral[30]}
          >
            {t("title")}
          </Typography>
          <Typography variant="body4" variantColor={theme.colors.neutral[50]}>
            {t("description")}
          </Typography>
        </div>

        <Upload
          value={formik.values.file}
          label={t("uploadLabel")}
          accept={["jpg", "png", "pdf"]}
          folder={`${card.companyId}/${card.employeeId}/${card._id}/resignation/aso/${card.name}`}
          module="employee-pii"
          customFilename={`Exame_demissional_${card.name}`}
          onUpload={async (file) => {
            formik.handleChange({
              target: {
                id: "file",
                value: file,
              },
            })

            await handleUpdateCard({
              file,
            })
          }}
          onRemove={async () => {
            formik.handleChange({
              target: {
                id: "file",
                value: undefined,
              },
            })

            await handleUpdateCard({
              file: null,
            })
          }}
        />
      </Wrapper>

      <Divider orientation="horizontal" />

      <Wrapper>
        <div>
          <Typography
            variant="headline8"
            variantColor={theme.colors.neutral[30]}
          >
            {t("dateTitle")}
          </Typography>
          <Typography variant="body4" variantColor={theme.colors.neutral[50]}>
            {t("dateDescription")}
          </Typography>
        </div>

        <DatePicker
          label={t("inputDateLabel")}
          placeholder={t("inputDateLabel")}
          value={formik.values.date}
          onDateChange={async (value) => {
            const date = value && dayjs(value).isValid() ? dayjs(value) : null
            if (!date) return
            const parsedValue = dayjs(value).isValid()
              ? date?.toISOString()
              : null

            formik.handleChange({
              target: { id: "date", value: parsedValue },
            })
          }}
          error={formik?.touched?.date && Boolean(formik?.errors?.date)}
          helperText={formik?.touched?.date && formik?.errors?.date?.toString()}
          fullWidth
          onBlur={() => {
            handleUpdateCard({
              date: formik.values.date ?? "",
            })
          }}
        />
      </Wrapper>

      {formik.values?.file && (
        <>
          <Divider orientation="horizontal" />
          <DocumentValidation card={card} canResend={false} />
        </>
      )}
    </>
  )
}
