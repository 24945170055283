import { MouseEventHandler, useState } from "react"
import {
  Typography,
  Icons,
  IconButton,
  MultiSelect,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2"

import { EditTemplateModal } from "./components/EditTemplateModal"

import { Flow } from "../../../../../types"

import { cloneDeep } from "lodash"

import {
  Container,
  StyledAccordion,
  TemplateWrapper,
  MultiSelectContainer,
  StyledLinkButton,
  LineText,
  TemplateCard,
  HelperText,
} from "./styles"
import { AutocompleteRenderInputParams } from "@mui/material"

type TemplateProps = {
  label: string
  _id: string
  customModelContractId?: string
}

interface ContractTemplateUploadProps {
  flowId: string
  hiringType: Flow["subcategory"]
  uploadComponent: React.ReactNode
  templates: TemplateProps[]
  templatesOptions: TemplateProps[]
  onTemplatesChanged: (templates: TemplateProps[]) => void
  previewButtonProps?: {
    onClick?: MouseEventHandler<HTMLButtonElement>
    disabled?: boolean
    loading?: boolean
  }
  candidateId: string
  modelMissingFields?: string[]
}

export const ContractTemplateUpload = ({
  flowId,
  hiringType,
  templates,
  templatesOptions,
  onTemplatesChanged,
  uploadComponent,
  candidateId,
  modelMissingFields = [],
}: ContractTemplateUploadProps) => {
  const [openEditModal, setOpenEditModal] = useState<{
    open: boolean
    modelId: string
    customModelContractId?: string
    hiringType: Flow["subcategory"]
  }>({
    open: false,
    modelId: "",
    customModelContractId: "",
    hiringType,
  })

  const handleCardClicked = (template: TemplateProps) => {
    setOpenEditModal({
      open: true,
      modelId: template._id,
      customModelContractId: template?.customModelContractId,
      hiringType,
    })
  }

  const defaultRenderInput = (params: AutocompleteRenderInputParams) => {
    return <TextField {...params} label="Selecionar modelos" />
  }

  return (
    <>
      <Container>
        <StyledAccordion
          defaultExpanded
          variant="default"
          title="Selecione os modelos de contratos"
        >
          <TemplateWrapper>
            <MultiSelectContainer>
              <MultiSelect
                label="Selecionar modelos"
                renderInput={defaultRenderInput}
                value={templates}
                onSelectChange={(_, value) => onTemplatesChanged(value)}
                isOptionEqualToValue={(option, value) =>
                  option._id === value._id
                }
                options={templatesOptions}
              />

              <StyledLinkButton
                variant="primary"
                onClick={() => {
                  window.open(`/flows/settings/${flowId}/contracts`, "_blank")
                }}
              >
                Gerenciar modelos de contrato
              </StyledLinkButton>
            </MultiSelectContainer>
            {templates.map((template) => {
              const error = modelMissingFields.includes(template._id)

              return (
                <div key={template._id}>
                  <TemplateCard
                    onClick={() => handleCardClicked(template)}
                    error={error}
                  >
                    <Typography variant="body3" style={{ fontWeight: "700" }}>
                      {template.label}
                    </Typography>
                    <IconButton
                      icon="IconPencil"
                      variant="line"
                      size="medium"
                    />
                  </TemplateCard>
                  {error && (
                    <HelperText variant="caption" tag="p">
                      <Icons
                        color="#FEA034"
                        name="IconInfoCircle"
                        size={18}
                        fill="transparent"
                        style={{ marginRight: "4px" }}
                      />
                      O contrato contém campos dinâmicos não preenchidos.
                      Edite-os antes de enviar.
                    </HelperText>
                  )}
                </div>
              )
            })}
          </TemplateWrapper>
        </StyledAccordion>
        <div>
          <LineText variant="body3" tag="p" style={{ color: "#83727D" }}>
            e/ou faça o upload de um documento
          </LineText>
        </div>
        <StyledAccordion
          defaultExpanded
          variant="default"
          title="Faça o upload de um ou mais contratos"
        >
          {uploadComponent}
        </StyledAccordion>
      </Container>
      <EditTemplateModal
        isOpen={openEditModal.open}
        modelId={openEditModal.modelId}
        hiringType={openEditModal.hiringType}
        customModelContractId={openEditModal.customModelContractId}
        candidateId={candidateId}
        onClose={() =>
          setOpenEditModal({
            open: false,
            modelId: "",
            customModelContractId: "",
            hiringType,
          })
        }
        onConfirm={(data) => {
          const customModelContractId = data?._id || ""
          const modelContractId = data?.modelContractId || ""
          const clone = cloneDeep(templates)

          const res = clone.map((c) => {
            if (c._id === modelContractId && !c.customModelContractId) {
              return { ...c, customModelContractId: customModelContractId }
            }
            return { ...c }
          })

          onTemplatesChanged(res)

          setOpenEditModal({
            open: false,
            modelId: "",
            customModelContractId: "",
            hiringType,
          })
        }}
      />
    </>
  )
}
