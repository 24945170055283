import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const localStyles = {
  linkButton: {
    alignSelf: "center",
  },
}
