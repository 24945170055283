import { trpc } from "../api/client"

export const useBFF = () => {
  const setCandidateField = trpc.candidate.setField.useMutation()

  const hiringMoveColumn = trpc.card.changeHiringColumn.useMutation()

  const hiringUpdateCardStatus = trpc.card.updateHiringCardStatus.useMutation()

  const hiringResetRefusedFields = trpc.hiring.resetProposal.useMutation()

  const hiringAcceptProposal = trpc.hiring.acceptProposal.useMutation()

  const hiringRefuseProposal = trpc.hiring.refuseProposal.useMutation()

  const hiringNextColumn = trpc.card.moveHiringNextColumn.useMutation()

  const createToken = trpc.helpers.createToken.useMutation()

  const sendProposalLetter = trpc.hiring.sendProposalLetter.useMutation()

  return {
    hiring: {
      moveColumn: hiringMoveColumn,
      updateCardStatus: hiringUpdateCardStatus,
      resetRefusedFields: hiringResetRefusedFields,
      acceptProposal: hiringAcceptProposal,
      refuseProposal: hiringRefuseProposal,
      nextColumn: hiringNextColumn,
    },
    candidate: {
      sendProposalLetter,
      setCandidateField,
    },
    resignation: {},
    token: {
      createToken,
    },
  }
}
