import { useNavigate } from "react-router-dom"
import { ConfirmationModal } from "../../../../../components"
import { CandidateSignInformation } from "../../../../../types"
import { Trans, useTranslation } from "react-i18next"

interface ModalIneligibleCandidates {
  isOpen: boolean
  handleClose: () => void
  candidates: CandidateSignInformation[]
}
export const ModalIneligibleCandidates = ({
  isOpen,
  handleClose,
  candidates,
}: ModalIneligibleCandidates) => {
  const navigate = useNavigate()

  const handleCancel = () => {
    const countEligibleCandidates = candidates.filter((c) => c.isElegibleToSign)
    if (countEligibleCandidates?.length === 0) {
      handleClose()
      navigate(-1)
      return
    }
    handleClose()
  }

  const [t] = useTranslation("translations", {
    keyPrefix: "page.hiring.massSignature.ineligibleCandidatesModal",
  })

  return (
    <ConfirmationModal
      variant="primary"
      isOpen={isOpen}
      onClose={handleClose}
      confirmButton={{
        text: t("confirmButton"),
        onClick: () => handleCancel(),
      }}
      cancelButton={{
        text: t("cancelButton"),
        onClick: () => handleCancel(),
      }}
      title={t("title")}
      description={
        <Trans
          i18nKey={
            "page.hiring.massSignature.ineligibleCandidatesModal.description"
          }
          components={{ b: <b /> }}
          values={{
            numberOfCandidates:
              candidates.filter((candidate) => !candidate.isElegibleToSign)
                ?.length ?? 0,
          }}
        />
      }
    />
  )
}
