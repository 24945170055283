import React, { useState } from "react"

import {
  Button,
  Icons,
  Menu,
  MenuOption,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { CandidateMenu, Commentary } from "../../common"
import CardChecklist from "../Checklist/CardChecklist"

import { Flow, FlowCategory, HiringCard } from "../../../types"

import {
  Container,
  HeaderContainer,
  BodyContainer,
  HeaderWrapper,
  ContentWrapper,
  MenuWrapper,
  ActionsWrapper,
  Content,
  ChecklistPillButton,
  ResetPillButton,
} from "./styles"
import { ModalExportCandidateData } from "./ModalExportCandidateData"
import { CardStatus } from "./CardStatus"
import { hiringColumnsId } from "../../../mock/board"
import { ResetStepConfirmationModal } from "./ModalResetStep"
import { ModalExportCandidateReport } from "../Home/ModalExportCandidateReport"
import { useTranslation } from "react-i18next"
import { useTracking } from "@utils/useTracking"

interface CardTemplate {
  card: HiringCard
  flow: Flow
  children: React.ReactNode
}

type ResetStepState = {
  canReset: boolean
  isOpen: boolean
  confirmCallback?: () => Promise<void> | void
  isLoading?: boolean
}

export interface IHiringCardContext {
  setResetStep(params: Partial<ResetStepState>): void
}

export const hiringCardContext = React.createContext<IHiringCardContext>({
  setResetStep: () => {},
})

const HiringCardTemplate = ({ card, flow, children }: CardTemplate) => {
  const [modalExportData, setModalExportData] = useState<boolean>(false)
  const [modalExportCandidateReport, setModalExportCandidateReport] =
    useState<boolean>(false)
  const [displayChecklist, setDisplayChecklist] = useState<boolean>(true)
  const [resetStep, setResetStep] = useState<ResetStepState>({
    canReset: false,
    isOpen: false,
  })
  const [t] = useTranslation("translations", {
    keyPrefix: "components.hiringCard",
  })
  const tracking = useTracking()

  const columnInfo = flow.columns?.find(
    (column) => column._id === card.columnId,
  )

  if (!columnInfo) return null

  const inactivesChecklistColumns = [
    ...hiringColumnsId.done,
    ...hiringColumnsId.archived,
  ]
  const hasChecklist = !inactivesChecklistColumns.includes(card.columnId)
  const hasResetStep = [
    ...hiringColumnsId.admission,
    ...hiringColumnsId.signing,
  ].includes(card.columnId)

  const handleSetResetStep = (params: Partial<ResetStepState>) => {
    setResetStep((prev) => ({
      ...prev,
      ...params,
    }))
  }

  const contextValue: IHiringCardContext = {
    setResetStep: handleSetResetStep,
  }

  return (
    <Container>
      <HeaderContainer>
        <HeaderWrapper>
          <Typography variant="body3" style={{ color: "#83727D" }}>
            Fase atual
          </Typography>
          <Typography variant="headline5" style={{ fontWeight: 700 }}>
            {columnInfo.name}
          </Typography>

          <Typography
            variant="body3"
            style={{ color: "#83727D", fontWeight: 400, marginTop: 5 }}
          >
            {columnInfo.description}
          </Typography>

          <CardStatus card={card} />
        </HeaderWrapper>

        <HeaderWrapper>
          <Menu
            type="default"
            options={[
              {
                children: (
                  <MenuOption
                    label={t("exportMenu.candidateData")}
                    icon="IconFileExport"
                    onClick={() => {
                      tracking.trackEvent({
                        name: "hiring_clicked_open_export_candidate_data_modal",
                      })
                      setModalExportData(true)
                    }}
                  />
                ),
              },
              {
                children: (
                  <MenuOption
                    label={t("exportMenu.candidateReport")}
                    icon="IconFileExport"
                    onClick={() => {
                      tracking.trackEvent({
                        name: "hiring_clicked_open_export_candidate_report_modal",
                      })
                      setModalExportCandidateReport(true)
                    }}
                  />
                ),
              },
            ]}
          >
            <Button size="medium" variant="secondary" variantType="neutral">
              {t("exportMenu.label")}
            </Button>
          </Menu>
        </HeaderWrapper>
      </HeaderContainer>

      <BodyContainer>
        <ContentWrapper>
          <ActionsWrapper>
            {hasChecklist && (
              <ChecklistPillButton
                active={displayChecklist}
                onClick={() => setDisplayChecklist((prev) => !prev)}
              >
                <Icons
                  name="IconCheckbox"
                  fill="transparent"
                  style={{ marginRight: 5 }}
                />
                <Typography variant="body3" weight={700}>
                  {t("checklist")}
                </Typography>
              </ChecklistPillButton>
            )}

            {hasResetStep && (
              <ResetPillButton
                disabled={!resetStep.canReset}
                onClick={() => {
                  if (!resetStep.canReset) return
                  setResetStep((prev) => ({ ...prev, isOpen: true }))
                }}
              >
                <Icons
                  name="IconRotate2"
                  fill="transparent"
                  style={{ marginRight: 5 }}
                />
                <Typography variant="body3" weight={700}>
                  {t("buttons.resetStep")}
                </Typography>
              </ResetPillButton>
            )}
          </ActionsWrapper>

          {hasResetStep && resetStep.isOpen && resetStep.confirmCallback && (
            <ResetStepConfirmationModal
              isOpen={resetStep.isOpen}
              onClose={() =>
                setResetStep((prev) => ({ ...prev, isOpen: false }))
              }
              onConfirm={resetStep.confirmCallback}
              isLoading={resetStep.isLoading}
            />
          )}

          {displayChecklist && (
            <CardChecklist
              column={columnInfo}
              card={card}
              flowType={FlowCategory.HIRING}
              style={{ marginBottom: 20 }}
            />
          )}

          <hiringCardContext.Provider value={contextValue}>
            {Array.isArray(children) ? (
              children.map((child, index) => (
                <div
                  key={`hiring-content-child-${index}`}
                  style={{
                    marginBottom: index === children.length - 1 ? 0 : 20,
                  }}
                >
                  {child}
                </div>
              ))
            ) : (
              <Content>{children}</Content>
            )}
          </hiringCardContext.Provider>

          <Commentary
            card={card}
            flowType={FlowCategory.HIRING}
            style={{ marginTop: 20 }}
          />
        </ContentWrapper>

        <MenuWrapper>
          <CandidateMenu card={card} flow={flow} />
        </MenuWrapper>
      </BodyContainer>

      <ModalExportCandidateData
        isOpen={modalExportData}
        handleClose={() => setModalExportData(false)}
        candidateId={card.candidateId}
      />
      <ModalExportCandidateReport
        isOpen={modalExportCandidateReport}
        handleClose={() => setModalExportCandidateReport(false)}
        candidateId={card.candidateId}
      />
    </Container>
  )
}

export default HiringCardTemplate
