import { Dropzone } from "@flash-tecnologia/hros-web-ui-v2"
import {
  uploadFileToS3,
  uploadHelper,
} from "@flash-tecnologia/hros-web-utility"
import React, { ComponentProps } from "react"
import { UploadProps } from "."
import { useTranslation } from "react-i18next"
import { trpc } from "@api/client"
import dispatchToast from "@utils/dispatchToast"
import {
  SignedUrlCommandEnum,
  StorageGatewayModules,
} from "@customTypes/storage-gateway"

const TEN_MEGABYTES = 10 * 1024 * 1024

function isFileSizeValid(file: File) {
  return file.size <= TEN_MEGABYTES
}

export enum States {
  Idle = "idle",
  Loading = "loading",
  Error = "error",
  Preview = "preview",
}

type UseUploadProps = {
  error?: UploadProps["error"]
  value: UploadProps["value"]
  folder: UploadProps["folder"]
  onUpload: UploadProps["onUpload"]
  module: UploadProps["module"]
  awsUtilitiesModule?: UploadProps["awsUtilitiesModule"]
  customFileName?: UploadProps["customFileName"]
}

export const useUpload = ({
  error,
  value,
  folder,
  onUpload,
  module = StorageGatewayModules.Hiring,
  awsUtilitiesModule,
  customFileName,
}: UseUploadProps) => {
  const [t] = useTranslation("translations", { keyPrefix: "components.upload" })

  const [state, setState] = React.useState<States>(
    !!value ? States.Preview : States.Idle,
  )
  const [temporaryUploadInformation, setTemporaryUploadInformation] =
    React.useState({ progress: 0, archiveName: "" })

  const { mutateAsync: getPresignedUrlMutate } =
    trpc.helpers.getPreSignedUrl.useMutation()

  React.useEffect(() => {
    if (value && temporaryUploadInformation.progress === 100) {
      setTimeout(() => {
        setState(States.Preview)
        setTemporaryUploadInformation({ archiveName: "", progress: 0 })
      }, 3000)
    }
  }, [value, temporaryUploadInformation])

  React.useEffect(() => {
    if (state === States.Preview && !value) {
      setState(States.Idle)
    }
  }, [value, state])

  const handleChange: ComponentProps<typeof Dropzone>["onChange"] = async (
    files,
  ) => {
    if (files.length > 1) {
      dispatchToast({ content: t("errorMultipleFiles"), type: "error" })
      setState(States.Error)
      return
    }

    const file = files[0]
    if (!isFileSizeValid(file.file)) {
      dispatchToast({ content: t("errorArchiveSize"), type: "error" })
      setState(States.Error)
      return
    }

    setTemporaryUploadInformation((prev) => ({
      ...prev,
      archiveName: file.file.name,
    }))

    try {
      const fileExtension = file.file.name.split(".").pop()
      const fileName = customFileName
        ? `${Date.now()}-${customFileName}.${fileExtension}`
        : `${Date.now()}-${file.file.name}`
      const filePath = `${folder}/${fileName}`
      const { url } = await getPresignedUrlMutate({
        filePath,
        command: SignedUrlCommandEnum.PUT,
        module,
      })
      await uploadFileToS3({
        file: file.file,
        url,
        onProgress: (percentage) => {
          setTemporaryUploadInformation((prev) => ({
            ...prev,
            progress: percentage,
          }))
        },
      })
      await uploadHelper({
        key: folder,
        file: file.file,
        module: awsUtilitiesModule || "employee-pii",
        fileName,
      })
      onUpload?.({ path: filePath, name: file.file.name, size: file.file.size })
    } catch (err) {
      setState(States.Error)
      dispatchToast({ content: t("errorUpload"), type: "error" })
    }
  }

  const handleRetry = () => {
    setTemporaryUploadInformation({ progress: 0, archiveName: "" })
    setState(States.Idle)
  }

  React.useEffect(() => {
    if (error) setState(States.Error)
  }, [error])

  return {
    state,
    temporaryUploadInformation,
    setTemporaryUploadInformation,
    handleChange,
    handleRetry,
  }
}
