import {
  dayjs,
  TagDateFilter,
  TagFilter,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { FilterContainer } from "./styles"
import { FlowWithCards, HiringCard } from "../../../types"
import React, { CSSProperties } from "react"
import { getFromKey } from "../../../utils"
import { useTranslation } from "react-i18next"

type FilterTypes =
  | "candidate"
  | "leader"
  | "roles"
  | "status"
  | "step"
  | "hiringDate"

export type Filter = {
  entityKey: string | string[]
  values: string[]
}

type FilterOptions<T = string> = {
  [key in T as string]: Filter
}

type FilterOptionsAvailable = {
  [key in FilterTypes]?: boolean
}

type FilterParams = {
  flow: FlowWithCards<HiringCard>
  key: string | string[]
  value: any | any[]
}

export type ExternalFilterParams = Omit<FilterParams, "flow"> & {
  identifier: string
}

export interface FilterRef {
  resetFilters: () => void
  reapplyFilters: () => void
  applyExternalFilter: (params: ExternalFilterParams) => void
}

export interface FilterListProps {
  flow: FlowWithCards<HiringCard>
  handleUpdateFlow: (flow: FlowWithCards<HiringCard>) => void
  originalFlow: FlowWithCards<HiringCard>
  hideFilter?: FilterOptionsAvailable
  style?: CSSProperties
}

function handleFilter({
  flow,
  key,
  value,
}: FilterParams): FlowWithCards<HiringCard> {
  if (!value || value?.length === 0) return flow

  const filteredColumns = flow?.columns?.map((column) => {
    const filteredCards = (column.cards || []).filter((card) => {
      if (Array.isArray(key)) {
        let included = false
        key?.forEach((keyFromArray) => {
          if (Array.isArray(value)) {
            value.forEach((valueFromArray) => {
              if (getFromKey(keyFromArray, card)?.includes(valueFromArray))
                included = true
            })
          }

          if (getFromKey(keyFromArray, card)?.includes(value)) included = true
        })
        return included
      }

      if (Array.isArray(value)) {
        let included = false
        value.forEach((valueFromArray) => {
          if (getFromKey(key, card)?.includes(valueFromArray)) included = true
        })
        return included
      }

      if (key === "hiringDate" && value !== undefined && card.hiringDate) {
        const { from, to }: { from?: Date; to?: Date } = value
        const hiringDate = dayjs(card.hiringDate)

        let included = true
        if (from && hiringDate.isBefore(from)) included = false
        if (to && hiringDate.isAfter(to)) included = false
        return included
      }

      return getFromKey(key, card)?.includes(value)
    })

    return {
      ...column,
      cards: filteredCards,
    }
  })

  return {
    ...flow,
    columns: filteredColumns,
  }
}

function applyFilters(flow: FlowWithCards<HiringCard>, filters: FilterOptions) {
  const keys = Object.keys(filters)
  keys.forEach((key) => {
    const filter = filters[key]
    if (!filter || filter.values?.length === 0) return
    flow = handleFilter({
      flow: flow,
      key: filter.entityKey,
      value: filter.values,
    })
  })

  return flow
}

function removeDuplicatedLeaders(cards: HiringCard[]) {
  const grouped = cards.reduce<HiringCard[]>((prev, curr) => {
    if (!curr.leader) return prev
    const foundIndex = prev.findIndex(
      (card) => card.leader?.name === curr.leader?.name,
    )
    if (foundIndex === -1) return [...prev, curr]
    return prev
  }, [])
  return grouped.filter((card) => card.leader?.name && card.leader?._id)
}

function removeDuplicatedStatus(cards: HiringCard[]) {
  const grouped = cards.reduce<HiringCard[]>((prev, curr) => {
    if (!curr.status) return prev
    const foundIndex = prev.findIndex((card) => card.status === curr.status)
    if (foundIndex === -1) return [...prev, curr]
    return prev
  }, [])
  return grouped.filter((card) => card.status)
}

function removeDuplicatedRoles(cards: HiringCard[]) {
  const grouped = cards.reduce<HiringCard[]>((prev, curr) => {
    if (!curr.role?._id) return prev
    const foundIndex = prev.findIndex(
      (card) => card.role?._id === curr.role?._id,
    )

    if (foundIndex === -1) return [...prev, curr]
    return prev
  }, [])

  return grouped.filter((card) => card.role?._id)
}

function removeDuplicatedSteps(cards: HiringCard[]) {
  const grouped = cards.reduce<HiringCard[]>((prev, curr) => {
    if (!curr.columnId) return prev
    const foundIndex = prev.findIndex((card) => card.columnId === curr.columnId)

    if (foundIndex === -1) return [...prev, curr]
    return prev
  }, [])

  return grouped.filter((card) => card.columnId)
}

const initialState: FilterOptions<FilterTypes> = {
  candidate: {
    entityKey: "_id",
    values: [],
  },
  leader: {
    entityKey: "leader._id",
    values: [],
  },
  roles: {
    entityKey: "role._id",
    values: [],
  },
  status: {
    entityKey: "status",
    values: [],
  },
  step: {
    entityKey: "columnId",
    values: [],
  },
  hiringDate: {
    entityKey: "hiringDate",
    values: [],
  },
}

export const HiringFilter = React.forwardRef(
  (
    {
      flow,
      handleUpdateFlow,
      originalFlow,
      hideFilter,
      style,
    }: FilterListProps,
    ref: React.Ref<FilterRef>,
  ) => {
    const [filters, setFilters] =
      React.useState<FilterOptions<FilterTypes>>(initialState)
    const [externalFilters, setExternalFilters] = React.useState<FilterOptions>(
      {},
    )

    React.useImperativeHandle(
      ref,
      () => ({
        resetFilters: resetFilters,
        reapplyFilters: applyAllFilters,
        applyExternalFilter: handleExternalFilter,
      }),
      [],
    )

    React.useEffect(() => {
      applyAllFilters()
    }, [originalFlow, filters, externalFilters])

    React.useEffect(() => {
      if (!hideFilter) return
      const keys = Object.keys(hideFilter)
      keys.forEach((key) => {
        if (hideFilter[key] === false) return

        const filter = filters[key]
        if (filter.values?.length > 0) {
          setFilters((prev) => ({
            ...prev,
            [key]: {
              ...prev[key],
              values: [],
            },
          }))
        }
      })
    }, [hideFilter])

    const cards: HiringCard[] =
      flow?.columns?.reduce<HiringCard[]>((prev, curr) => {
        return curr.cards?.length > 0 ? [...prev, ...curr.cards] : prev
      }, []) || []

    const candidateOptions = cards.map((card) => ({
      value: card._id,
      label: card.name,
    }))

    const leaderOptions = removeDuplicatedLeaders(cards).map((card) => ({
      value: card.leader?._id as string,
      label: card.leader?.name as string,
    }))

    const rolesOptions = removeDuplicatedRoles(cards).map((card) => ({
      value: card.role?._id as string,
      label: card.role?.name as string,
    }))

    const statusOptions = removeDuplicatedStatus(cards).map((card) => ({
      value: card.status as string,
      label: card.status as string,
    }))

    const stepOptions = removeDuplicatedSteps(cards).map((card) => {
      const foundColumn = flow.columns?.find(
        (column) => column._id === card.columnId,
      )
      return {
        value: card.columnId,
        label: foundColumn?.name as string,
      }
    })

    function applyAllFilters() {
      const flowWithFilters = applyFilters(originalFlow, filters)
      const flowWithExternalFilters = applyFilters(
        flowWithFilters,
        externalFilters,
      )

      handleUpdateFlow(flowWithExternalFilters)
    }

    function resetFilters() {
      handleUpdateFlow(originalFlow)

      setFilters(initialState)
      setExternalFilters({})
    }

    function handleFilter(identifier: FilterTypes, value: any) {
      setFilters((prev) => ({
        ...prev,
        [identifier]: {
          ...prev[identifier],
          values: value,
        },
      }))
    }

    function handleExternalFilter(params: ExternalFilterParams) {
      setExternalFilters((prev) => ({
        ...prev,
        [params.identifier]: {
          entityKey: params.key,
          values: params.value,
        },
      }))
    }

    const [t] = useTranslation("translations", {
      keyPrefix: "components.hiringFilter",
    })

    return (
      <FilterContainer style={style}>
        <Typography variant="body3">Filtrar por</Typography>
        {!hideFilter?.candidate && (
          <TagFilter
            selectedOptions={filters.candidate?.values}
            onApply={(values) => handleFilter("candidate", values)}
            onClear={() => handleFilter("candidate", [])}
            variant="secondary"
            hasLeftIcon={false}
            filterLabel={t("candidate")}
            optionIconType="checkbox"
            options={candidateOptions}
          />
        )}
        {!hideFilter?.roles && (
          <TagFilter
            selectedOptions={filters.roles?.values}
            onApply={(values) => handleFilter("roles", values)}
            onClear={() => handleFilter("roles", [])}
            variant="secondary"
            hasLeftIcon={false}
            filterLabel={t("role")}
            optionIconType="checkbox"
            options={rolesOptions}
          />
        )}
        {!hideFilter?.leader && (
          <TagFilter
            selectedOptions={filters.leader?.values}
            onApply={(values) => handleFilter("leader", values)}
            onClear={() => handleFilter("leader", [])}
            variant="secondary"
            hasLeftIcon={false}
            filterLabel={t("manager")}
            optionIconType="checkbox"
            options={leaderOptions}
          />
        )}
        {!hideFilter?.status && (
          <TagFilter
            selectedOptions={filters.status?.values}
            onApply={(values) => handleFilter("status", values)}
            onClear={() => handleFilter("status", [])}
            variant="secondary"
            hasLeftIcon={false}
            filterLabel={t("status")}
            optionIconType="checkbox"
            options={statusOptions}
          />
        )}
        {!hideFilter?.step && (
          <TagFilter
            selectedOptions={filters.step?.values}
            onApply={(values) => handleFilter("step", values)}
            onClear={() => handleFilter("step", [])}
            variant="secondary"
            hasLeftIcon={false}
            filterLabel={t("step")}
            optionIconType="checkbox"
            options={stepOptions}
          />
        )}
        {!hideFilter?.hiringDate && (
          <TagDateFilter
            filterLabel={t("hiringDate")}
            onSubmit={(range) => handleFilter("hiringDate", range)}
            variant="secondary"
            hasLeftIcon={false}
          />
        )}
      </FilterContainer>
    )
  },
)
