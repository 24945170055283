import React from "react"
import { Container, EllipsisTypography, PreviewImage } from "./styles"
import { Skeleton } from "@mui/material"
import { UploadProps } from ".."
import {
  FileType,
  SignedUrlCommandEnum,
  StorageGatewayModules,
} from "@customTypes/storage-gateway"
import { Box } from "@components/common/atoms/Box"
import { trpc } from "@api/client"

type PreviewProps = {
  file: FileType
  children?: React.ReactNode
  module: StorageGatewayModules
  awsUtilitiesModule?: UploadProps["awsUtilitiesModule"]
}

export interface PreviewState {
  fileName?: string
  url?: string
}

export const previewContext = React.createContext<PreviewState>(
  {} as PreviewState,
)

export const Preview = ({
  file,
  module,
  children,
  awsUtilitiesModule = "employee-pii",
}: PreviewProps) => {
  const [url, setUrl] = React.useState<string | null>(null)

  const { mutateAsync: getPresignedUrlMutate } =
    trpc.helpers.getPreSignedUrl.useMutation({
      onError: () => {}, // swallow error when file is at AwsUtilities S3, remove when migrated
    })
  const { data: awsUtilitiesFile } = trpc.helpers.getS3File.useQuery({
    module: awsUtilitiesModule,
    path: file.path,
  })

  const nameOfArchive = React.useMemo(() => {
    const lastIndexName = file.path.lastIndexOf("/") || ""
    if (lastIndexName) {
      const keyFileName = file.path.substring(lastIndexName + 1)
      const withoutTimestamp = keyFileName.split("-").slice(1).join("-")
      return withoutTimestamp
    }
  }, [file.path])

  React.useEffect(() => {
    const downloadFile = async (path: string) => {
      const { url } = await getPresignedUrlMutate({
        filePath: path,
        command: SignedUrlCommandEnum.GET,
        module,
      })
      setUrl(url)
    }
    if (awsUtilitiesFile?.signedUrl && !url) setUrl(awsUtilitiesFile.signedUrl)
    else if (file.path) downloadFile(file.path)
  }, [file.path, awsUtilitiesFile])

  const contextValue = React.useMemo(
    () => ({ url: url || undefined, fileName: nameOfArchive }),
    [url, nameOfArchive],
  )

  return (
    <Container>
      {url ? (
        <PreviewImage url={url} />
      ) : (
        <Skeleton variant="rectangular" width={45} height={45} />
      )}

      <EllipsisTypography variant="body3" weight={700} color="neutral.30">
        {nameOfArchive}
      </EllipsisTypography>

      <Box gap="xs3">
        <previewContext.Provider value={contextValue}>
          {children}
        </previewContext.Provider>
      </Box>
    </Container>
  )
}
