import React from "react"
import {
  Button,
  DatePicker,
  dayjs,
  Divider,
  Icons,
  Loader,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import {
  ResignationLimitDate,
  LimitDateRef,
  Upload,
  CircleIconContainer,
  WizardCardLink,
  UploadPreview,
} from "src/components"
import { useTheme } from "styled-components"
import * as yup from "yup"
import { ContentProps } from "./Content"
import i18n from "@i18n"
import { StatusContainer, Wrapper } from "./styles"
import {
  ResignationExamStatus,
  ResignationLimitDateEnum,
} from "bff/src/types/hiring"
import dispatchToast from "@utils/dispatchToast"
import { DocumentValidation } from "./DocumentValidation"
import { trpc } from "@api/client"
import { useQueryClient } from "@tanstack/react-query"

const validationSchema = yup.object({
  limitDate: yup
    .string()
    .required(i18n.t("page.resignation.exam.requiredFieldError")),
  medicalReferral: yup.object().notRequired(),
})

const SentContent = ({ card }: ContentProps) => {
  const theme = useTheme()
  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignation.exam.examGuide",
  })

  const emailLink =
    card.emailLink?.find(
      (e) => e.name === "resendAsoRequest" && e.expired !== true,
    ) ||
    card.emailLink?.find((e) => e.name === "asoRequest" && e.expired !== true)

  return (
    <>
      {[ResignationExamStatus.sent, ResignationExamStatus.resent].includes(
        card.resignationExam?.status ?? ResignationExamStatus.pending,
      ) ? (
        <>
          <Wrapper style={{ textAlign: "center", alignItems: "center" }}>
            <CircleIconContainer
              backgroundColor={theme.colors.secondary[95]}
              color={theme.colors.secondary[50]}
            >
              <Icons name="IconCheck" fill="transparent" />
            </CircleIconContainer>

            <Typography
              variant="headline6"
              variantColor={theme.colors.neutral[30]}
            >
              {t("requestSent")}
            </Typography>
            <Typography variant="body2" variantColor={theme.colors.neutral[50]}>
              {t("requestSentDescription")}
            </Typography>
          </Wrapper>

          <Divider orientation="horizontal" />
        </>
      ) : (
        <></>
      )}

      <Wrapper>
        {emailLink ? (
          <WizardCardLink multiplesEmailLinks={[{ emailLink }]} />
        ) : (
          <></>
        )}
      </Wrapper>

      <Divider orientation="horizontal" />

      <Wrapper>
        <div>
          <Typography
            variant="headline8"
            variantColor={theme.colors.neutral[30]}
          >
            {t("limitExamDateTitle")}
          </Typography>
          <Typography variant="body4" variantColor={theme.colors.neutral[50]}>
            {t("limitExamDateDescription")}
          </Typography>
        </div>

        <DatePicker
          label={t("limitExamDateLabel")}
          placeholder={t("limitExamDateLabel")}
          value={card.resignationExam?.limitDate}
          disabled={true}
          onDateChange={(_e) => {}}
          fullWidth
        />
      </Wrapper>

      <Divider orientation="horizontal" />

      <Wrapper>
        <div>
          <Typography
            variant="headline8"
            variantColor={theme.colors.neutral[30]}
          >
            {t("asoTitle")}
          </Typography>
          <Typography variant="body4" variantColor={theme.colors.neutral[50]}>
            {t("asoDescription")}
          </Typography>
        </div>

        {card.resignationExam?.file &&
        card.resignationExam?.status === ResignationExamStatus.filled ? (
          <>
            <UploadPreview
              file={card.resignationExam?.file}
              hasShareLink={true}
              options={{
                canRemove: false,
                canDownload: true,
              }}
            />

            <Divider orientation="horizontal" />

            <DocumentValidation card={card} canResend={true} />
          </>
        ) : (
          <StatusContainer>
            <Typography
              variant="body3"
              variantColor={theme.colors.neutral[30]}
              weight={700}
            >
              {card.employee?.name || "Não cadastrado"}
            </Typography>
            <Typography variant="body4" variantColor={theme.colors.neutral[50]}>
              {card.employee?.corporateEmail ||
                card.employee?.email ||
                "Não cadastrado"}
            </Typography>
            <Typography
              variant="body4"
              variantColor={theme.colors.feedback.error[40]}
              weight={700}
            >
              <Icons name="IconClock" fill="transparent" size={16} />
              {t("asoStatus")}
            </Typography>
          </StatusContainer>
        )}
      </Wrapper>
    </>
  )
}

const WaitingContent = ({ card }: ContentProps) => {
  const theme = useTheme()
  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignation.exam.examGuide",
  })

  const queryClient = useQueryClient()

  const limitDateRef = React.useRef<LimitDateRef>(null)

  const { mutateAsync: updateCard, isLoading: isUpdatingCard } =
    trpc.card.updateResignationCard.useMutation()
  const { mutateAsync: sendAsoRequest, isLoading: isSendingAsoRequest } =
    trpc.resignationCard.sendAsoRequest.useMutation()

  const formik = useFormik({
    initialValues: {
      limitDate: card?.resignationExam?.limitDate,
      medicalReferral: card.resignationExam?.medicalReferral,
    },
    validationSchema,
    onSubmit: async (values) => {
      const errorsOnValidate = await limitDateRef.current?.validate()
      if (Object.keys(errorsOnValidate ?? {})?.length > 0) {
        dispatchToast({
          content: t("formInvalidError", {
            keyPrefix: "page.resignation.exam",
          }),
          type: "error",
        })
        return
      }

      try {
        await limitDateRef.current?.submit()
        await updateCard({
          flowCardId: card._id,
          resignationExam: {
            medicalReferral: values.medicalReferral,
            limitDate: values.limitDate ?? "",
          },
        })
        await sendAsoRequest({
          cardId: card._id,
          employeeId: card.employeeId,
          columnId: card.columnId,
        })
        queryClient.invalidateQueries()
        dispatchToast({
          content: "Exame demissional enviado com sucesso!",
          type: "success",
        })
      } catch (err: any) {
        dispatchToast({
          type: "error",
          content:
            err.data?.userFriendlyError?.message ??
            "Houve um problema ao enviar a solicitação",
        })
      }
    },
  })

  return (
    <>
      <Wrapper>
        <div>
          <Typography
            variant="headline8"
            variantColor={theme.colors.neutral[30]}
          >
            {t("title")}
          </Typography>
          <Typography variant="body4" variantColor={theme.colors.neutral[50]}>
            {t("description")}
          </Typography>
        </div>

        <Upload
          value={formik.values.medicalReferral}
          label={t("uploadLabel")}
          accept={["jpg", "png", "pdf"]}
          folder={`${card.companyId}/${card.employeeId}/${card._id}/resignation/aso/${card.name}`}
          module="employee-pii"
          customFilename={`Guia_exame_demissional_${card.name}`}
          onUpload={async (file) => {
            formik.handleChange({
              target: {
                id: "medicalReferral",
                value: file,
              },
            })
          }}
          onRemove={async () => {
            formik.handleChange({
              target: {
                id: "medicalReferral",
                value: undefined,
              },
            })
          }}
        />
      </Wrapper>

      <Divider orientation="horizontal" />

      <Wrapper>
        <div>
          <Typography
            variant="headline8"
            variantColor={theme.colors.neutral[30]}
          >
            {t("limitExamDateTitle")}
          </Typography>
          <Typography variant="body4" variantColor={theme.colors.neutral[50]}>
            {t("limitExamDateDescription")}
          </Typography>
        </div>

        <DatePicker
          label={t("limitExamDateLabel")}
          placeholder={t("limitExamDateLabel")}
          value={formik.values.limitDate}
          onDateChange={async (value) => {
            const date = value && dayjs(value).isValid() ? dayjs(value) : null
            if (!date) return
            const parsedValue = dayjs(value).isValid()
              ? date?.toISOString()
              : null

            formik.handleChange({
              target: { id: "limitDate", value: parsedValue },
            })
          }}
          error={
            formik?.touched?.limitDate && Boolean(formik?.errors?.limitDate)
          }
          helperText={
            formik?.touched?.limitDate && formik?.errors?.limitDate?.toString()
          }
          fullWidth
        />
      </Wrapper>

      <Divider orientation="horizontal" />

      <Wrapper>
        <ResignationLimitDate
          ref={limitDateRef}
          cardId={card._id}
          step={ResignationLimitDateEnum.aso_Resignation}
          limitDate={card.limitDates?.find(
            (limitDate) =>
              limitDate.step === ResignationLimitDateEnum.aso_Resignation,
          )}
          description={t("limitDateDescription")}
        />

        <Button
          variant="primary"
          size="large"
          disabled={isUpdatingCard || isSendingAsoRequest}
          onClick={() => formik.handleSubmit()}
        >
          {isUpdatingCard || isSendingAsoRequest ? (
            <Loader size="extraSmall" variant="secondary" />
          ) : (
            <>
              {t("submitButtonLabel")}
              <Icons name="IconMail" />
            </>
          )}
        </Button>
      </Wrapper>
    </>
  )
}

export const ExamGuideContent = (props: ContentProps) => {
  switch (props.card.resignationExam?.status) {
    case ResignationExamStatus.sent:
    case ResignationExamStatus.resent:
    case ResignationExamStatus.filled:
      return <SentContent {...props} />
    case ResignationExamStatus.pending:
    default:
      return <WaitingContent {...props} />
  }
}
