import React, { useState } from "react"

import { Skeleton } from "@mui/material"

import {
  IconButton,
  Icons,
  Menu,
  MenuOption,
  Tag,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { localStyles, PreviewContainer, PreviewImage } from "./styles"

import { removeDropzoneTimestamp } from "src/utils"
import { useTheme } from "styled-components"
import { S3File } from "../../../types"
import dispatchToast from "../../../utils/dispatchToast"
import { LinkButtonFitContent } from "../atoms/LinkButtonFitContent"

interface UploadPreviewProps {
  file: S3File
  options: {
    canRemove: boolean
    canDownload: boolean
    removeText?: string
    handleRemove?: (file: S3File) => void | Promise<void>
    downloadText?: string
  }
  customName?: string
  tag?: React.ComponentProps<typeof Tag>
  hasShareLink?: boolean
}

export const UploadPreview = ({
  file,
  options,
  hasShareLink = false,
  customName,
  tag,
}: UploadPreviewProps) => {
  const theme = useTheme()

  const [isRemoving, setIsRemoving] = useState<boolean>(false)

  const nameOfArchive = React.useMemo(() => {
    if (customName) return customName

    const lastIndexName = file?.key?.lastIndexOf("/") || ""
    if (file?.key && lastIndexName) {
      const keyFileName = file?.key.substring(lastIndexName + 1)
      return removeDropzoneTimestamp(keyFileName)
    }

    return "Nome não identificado"
  }, [file, customName])

  if (isRemoving)
    return <Skeleton variant="rectangular" width="100%" height="114px" />

  const hasAnyOption = !!options.canDownload || !!options.canRemove

  async function handleDownload() {
    if (!file.value) return
    const readFile = await fetch(file.value)
    const fileToArrayBuffer = await readFile.arrayBuffer()
    const res = URL.createObjectURL(new Blob([fileToArrayBuffer]))
    const a = document.createElement("a")
    a.href = res
    a.download = nameOfArchive
    a.click()
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <PreviewContainer>
        <PreviewImage url={file.value} />
        <Typography
          variant="body4"
          variantColor="#53464F"
          style={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
          {nameOfArchive}{" "}
          {tag ? (
            <Tag disabled {...tag}>
              <Typography variant="caption" weight={600}>
                {tag?.children}
              </Typography>
            </Tag>
          ) : null}
        </Typography>
        <div style={{ display: "flex" }}>
          {hasAnyOption ? (
            <>
              {options.canDownload && options.canRemove ? (
                <Menu
                  type="default"
                  options={[
                    {
                      children: (
                        <MenuOption
                          label={options?.downloadText || "Fazer download"}
                          icon="IconDownload"
                          onClick={() => handleDownload()}
                        />
                      ),
                    },
                    {
                      children: (
                        <MenuOption
                          label={options?.removeText || "Remover arquivo"}
                          icon="IconTrash"
                          onClick={() => {
                            if (!options.handleRemove)
                              throw new Error(
                                "No handle function provided to remove",
                              )

                            options?.handleRemove(file)
                          }}
                        />
                      ),
                    },
                  ]}
                >
                  <IconButton
                    size="medium"
                    variant="line"
                    icon="IconDotsVertical"
                  />
                </Menu>
              ) : (
                <IconButton
                  size="medium"
                  variant="line"
                  icon={options.canRemove ? "IconTrash" : "IconDownload"}
                  onClick={async (e) => {
                    e.stopPropagation()
                    e.preventDefault()

                    if (options.canRemove) {
                      if (!options.handleRemove)
                        throw new Error("No handle function provided to remove")

                      setIsRemoving(true)
                      await options.handleRemove(file)
                      setIsRemoving(false)
                    } else {
                      handleDownload()
                    }
                  }}
                />
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </PreviewContainer>

      {hasShareLink && (
        <div style={{ marginTop: "4px" }}>
          <LinkButtonFitContent
            color={theme.colors.neutral[40]}
            variant="primary"
            style={localStyles.linkButton}
            onClick={async () => {
              if (!file.value) return
              navigator.clipboard.writeText(file.value)
              dispatchToast({
                content: "Link copiado",
                type: "success",
              })
            }}
          >
            <Typography
              variant="caption"
              variantColor="#6B5B66"
              style={{ fontWeight: 700 }}
            >
              Compartilhar link
            </Typography>
            <Icons
              name="IconLink"
              size={16}
              style={{
                fill: "transparent",
                color: "#6B5B66",
              }}
            />
          </LinkButtonFitContent>
        </div>
      )}
    </div>
  )
}
