import { Dropzone, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import dispatchToast from "../../../utils/dispatchToast"
import { Action } from "./Action"
import { Error as ErrorComponent } from "./Error"
import { Loading } from "./Loading"
import { Preview } from "./Preview"
import { States, useUpload } from "./useUpload"
import { useTheme } from "styled-components"
import { ComponentProps } from "react"
import { FileType, StorageGatewayModules } from "@customTypes/storage-gateway"
import { Box } from "../atoms/Box"

export type UploadProps = {
  accept: ComponentProps<typeof Dropzone>["accept"]
  children?: React.ReactNode
  error?: boolean
  folder: string
  helperText?: string
  label?: string
  maxSize?: number
  onUpload?: (file: FileType) => Promise<void>
  placeholder?: string
  value?: FileType
  module?: StorageGatewayModules
  awsUtilitiesModule?: "hiring" | "employee-pii"
  customFileName?: string
}

const Root = ({
  accept,
  children,
  error,
  folder,
  helperText,
  label,
  maxSize,
  onUpload,
  placeholder,
  value,
  module = StorageGatewayModules.Hiring,
  awsUtilitiesModule = "employee-pii",
  customFileName,
}: UploadProps) => {
  const { state, temporaryUploadInformation, handleChange, handleRetry } =
    useUpload({
      error,
      folder,
      onUpload,
      value,
      module,
      awsUtilitiesModule,
      customFileName,
    })

  const theme = useTheme()

  const [t] = useTranslation("translations", { keyPrefix: "components.upload" })

  function renderState() {
    if (state === States.Preview && value) {
      return (
        <Preview
          file={value}
          module={module}
          awsUtilitiesModule={awsUtilitiesModule}
        >
          {children}
        </Preview>
      )
    }

    if (state === States.Error) {
      return (
        <ErrorComponent
          archiveName={temporaryUploadInformation.archiveName}
          onRetry={handleRetry}
        />
      )
    }

    return (
      <Box $width="100%" flexDirection="column" gap="xs5">
        <Dropzone
          accept={accept}
          title={placeholder}
          multiple={false}
          onChange={handleChange}
          maxSize={maxSize}
          customPreview={() => (
            <Loading
              archiveName={temporaryUploadInformation.archiveName}
              progress={temporaryUploadInformation.progress}
            />
          )}
          onFileSizeError={() => {
            dispatchToast({
              content: t("errorArchiveSize"),
              type: "error",
            })
          }}
        />
        {helperText && (
          <Box gap="xs5">
            <Icons
              name="IconInfoCircle"
              size={16}
              color={theme.colors.secondary[50]}
            />
            <Typography variant="caption" color="neutral.50" weight={600}>
              {helperText}
            </Typography>
          </Box>
        )}
      </Box>
    )
  }

  return (
    <Box flexDirection="column" gap="xs4" $width={"100%"}>
      {label && (
        <Typography variant="body3" weight={600} color="neutral.30">
          {label}
        </Typography>
      )}

      {renderState()}
    </Box>
  )
}

export const Upload = {
  Root: Root,
  Action: Action,
  Preview: Preview,
}
