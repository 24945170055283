import { useMemo } from "react"
import { useNavigate } from "react-router-dom"

import {
  DataGrid,
  Skeleton,
  Tag,
  Typography,
  dayjs,
  EmptyState,
  Icons,
  DataGridProps,
} from "@flash-tecnologia/hros-web-ui-v2"

import { ModelOptions } from "./ModelOptions"

interface TableGridProps {
  data: any[]
  rawData: any[]
  pageSize: number
  loading: boolean
  flowId: string
  refetchData: () => void
}

export const TableGrid = ({
  data,
  rawData,
  pageSize,
  loading,
  flowId,
  refetchData,
}: TableGridProps) => {
  const navigate = useNavigate()

  const columns: DataGridProps<string>["columns"] = useMemo(
    () => [
      {
        Header: "Modelo de carta proposta",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          const info = {
            published: { label: "Publicado", variant: "success" },
            draft: { label: "Rascunho", variant: "error" },
          }

          return (
            <div style={{ display: "flex", gap: "8px" }}>
              <Tag variant={info[value]?.variant} disabled>
                <Typography variant="caption" style={{ fontWeight: 600 }}>
                  {info[value]?.label}
                </Typography>
              </Tag>
            </div>
          )
        },
      },
      {
        Header: "Candidatos associados",
        accessor: "cardsAssociated",
      },
      {
        Header: "Criado em",
        accessor: "createdAt",
        Cell: ({ value }) => {
          const isValid = dayjs(value).isValid()
          return isValid ? dayjs(value).format("DD/MM/YYYY") : "-"
        },
      },
      {
        Header: "Ações",
        accessor: "action",
        sticky: "right",
        disableSortBy: true,
        Cell: ({ row }) => {
          const modelId = row?.original?._id

          return (
            <ModelOptions
              modelId={modelId}
              flowId={flowId}
              refetchData={refetchData}
            />
          )
        },
      },
    ],
    [],
  )

  if (loading) return <Skeleton variant="rounded" height={"400px"} />

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={
          <>
            Criar modelo <Icons name="IconPlus" />
          </>
        }
        description={"Você ainda não possui nenhum modelo de carta proposta."}
        buttonProps={{
          size: "medium",
          onClick: () =>
            navigate(
              `/flows/settings/${flowId}/proposedLetter/create/basic-info`,
            ),
        }}
      />
    )

  return (
    <DataGrid
      hasPagination={true}
      columns={columns}
      data={data || []}
      initialState={{ pageSize: Number(pageSize) }}
      emptyState={{
        isFiltered: true,
        message: "Nenhum modelo de contrato encontrado!",
      }}
      pageSizeOptions={[
        {
          label: "10 itens",
          value: 10,
        },
        {
          label: "25 itens",
          value: 25,
        },
        {
          label: "50 itens",
          value: 50,
        },
        {
          label: "100 itens",
          value: 100,
        },
        {
          label: "250 itens",
          value: 250,
        },
      ]}
    />
  )
}
