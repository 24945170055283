import { trpc } from "@api/client"
import {
  DatePicker,
  dayjs,
  Dayjs,
  LinkButton,
  Modal,
  Radio,
  SelectField,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import i18n from "@i18n"
import { RadioGroup } from "@mui/material"
import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import { ProbationPeriod } from "src/types"
import { useTheme } from "styled-components"
import * as yup from "yup"
import { CpfMask, cpfParser, validateCPF } from "../../../utils"
import dispatchToast from "../../../utils/dispatchToast"
import { LoadingButton } from "../LoadingButton"
import { ProbationaryPeriod, ProbationaryRef } from "../ProbationaryPeriod"
import InfoNotificationCard from "./InfoNotificationCard"
import { Container, ContentSection } from "./styles"

export * from "./AdmissionWarningModal"

enum SendType {
  Now = "EnviarImediatamente",
  Schedule = "AgendarEnvio",
}

const validationSchema = yup.object().shape({
  cpf: yup.string().required("Campo obrigatório"),
  hiringDate: yup.string().required("Campo obrigatório"),
  corporateEmail: yup
    .string()
    .email("Insira um e-mail válido")
    .required("Campo obrigatório"),
  sendType: yup.string().nullable().required("Campo obrigatório"),
  sendDate: yup
    .date()
    .typeError("Campo obrigatório")
    .when("sendType", {
      is: SendType.Schedule,
      then: yup.date().required("Campo obrigatório"),
    }),
  sendTime: yup
    .string()
    .nullable()
    .when("sendType", {
      is: SendType.Schedule,
      then: yup.string().nullable().required("Campo obrigatório"),
    }),
})

type SendTypeOption = {
  label: string
  value: SendType.Now | SendType.Schedule
}

const sendTypeOptions: SendTypeOption[] = [
  { label: "Enviar imediatamente", value: SendType.Now },
  { label: "Agendar envio", value: SendType.Schedule },
]

type ScheduleOptionValue = "morning" | "noon" | "night"
type ScheduleOption = {
  label: string
  value: ScheduleOptionValue
}
const morningOption: ScheduleOption = { label: "Manhã (8h)", value: "morning" }
const noonOption: ScheduleOption = { label: "Tarde (12h)", value: "noon" }
const nightOption: ScheduleOption = { label: "Noite (18h)", value: "night" }

const defaultTimeOptions: ScheduleOption[] = [
  morningOption,
  noonOption,
  nightOption,
]

type FormValues = {
  cpf: string
  hiringDate: string | undefined
  corporateEmail: string
  sendType: string
  sendDate: string
  sendTime: string
}

interface IModalDoneAdmission {
  isOpen: boolean
  handleClose: () => void
  cbConfigure: any
  card: {
    candidateId: string
    candidate: {
      proposalLetterInfo?: {
        hiringDate: string
      }
      probationPeriod?: ProbationPeriod
      documentNumber?: string
      name: string
      email?: string
      corporateEmail?: string
    }
  }
  loading?: boolean
}

export const ModalDoneAdmission = React.memo(
  ({
    isOpen,
    handleClose,
    cbConfigure,
    card,
    loading,
  }: IModalDoneAdmission) => {
    const theme = useTheme()
    const [notificationCard, setNotificationCard] = useState(true)
    const [timeOptions, setTimeOptions] = useState<ScheduleOption[]>([])
    const {
      candidate: {
        proposalLetterInfo = {
          hiringDate: "",
        },
        documentNumber = "",
        corporateEmail = "",
        probationPeriod,
      },
    } = card
    const initialValues: FormValues = {
      cpf: documentNumber ?? "",
      hiringDate: proposalLetterInfo?.hiringDate
        ? (proposalLetterInfo?.hiringDate as string)
        : undefined,
      corporateEmail: corporateEmail ?? "",
      sendType: "",
      sendDate: "",
      sendTime: "",
    }

    const probationPeriodRef = React.useRef<ProbationaryRef>(null)

    const formik = useFormik<FormValues>({
      initialValues,
      isInitialValid: false,
      validateOnChange: true,
      validateOnBlur: true,
      validationSchema,
      validate: (values) => {
        let errors: any = {}
        if (values.cpf && !validateCPF(values.cpf)) errors.cpf = "CPF inválido"

        return errors
      },
      onSubmit: () => {},
      enableReinitialize: false,
      validateOnMount: true,
    })

    const { mutate: updateCandidate, isLoading: isUpdatingCandidate } =
      trpc.candidate.update.useMutation({
        onSuccess: () => {
          cbConfigure()
          dispatchToast({
            type: "success",
            content: "Candidato atualizado com sucesso.",
          })
        },
        onError: () => {
          dispatchToast({
            type: "error",
            content: "Não foi possível atualizar o candidato, tente novamente.",
          })
        },
      })

    const handleUpdateCandidate = (probationPeriod?: ProbationPeriod) => {
      try {
        let tmp: dayjs.Dayjs
        switch (formik.values.sendTime) {
          case "morning":
            tmp = dayjs(formik.values.sendDate).hour(8).minute(0).second(0)
            break
          case "noon":
            tmp = dayjs(formik.values.sendDate).hour(12).minute(0).second(0)
            break
          case "night":
            tmp = dayjs(formik.values.sendDate).hour(18).minute(0).second(0)
            break
          default:
            tmp = dayjs(formik.values.sendDate)
            break
        }
        updateCandidate({
          candidateId: card.candidateId,
          fields: {
            ...((!card.candidate?.proposalLetterInfo?.hiringDate ||
              dayjs(
                card.candidate?.proposalLetterInfo?.hiringDate,
              ).toISOString() !==
                dayjs(formik.values.hiringDate).toISOString()) && {
              proposalLetterInfo: {
                hiringDate: formik.values.hiringDate,
              },
            }),
            ...(!card.candidate?.corporateEmail && {
              corporateEmail: formik.values.corporateEmail,
            }),
            ...(!card.candidate?.documentNumber && {
              documentNumber: formik.values.cpf,
            }),
            inviteDate:
              formik.values.sendType === SendType.Schedule
                ? tmp.toISOString()
                : undefined,
            probationPeriod,
          },
        })
      } catch (err: any) {
        dispatchToast({
          type: "error",
          content: "Não foi possível encaminhar o convite, tente novamente.",
        })
      }
    }

    const handleFormSubmit = async (type: "now" | "later") => {
      if (!probationPeriodRef.current) {
        dispatchToast({
          content: i18n.t("error.internalServerError"),
          type: "error",
        })
        return
      }
      const probationErrors = await probationPeriodRef?.current?.validateForm()
      const probationErrorsValues = Object.values(probationErrors)
      if (probationErrorsValues.length > 0) {
        const [firstError] = probationErrorsValues
        dispatchToast({
          type: "error",
          content: firstError,
        })
        return
      }

      const probationPeriod: typeof probationPeriodRef.current.values =
        await probationPeriodRef?.current?.submitForm()

      await formik.submitForm()
      const formErrors = Object.values(formik.errors)

      if (!formik.isValid || formErrors.length > 0) {
        const [firstError] = formErrors
        return dispatchToast({
          type: "error",
          content: firstError,
        })
      }
      switch (type) {
        case "now":
          handleUpdateCandidate(probationPeriod)
          break
        case "later":
          cbConfigure()
          break
        default:
          break
      }
    }

    useEffect(() => {
      if (formik.values.sendType === SendType.Now) {
        formik.setValues(
          {
            ...formik.values,
            sendDate: "",
            sendTime: "",
          },
          true,
        )
        formik.setErrors({
          ...formik.errors,
          sendDate: "",
          sendTime: "",
        })
      }
    }, [formik.values.sendType])

    useEffect(() => {
      if (!dayjs(formik.values.sendDate).isSame(dayjs(), "day")) {
        setTimeOptions(defaultTimeOptions)
        return
      }
      const tmp: ScheduleOption[] = []
      if (dayjs().isBefore(dayjs().hour(8))) tmp.push(morningOption)
      if (dayjs().isBefore(dayjs().hour(12))) tmp.push(noonOption)
      if (dayjs().isBefore(dayjs().hour(18))) tmp.push(nightOption)
      setTimeOptions(tmp)
    }, [formik.values.sendDate])

    const radios: React.ReactNode[] = React.useMemo(() => {
      return sendTypeOptions.reduce<React.ReactNode[]>((acc, option) => {
        return [
          ...acc,
          <Radio
            key={option.value + "-value"}
            value={option.value}
            name="sendType"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />,
          <Typography key={option.value + "-text"} variant="body3" weight={600}>
            {option.label}
          </Typography>,
        ]
      }, [])
    }, [])

    const Divider = () => (
      <div
        style={{
          width: "100%",
          border: `${theme.borders.width.xs2} solid ${theme.colors.neutral90}`,
        }}
      />
    )
    const isLoading = isUpdatingCandidate || loading

    const parseDayjsToDateString = (value: dayjs.Dayjs): string => {
      const date =
        value && dayjs(value).isValid() ? dayjs(value).toISOString() : ""
      return date
    }
    return (
      <Modal.Root
        open={isOpen}
        onClose={() => {
          if (!isLoading) handleClose()
        }}
        size="md"
      >
        <>
          <Modal.Header
            title={"Concluir Admissão"}
            description={
              "Todo o histórico do processo admissional deste candidato será mantido dentro da etapa de Admissão concluída."
            }
          />

          <Modal.Content>
            <Container>
              <ContentSection>
                <Typography variant="body3" weight={700}>
                  Campos obrigatórios para a concluir a admissão
                </Typography>

                <TextField
                  id="cpf"
                  name="cpf"
                  label="CPF"
                  disabled={!!card.candidate?.documentNumber || isLoading}
                  fullWidth
                  value={CpfMask(formik.values.cpf)}
                  error={formik.touched.cpf && Boolean(formik.errors.cpf)}
                  helperText={formik.touched.cpf && formik.errors.cpf}
                  onChange={(e) => {
                    const value = cpfParser(e.target.value)
                    e.target.value = value
                    if (value.length > 14) return
                    formik.handleChange(e)
                  }}
                  onBlur={formik.handleBlur}
                />

                <DatePicker
                  id="hiringDate"
                  name="hiringDate"
                  label="Data prevista para admissão"
                  placeholder="Data prevista para admissão"
                  value={
                    formik.values.hiringDate
                      ? parseDayjsToDateString(dayjs(formik.values.hiringDate))
                      : ""
                  }
                  disabled={isLoading}
                  error={
                    formik.touched.hiringDate &&
                    Boolean(formik.errors.hiringDate)
                  }
                  helperText={
                    formik.touched.hiringDate && formik.errors.hiringDate
                  }
                  onBlur={formik.handleBlur}
                  onDateChange={(value) => {
                    if (!dayjs(value).isValid()) return
                    formik.setFieldValue(
                      "hiringDate",
                      parseDayjsToDateString(value as Dayjs),
                    )
                  }}
                />

                <ProbationaryPeriod
                  ref={probationPeriodRef}
                  hiringDate={formik.values.hiringDate}
                  value={probationPeriod}
                  required={true}
                />
              </ContentSection>
              <Divider />
              {notificationCard ? (
                <InfoNotificationCard
                  onClose={() => setNotificationCard(false)}
                />
              ) : null}

              <ContentSection>
                <Typography variant="body3" weight={700}>
                  Atribuir e-mail corporativo
                </Typography>
                <TextField
                  id="name"
                  name="name"
                  label="Nome completo"
                  disabled
                  fullWidth
                  value={card?.candidate?.name ?? "Não cadastrado"}
                />
                {card.candidate?.email ? (
                  <TextField
                    id="mail"
                    name="mail"
                    label="E-mail pessoal"
                    disabled
                    fullWidth
                    value={card?.candidate?.email ?? "Não cadastrado"}
                  />
                ) : (
                  <></>
                )}
                <>
                  <TextField
                    id="corporateEmail"
                    name="corporateEmail"
                    label="E-mail corporativo"
                    fullWidth
                    value={formik.values.corporateEmail ?? ""}
                    disabled={!!card.candidate?.corporateEmail || isLoading}
                    error={
                      formik.touched.corporateEmail &&
                      Boolean(formik.errors.corporateEmail)
                    }
                    helperText={
                      formik.touched.corporateEmail &&
                      formik.errors.corporateEmail
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </>
              </ContentSection>
              <Divider />
              <ContentSection>
                <Typography variant="body3" weight={700}>
                  Enviar convite de boas-vindas
                </Typography>
                <div
                  style={{
                    padding: `${theme.spacings.xs2} ${theme.spacings.xs}`,
                    border: `${theme.borders.width.xs2} solid ${formik.touched.sendType && Boolean(formik.errors.sendType) ? theme.colors.error70 : theme.colors.neutral80}`,
                    borderRadius: theme.borders.radius.m,
                    display: "flex",
                    flexDirection: "column",
                    gap: theme.spacings.xs1,
                    background:
                      formik.touched.sendType && Boolean(formik.errors.sendType)
                        ? theme.colors.error90
                        : "transparent",
                  }}
                >
                  <div>
                    <Typography variant="body3" weight={600}>
                      Convite de boas-vindas
                    </Typography>
                    <Typography variant="body4" weight={400}>
                      Escolha entre o envio do convite imediato ou defina uma
                      data e horário específico.
                    </Typography>
                  </div>
                  <ContentSection>
                    <RadioGroup
                      value={formik.values.sendType ?? ""}
                      name="radio-buttons-group"
                      onChange={formik.handleChange}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {radios}
                      </div>
                    </RadioGroup>
                    {formik.values.sendType === SendType.Schedule && (
                      <ContentSection
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <DatePicker
                            id={"sendDate"}
                            name={"sendDate"}
                            label={"Data de envio"}
                            placeholder={"Digite a data de envio"}
                            value={formik.values.sendDate ?? ""}
                            fromDate={dayjs()}
                            disabled={isLoading}
                            fullWidth
                            error={
                              formik.touched.sendDate &&
                              Boolean(formik.errors.sendDate)
                            }
                            helperText={
                              formik.touched.sendDate && formik.errors.sendDate
                            }
                            onDateChange={(value) => {
                              formik.setValues({
                                ...formik.values,
                                sendTime: "",
                                sendDate: parseDayjsToDateString(
                                  value as Dayjs,
                                ),
                              })
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        <div style={{ width: "100%" }}>
                          <SelectField
                            name="sendTime"
                            label={"Hora de envio"}
                            fullWidth
                            disabled={isLoading}
                            value={formik.values.sendTime ?? ""}
                            error={
                              formik.touched.sendTime &&
                              Boolean(formik.errors.sendTime)
                            }
                            helperText={
                              formik.touched.sendTime && formik.errors.sendTime
                            }
                            onSelectChange={(e, { value }) => {
                              formik.setFieldValue(
                                "sendTime",
                                value as ScheduleOptionValue,
                              )
                            }}
                            options={timeOptions}
                          />
                        </div>
                      </ContentSection>
                    )}
                  </ContentSection>
                </div>
              </ContentSection>
            </Container>
          </Modal.Content>

          <Modal.Footer>
            <LinkButton
              variant="primary"
              onClick={async () => await handleFormSubmit("later")}
              style={{ alignSelf: "center" }}
              disabled={isLoading}
            >
              Configurar depois
            </LinkButton>

            <LoadingButton
              variant="primary"
              size="large"
              disabled={isLoading}
              isLoading={!!isLoading}
              onClick={async () => await handleFormSubmit("now")}
            >
              Concluir Admissão
            </LoadingButton>
          </Modal.Footer>
        </>
      </Modal.Root>
    )
  },
)
