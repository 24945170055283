import { Fragment, useContext, useEffect, useState } from "react"
import {
  CloseWrapper,
  Container,
  Description,
  Footer,
  Header,
  IconContainer,
  RadioContainer,
  StyledModal,
  Title,
  Body,
  RoundIconTitle,
  RoundIconContainer,
} from "./styles"
import {
  Button,
  Icons,
  LinkButton,
  Loader,
  MultiSelect,
  NotificationCard,
  Radio,
  Stepper,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import dispatchToast from "../../../../utils/dispatchToast"
import { globalContext } from "../../../../context"
import { ColumnWithCards, HiringCard } from "../../../../types"
import { trpc } from "@api/client"
import { useTracking } from "@utils/useTracking"

enum Steps {
  information = 0,
  stages = 1,
}

interface ModalExportData {
  isOpen: boolean
  handleClose: () => void
  columns: ColumnWithCards<HiringCard>[]
}

export const ModalExportData = ({
  isOpen,
  handleClose,
  columns,
}: ModalExportData) => {
  const [step, setStep] = useState<Steps>(Steps.information)
  const [typeOfExport, setTypeOfExport] = useState<
    "worksheet" | "documents" | "both" | null
  >(null)
  const [columnsToExport, setColumnsToExport] = useState<
    { label: string; value: string; count: number }[]
  >([])
  const [exportFinished, setExportFinished] = useState<boolean>(false)
  const [candidatesSelected, setCandidatesSelected] = useState<number>(0)

  const { employee } = useContext(globalContext)
  const [t] = useTranslation("translations", { keyPrefix: "page" })

  const { mutateAsync: createReport, isLoading: isReportLoading } =
    trpc.hiring.createBoardReport.useMutation()

  const tracking = useTracking()

  useEffect(() => {
    let totalCandidates = 0
    columnsToExport.forEach((c) => {
      totalCandidates += c.count
    })
    setCandidatesSelected(totalCandidates)
  }, [columnsToExport])

  const contents = [
    {
      step: Steps.information,
      content: () => {
        const radioProps = (item: string) => ({
          checked: typeOfExport === item,
          onChange: (e: any) => setTypeOfExport(e.target.value),
          value: item,
          name: "radio-export-data",
          inputProps: { "aria-label": item },
        })

        return (
          <Fragment>
            <Title variant="headline7">
              {t("exportData.informationTitle")}
            </Title>
            <Description variant="body3">
              {t("exportData.informationDescription")}
            </Description>

            <RadioContainer>
              <Radio {...radioProps("worksheet")} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 10,
                }}
              >
                <Title variant="body3">{t("exportData.dataOnlyTitle")}</Title>
                <Description variant="body4">
                  {t("exportData.dataOnlyDescription")}
                </Description>
              </div>
            </RadioContainer>

            <RadioContainer>
              <Radio {...radioProps("documents")} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 10,
                }}
              >
                <Title variant="body3">
                  {t("exportData.documentOnlyTitle")}
                </Title>
                <Description variant="body4">
                  {t("exportData.documentOnlyDescription")}
                </Description>
              </div>
            </RadioContainer>

            <RadioContainer>
              <Radio {...radioProps("both")} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 10,
                }}
              >
                <Title variant="body3">{t("exportData.allDataTitle")}</Title>
                <Description variant="body4">
                  {t("exportData.allDataDescription")}
                </Description>
              </div>
            </RadioContainer>
          </Fragment>
        )
      },
    },
    {
      step: Steps.stages,
      content: () => (
        <Fragment>
          {candidatesSelected > 250 ? (
            <div style={{ paddingBottom: "40px" }}>
              <NotificationCard.Root variant="contained" type="error">
                <NotificationCard.Icon iconName="IconX" />
                <NotificationCard.WrapperTexts>
                  <Typography
                    variant="body3"
                    style={{
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "22px",
                      paddingBottom: "4px",
                    }}
                  >
                    A quantidade de candidatos selecionados ultrapassou o limite
                  </Typography>
                  <Typography variant="body4" style={{}}>
                    Apenas <b>250</b> candidatos podem ser exportados por vez,
                    exclua alguma etapa para diminuir a quantidade de candidatos
                    e finalizar a exportação.
                  </Typography>
                </NotificationCard.WrapperTexts>
              </NotificationCard.Root>
            </div>
          ) : (
            <></>
          )}
          <Title variant="headline7">{t("exportData.stagesTitle")}</Title>
          <Description variant="body3">
            {t("exportData.stagesDescription")}
          </Description>

          <div style={{ marginTop: "20px" }}>
            <MultiSelect
              renderInput={() => null}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              onSelectChange={(_, value) => setColumnsToExport(value)}
              value={columnsToExport}
              label={t("exportData.stagesPlaceholder")}
              fullWidth={true}
              options={columns
                .filter((column) => !column.hidden)
                .map((column) => ({
                  label: column.name,
                  value: column._id,
                  count: column?.cards?.length || 0,
                }))}
            />
            <Typography
              variant="body3"
              style={{
                fontWeight: 600,
                color: "#B9ACB4",
                fontSize: "12px",
                lineHeight: "18px",
                textAlign: "end",
              }}
            >
              {candidatesSelected}/250 candidatos selecionados
            </Typography>
          </div>
        </Fragment>
      ),
    },
  ]

  const renderContent = () => {
    const currentContent = contents.find((c) => c.step === step)
    if (!currentContent) return null

    return <currentContent.content />
  }

  const handleSubmit = async () => {
    if (!typeOfExport) {
      dispatchToast({
        content: "Selecione o tipo de exportação",
        type: "warning",
      })
      return
    }
    if (!columnsToExport || columnsToExport?.length <= 0) {
      dispatchToast({
        content: "Nenhuma coluna foi selecionada para ser exportada",
        type: "warning",
      })
      return
    }

    const columnsToExportId = columnsToExport.map(
      (columnToExport) => columnToExport.value,
    )
    const filteredColumns = columns.filter((column) =>
      columnsToExportId.includes(column._id),
    )

    const candidateIds = filteredColumns.reduce<string[]>(
      (prevValue, currValue) => {
        const filteredCards =
          currValue.cards?.filter((card) => card.active && card.candidateId) ||
          []
        if (filteredCards?.length === 0) return prevValue

        const candidatesIdFromCard = filteredCards?.map(
          (card) => card.candidateId,
        )

        return prevValue.concat(candidatesIdFromCard)
      },
      [],
    )

    if (!candidateIds || candidateIds?.length === 0) {
      dispatchToast({
        content: "Não há dados a serem exportados nas colunas selecionadas",
        type: "warning",
      })
      return
    }

    tracking.trackEvent({
      name: "hiring_clicked_export_candidate_data",
    })

    await createReport({
      candidateIds: candidateIds || [],
      reportType: typeOfExport,
    })
      .then((_) => setExportFinished(true))
      .catch(() => {
        dispatchToast({
          content: "Houve um error ao exportar dados",
          type: "error",
        })
      })
  }

  if (exportFinished) {
    const employeeEmail = employee?.corporateEmail || employee?.email || ""

    return (
      <StyledModal open={isOpen} onClose={handleClose} isSmall={true}>
        <Container>
          <Header
            style={{
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CloseWrapper>
              <IconContainer onClick={handleClose}>
                <Icons name="IconX" style={{ width: "16px", height: "16px" }} />
              </IconContainer>
            </CloseWrapper>

            <RoundIconContainer>
              <Icons name="IconCheck" fill="transparent" size={42} />
            </RoundIconContainer>

            <RoundIconTitle>Pronto!</RoundIconTitle>

            <Title variant="headline6">
              Seu relatório está sendo exportado!
            </Title>

            <Description variant="body3" style={{ marginTop: 15 }}>
              Para acessá-lo é só conferir o e-mail que será enviado em alguns
              minutos para o endereço:
            </Description>

            <Description variant="body3" style={{ marginTop: 5 }}>
              <b>{employeeEmail}</b>
            </Description>

            <Description variant="body3" style={{ marginTop: 20 }}>
              Para acessar os dados utilize os{" "}
              <b>4 primeiros dígitos do seu CPF</b> como senha.
            </Description>
          </Header>

          <Footer style={{ justifyContent: "center" }}>
            <Button size="large" variant={"primary"} onClick={handleClose}>
              Voltar para Admissão
            </Button>
          </Footer>
        </Container>
      </StyledModal>
    )
  }

  return (
    <StyledModal open={isOpen} onClose={handleClose}>
      <Container>
        <Header>
          <Stepper
            steps={[t("exportData.stepperInfo"), t("exportData.stepperStages")]}
            activeStep={step}
            setActiveStep={(step: Steps) => setStep(step)}
            disableClick={step === Steps.information}
          />

          <CloseWrapper>
            <IconContainer onClick={handleClose}>
              <Icons name="IconX" style={{ width: "16px", height: "16px" }} />
            </IconContainer>
          </CloseWrapper>

          <Title variant="headline6">{t("exportData.title")}</Title>
          <Description variant="body3">
            {t("exportData.description")}
          </Description>
        </Header>

        <Body>{renderContent()}</Body>

        <Footer>
          <LinkButton
            variant="primary"
            onClick={handleClose}
            style={{ marginTop: 15 }}
          >
            Cancelar
          </LinkButton>

          {step === Steps.information ? (
            <Button
              size="large"
              variant={"primary"}
              disabled={typeOfExport === null}
              onClick={() => setStep(Steps.stages)}
            >
              Continuar
              <Icons name={"IconArrowRight"} fill="transparent" />
            </Button>
          ) : (
            <Button
              size="large"
              variant={"primary"}
              onClick={handleSubmit}
              disabled={
                columnsToExport?.length === 0 ||
                isReportLoading ||
                candidatesSelected > 250
              }
            >
              {isReportLoading ? (
                <Loader size="extraSmall" variant="secondary" />
              ) : (
                <>
                  Exportar relatório
                  <Icons name={"IconDownload"} fill="transparent" />
                </>
              )}
            </Button>
          )}
        </Footer>
      </Container>
    </StyledModal>
  )
}
