import { z } from "zod"
import { S3File } from "."

export enum SignType {
  candidate = "candidate",
  company = "company",
  witness = "witness",
  institution = "institution",
  employee = "person",
}

export enum SignStatus {
  pending = "Pending",
  signed = "Signed",
}

export enum SignatureSignStatus {
  pending = "Pending",
  signed = "Signed",
  external = "External",
}

export type DumbSignInfoParam = { name: string; email: string; phone?: string }
export type SignInfoParam = { id: string } & DumbSignInfoParam

export const DumbSignInfoParamZodObject = z.object({
  name: z.string(),
  email: z.string(),
  phone: z.string().optional(),
})

export const SignInfoParamZodObject = DumbSignInfoParamZodObject.extend({
  id: z.string(),
})

export type Signature = {
  candidateId?: string
  employeeId?: string
  name: string
  email: string
  phone?: string | null
  type: SignType
  status: SignatureSignStatus
  token?: string
  signedAt?: Date | string | null
  signatureFile?: any
  ip?: string
  confirmationInfo?: {
    name?: string
    documentNumber: string
    email: string
  }
}

export const SignatureZodObject = z.object({
  candidateId: z.string().optional(),
  employeeId: z.string().optional(),
  name: z.string(),
  email: z.string(),
  phone: z.string().nullish(),
  type: z.nativeEnum(SignType),
  status: z.nativeEnum(SignatureSignStatus),
  token: z.string().optional(),
  signedAt: z.union([z.date(), z.string()]).optional().nullable(),
  signatureFile: z.any().optional(),
  ip: z.string().optional(),
  confirmationInfo: z
    .object({
      name: z.string().optional(),
      documentNumber: z.string(),
      email: z.string(),
    })
    .optional(),
})

export interface ContractOutput extends S3File {
  version: number
}

export type Contract = {
  _id: string
  companyId: string
  flowCardId: string
  contractDuration?: string
  category: "hiring" | "resignation"
  template: S3File[]
  modelContract?: { custom: string; origin: string }[]
  status: SignStatus
  signature: Signature[]
  contractOutput: ContractOutput[]
  lastContractOutput?: S3File
}

export type ModelContract = {
  _id: string
  name: string
  description: string
  tags: string[]
  status: string
  companyId: string
  flowId: string
  html: string
  active: boolean
  preview: S3File
  file: S3File
}

export type CustomModelContract = {
  _id: string
  name: string
  description: string
  tags: string[]
  status: string
  companyId: string
  html: string
  flowId: string
  active: boolean
  preview: S3File
  file: S3File
  candidateId: S3File
  modelContractId: string
}

export type UpsertCustomModelContractResponse = CustomModelContract & {
  candidateName: string
  verifiedTags: {
    label: string
    tag: string
    value: string
  }[]
}
