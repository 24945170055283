import dispatchToast from "../../../../utils/dispatchToast"
import { GenericModal, Banner } from "../../../common"
import {
  ChecklistActionWrapper,
  ChecklistContainer,
  ChecklistIcon,
  ChecklistItem,
  ChecklistWrapper,
  Container,
} from "./styles"
import { ChecklistCard, FlowCategory } from "../../../../types"
import { Fragment, useCallback, useEffect, useRef, useState } from "react"
import {
  Button,
  LinkButton,
  TextField,
  Checkbox,
} from "@flash-tecnologia/hros-web-ui-v2"

import { useQueryClient } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import { trpc } from "@api/client"

interface ModalChecklist {
  isOpen: boolean
  handleClose: () => void
  checklist?: ChecklistCard
  flowType: FlowCategory
  cardId: string
  columnId: string
  version: number
  width?: string
}

type ChecklistMode = {
  mode: "view" | "create" | "update"
  id?: number
}

type LocalChecklist = {
  id: number
  label: string
}

const ModalChecklist = ({
  isOpen,
  handleClose,
  checklist,
  flowType,
  columnId,
  cardId,
  version,
  width = "100%",
}: ModalChecklist) => {
  const [localChecklist, setLocalChecklist] = useState<LocalChecklist[]>()
  const [checklistMode, setChecklistMode] = useState<ChecklistMode>({
    mode: checklist?.items?.length ? "view" : "create",
  })

  useEffect(() => {
    const parsedChecklist = checklist?.items?.map(({ label }, idx) => ({
      id: idx,
      label: label,
    }))
    setLocalChecklist(parsedChecklist)
  }, [checklist])

  const refEditChecklist = useRef<HTMLInputElement>(null)
  const refCreateChecklist = useRef<HTMLInputElement>(null)

  const queryClient = useQueryClient()

  const getHiringCardByIdQueryKey = getQueryKey(
    trpc.card.getHiringCardById,
    { cardId },
    "query",
  )
  const getResignationCardByIdQueryKey = getQueryKey(
    trpc.resignationCard.getResignationCardById,
    { cardId },
    "query",
  )

  const changeHiringChecklist =
    trpc.modelChecklist.changeHiringChecklist.useMutation()
  const changeResignationChecklist =
    trpc.modelChecklist.changeResignationChecklist.useMutation()

  const urls = {
    ["hiring"]: {
      key: getHiringCardByIdQueryKey,
      fn: changeHiringChecklist.mutateAsync,
    },
    ["resignation"]: {
      key: getResignationCardByIdQueryKey,
      fn: changeResignationChecklist.mutateAsync,
    },
  }

  const flowData = urls[flowType]

  const handleSubmit = () => {
    flowData.fn(
      {
        columnId: columnId,
        flowCardId: cardId,
        version,
        checklist: localChecklist?.map(({ label }) => label) || [],
      },
      {
        onSuccess: (checklists) => {
          queryClient.setQueryData(flowData.key, (oldQueryData: any) => {
            return {
              ...oldQueryData,
              version: oldQueryData.version + 1,
              checklists: checklists,
            }
          })

          dispatchToast({
            type: "success",
            content: "Checklist editada com sucesso!",
          })
          handleClose()
        },
      },
    )
  }

  const handleAddChecklist = (newItem: string) => {
    setLocalChecklist((prev) => {
      if (!prev) {
        return [{ id: 0, label: newItem }]
      }

      return [...prev, { id: prev.length, label: newItem }]
    })
    setChecklistMode({ mode: "view" })
  }

  const handleRemoveChecklist = (id: number) => {
    const updatedChecklist = localChecklist?.filter(
      (checklist) => checklist.id !== id,
    )
    setLocalChecklist(updatedChecklist)
  }

  const handleUpdateChecklist = (id: number, newValue: string) => {
    const index = localChecklist?.findIndex((checklist) => checklist.id === id)
    const updatedChecklist = localChecklist ? [...localChecklist] : []
    updatedChecklist.splice(index || 0, 1, { id, label: newValue })
    setLocalChecklist(updatedChecklist)

    setChecklistMode({ mode: "view" })
  }

  const renderActions = useCallback(() => {
    const viewMode = () => {
      return (
        <ChecklistActionWrapper>
          <Button
            variant="secondary"
            size="small"
            onClick={() => setChecklistMode({ mode: "create" })}
          >
            Adicionar tarefa
          </Button>
        </ChecklistActionWrapper>
      )
    }

    const createMode = () => {
      return (
        <Fragment>
          <ChecklistItem width={width}>
            <TextField
              ref={refCreateChecklist}
              type={"string"}
              label={"Adicionar tarefa"}
              fullWidth={true}
            />
          </ChecklistItem>

          <ChecklistActionWrapper>
            <Button
              variant="primary"
              size="small"
              onClick={() => {
                if (!refCreateChecklist.current?.value) return
                handleAddChecklist(refCreateChecklist.current?.value)
              }}
            >
              Adicionar
            </Button>

            <LinkButton
              variant="primary"
              onClick={() => setChecklistMode({ mode: "view" })}
              style={{ marginLeft: 25, paddingTop: 8 }}
            >
              Cancelar
            </LinkButton>
          </ChecklistActionWrapper>
        </Fragment>
      )
    }

    const updateMode = () => {
      return (
        <ChecklistActionWrapper>
          <Button
            variant="primary"
            size="small"
            onClick={() => {
              if (
                !refEditChecklist.current?.value ||
                checklistMode.id === undefined
              )
                return

              const newValue = refEditChecklist.current?.value
              handleUpdateChecklist(checklistMode.id, newValue)
            }}
          >
            Editar
          </Button>

          <LinkButton
            variant="primary"
            onClick={() => setChecklistMode({ mode: "view" })}
            style={{ marginLeft: 25, paddingTop: 8 }}
          >
            Cancelar
          </LinkButton>
        </ChecklistActionWrapper>
      )
    }

    switch (checklistMode.mode) {
      case "view":
        return viewMode()
      case "update":
        return updateMode()
      case "create":
        return createMode()
    }
  }, [checklistMode])

  return (
    <GenericModal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Checklist de tarefas"
      description="Edite abaixo sua lista de atividades"
      actionButtons={[
        { variant: "link", description: "Cancelar", handleAction: handleClose },
        {
          variant: "primary",
          description: "Salvar",
          handleAction: handleSubmit,
        },
      ]}
    >
      <Container>
        <Banner
          icon="IconAlertCircle"
          type="info"
          hasHideBanner={false}
          message={
            "As edições feitas por aqui afetarão apenas esta pessoa e o template de checklist desta etapa não será alterado."
          }
        />

        <ChecklistContainer>
          {localChecklist &&
            localChecklist.map((item, idx) => {
              if (checklistMode.mode === "update" && idx === checklistMode.id)
                return (
                  <ChecklistItem key={idx} width={width}>
                    <span data-id={item.id} style={{ display: "none" }}>
                      {item.label}
                    </span>
                    <TextField
                      ref={refEditChecklist}
                      type={"string"}
                      label={"Editar tarefa"}
                      defaultValue={item.label}
                      fullWidth={true}
                    />
                  </ChecklistItem>
                )

              return (
                <ChecklistItem key={idx} width={width}>
                  <ChecklistWrapper>
                    <Checkbox />
                    <span data-id={item.id}>{item.label}</span>
                  </ChecklistWrapper>

                  <ChecklistWrapper>
                    <ChecklistIcon
                      name="IconPencil"
                      fill="transparent"
                      onClick={() =>
                        setChecklistMode({
                          mode: "update",
                          id: idx,
                        })
                      }
                    />
                    <ChecklistIcon
                      name="IconTrash"
                      fill="transparent"
                      onClick={() => handleRemoveChecklist(item.id)}
                    />
                  </ChecklistWrapper>
                </ChecklistItem>
              )
            })}

          {renderActions()}
        </ChecklistContainer>
      </Container>
    </GenericModal>
  )
}

export default ModalChecklist
