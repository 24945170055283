import React, { Dispatch, SetStateAction, useMemo, useState } from "react"
import { HiringContentProps } from "../../../components/screen/CardPage/types"
import {
  ButtonContainer,
  ConfirmButton,
  ContentContainer,
  ProposalAcceptedContainer,
  RadioArea,
  RadioButton,
  RadioLabel,
  RefusedReasonContainer,
  ResendContainer,
  RightContainer,
  StyledContainedButton,
  SubContainer,
  Title,
} from "./styles"
import AcceptedProposal from "../../../assets/done.svg"
import {
  Button,
  Icons,
  Loader,
  Radio,
  TextArea,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import dispatchToast from "../../../utils/dispatchToast"
import PJProposedContent from "./PJProposedContent"
import CLTProposedContent from "./CLTProposedContent"
import InternshipProposedContent from "./InternshipProposedContent"
import { useBFF } from "../../../hooks"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useTracking } from "@utils/useTracking"
import { trpc } from "@api/client"

const ProposalSent = () => (
  <ContentContainer
    style={{
      borderBottom: "1px solid #E8E3E6",
      marginBottom: 24,
    }}
  >
    <SubContainer>
      <ProposalAcceptedContainer>
        <AcceptedProposal />
        <Title style={{ color: "#6B5B66" }}>Proposta enviada!</Title>
        <Typography
          variant="headline7"
          style={{ color: "#83727D", fontWeight: 500, marginTop: "16px" }}
        >
          Agora é só aguardar a resposta do candidato para dar continuidade á
          admissão.
        </Typography>
      </ProposalAcceptedContainer>
    </SubContainer>
  </ContentContainer>
)

interface StatusAcceptedProps extends HiringContentProps {}

const StatusRefused: React.FC<StatusAcceptedProps> = ({ card, flow }) => {
  const { hiring } = useBFF()
  const queryClient = useQueryClient()
  const tracking = useTracking()

  const { mutateAsync: updateCard } = trpc.card.updateHiringCard.useMutation()

  const { mutateAsync: resetProposal, isLoading: resetingProposal } =
    useMutation(
      async () => {
        tracking.trackEvent({
          name: `company_hiring_${flow.subcategory}_makenewproposal_clicked`,
        })
        await hiring.resetRefusedFields.mutateAsync({
          candidateId: card.candidateId,
        })
        await updateCard({
          approvalStatus: null,
          approvalCommentary: null,
          flowCardId: card._id,
        })
        await hiring.updateCardStatus.mutateAsync({
          newStatus: "Envio da proposta pendente",
          flowCardId: card._id,
          version: card.version,
        })

        queryClient.invalidateQueries()
      },
      {
        onError: async () => {
          dispatchToast({
            content: "Houve um problema ao fazer uma nova proposta",
            type: "error",
          })
        },
      },
    )

  const { mutateAsync: moveCardToArchived, isLoading: isMovingToArchived } =
    useMutation(
      async () => {
        const findArchived =
          flow.columns?.find((c) => c.name === "Arquivado") || ""

        if (!findArchived) {
          return
        }
        await hiring.moveColumn.mutateAsync({
          flowCardId: card._id,
          newColumnId: findArchived?._id,
          newPosition: 0,
          version: card.version,
        })
        await hiring.updateCardStatus.mutateAsync({
          newStatus: "Arquivado",
          flowCardId: card._id,
          version: card.version + 1,
        })
      },
      {
        onSuccess: async () => {
          dispatchToast({
            content: "Card arquivado com sucesso!",
            type: "success",
          })
        },
      },
    )

  return (
    <ContentContainer>
      <SubContainer>
        <div style={{ textAlign: "center" }}>
          <Title style={{ color: "#6B5B66" }}>
            Infelizmente sua proposta foi recusada 😞
          </Title>
          {card.approvalCommentary ||
          card.candidate.proposalLetterInfo?.refuseReason ? (
            <RefusedReasonContainer>
              <Typography
                variant="headline8"
                style={{
                  color: "#53464F",
                  fontWeight: 800,
                }}
              >
                Motivo da recusa
              </Typography>
              <Typography
                variant="headline8"
                style={{
                  color: "#53464F",
                  fontStyle: "normal",
                  fontWeight: 500,
                  marginTop: "16px",
                }}
              >
                {card.approvalCommentary ||
                  card.candidate.proposalLetterInfo?.refuseReason}
              </Typography>
            </RefusedReasonContainer>
          ) : null}
          <ResendContainer>
            <Button
              size="medium"
              variant="secondary"
              loading={isMovingToArchived}
              disabled={isMovingToArchived}
              onClick={async () => {
                await moveCardToArchived()
              }}
            >
              <span>Mover candidato para Arquivados</span>
              <Icons
                name="IconArchive"
                style={{ marginLeft: "8px" }}
                fill={"transparent"}
              />
            </Button>

            <Button
              variant="primary"
              size="medium"
              onClick={async () => {
                await resetProposal()
              }}
              loading={resetingProposal}
              disabled={resetingProposal}
            >
              <span>Fazer nova proposta</span>
              <Icons
                name={"IconFile"}
                style={{
                  marginLeft: "8px",
                  fill: "transparent",
                  stroke: "#fff",
                }}
              />
            </Button>
          </ResendContainer>
        </div>
      </SubContainer>
    </ContentContainer>
  )
}

interface ProposalShareLinkProps {
  value: string
}
const ProposalShareLink: React.FC<ProposalShareLinkProps> = ({ value }) => (
  <div
    style={{
      borderBottom: "1px solid #E8E3E6",
      marginBottom: 24,
    }}
  >
    <SubContainer>
      <ContentContainer>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
            marginBottom: "24px",
          }}
        >
          <Title
            style={{
              fontSize: "20px",
              lineHeight: "24px",
              color: "#53464F",
            }}
          >
            Compartilhe o link
          </Title>

          <Typography
            variant="body4"
            style={{
              color: "#83727D",
              lineHeight: "20px",
            }}
          >
            Copie e compartilhe o link abaixo caso a pessoa tenha dificuldades
            de acessar sua caixa de e-mail{" "}
          </Typography>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "24px",
            }}
          >
            <TextField
              name={"link"}
              label={"Link"}
              fullWidth={true}
              value={value}
              type={"text"}
              InputProps={{
                endAdornment: (
                  <Icons
                    name="IconLink"
                    fill={"transparent"}
                    style={{ marginLeft: "10px" }}
                  />
                ),
              }}
            />

            <Button
              size={"small"}
              variant="primary"
              style={{
                marginLeft: "24px",
                alignSelf: "center",
              }}
              onClick={() => {
                navigator.clipboard.writeText(value)
                dispatchToast({
                  content: "Link copiado",
                  type: "success",
                })
              }}
            >
              <>
                <Typography variant="body3" style={{ fontWeight: 700 }}>
                  Copiar link
                </Typography>
                <Icons name="IconCopy" fill="transparent" />
              </>
            </Button>
          </div>
        </div>
      </ContentContainer>
    </SubContainer>
  </div>
)

interface ProposedContent extends HiringContentProps {
  sendToCandidate: boolean
  setHasRefused: Dispatch<SetStateAction<boolean>>
}
const GenericContent: React.FC<ProposedContent> = ({ flow, ...others }) => {
  // component can be CLTProposedContent, PJProposedContent or InternshipProposedContent
  let Component: any
  switch (flow.subcategory) {
    case "clt":
      Component = CLTProposedContent
      break
    case "pj":
      Component = PJProposedContent
      break
    case "internship":
      Component = InternshipProposedContent
      break
    default:
      break
  }
  return <Component {...others} />
}

interface ProposalProps extends HiringContentProps {
  setHasRefused: Dispatch<SetStateAction<boolean>>
}
const Proposal: React.FC<ProposalProps> = ({ card, flow, setHasRefused }) => {
  const [sendToCandidate, setSendToCandidate] = useState(() => {
    if (card.metadata?.sendToCandidate) return !!card.metadata.sendToCandidate
    return true
  })

  const findEmailLink = useMemo(
    () => card.emailLink?.find((e) => e.name === "proposalLetter"),
    [card.emailLink],
  )

  return (
    <div>
      {!!findEmailLink?.link && (
        <>
          <ProposalSent />
          <ProposalShareLink value={findEmailLink.link} />
        </>
      )}

      <ContentContainer>
        <SubContainer>
          <div
            style={{
              paddingBottom: "40px",
            }}
          >
            <Title
              style={{
                fontSize: "20px",
                lineHeight: "24px",
                color: "#53464F",
              }}
            >
              Resposta da proposta
            </Title>
            <Typography
              variant="body4"
              style={{ fontWeight: 400, color: "#83727D" }}
            >
              Escolha a melhor forma para receber a resposta da pessoa. Você
              pode solicitar diretamente a pessoa via e-mail ou caso já tenha
              tido uma resposta, pode preencher manualmente você mesmo.
            </Typography>

            <RadioArea>
              <RadioButton onClick={() => setSendToCandidate(true)}>
                <Radio checked={sendToCandidate} />
                <RadioLabel variant="body3">
                  Solicitar resposta da pessoa
                </RadioLabel>
              </RadioButton>
              <RadioButton onClick={() => setSendToCandidate(false)}>
                <Radio checked={!sendToCandidate} />
                <RadioLabel variant="body3">Preencher manualmente</RadioLabel>
              </RadioButton>
            </RadioArea>
          </div>
        </SubContainer>
      </ContentContainer>
      <GenericContent
        flow={flow}
        card={card}
        sendToCandidate={sendToCandidate}
        setHasRefused={setHasRefused}
      />
    </div>
  )
}

interface RefusingProposalProps extends HiringContentProps {
  setHasRefused: Dispatch<SetStateAction<boolean>>
}

const RefusingProposal: React.FC<RefusingProposalProps> = ({
  card,
  flow,
  setHasRefused,
}) => {
  const queryClient = useQueryClient()
  const tracking = useTracking()
  const { hiring } = useBFF()

  const [refusedCommentary, setRefusedCommentary] = useState("")

  const { mutateAsync: updateHiringCard, isLoading: isRefusing } =
    trpc.card.updateHiringCard.useMutation()

  const handleRefuseProposal = async (params: {
    refusedReason: string | null
  }) => {
    await updateHiringCard({
      approvalStatus: "refused",
      approvalCommentary: params?.refusedReason,
      flowCardId: card._id,
    })
    await hiring.refuseProposal.mutateAsync({
      candidateId: card.candidateId,
      refuseReason: params?.refusedReason,
    })
    await hiring.updateCardStatus.mutateAsync(
      {
        newStatus: "Proposta recusada",
        flowCardId: card._id,
        version: card.version,
      },
      {
        onSuccess: async () => {
          setHasRefused(false)
          setRefusedCommentary("")
          dispatchToast({
            content: "Proposta recusada com sucesso!",
            type: "success",
          })
        },
      },
    )
    queryClient.invalidateQueries()
  }

  return (
    <ContentContainer>
      <SubContainer>
        <Title
          style={{
            fontSize: "20px",
            lineHeight: "24px",
            color: "#53464F",
            textAlign: "center",
          }}
        >
          Tem certeza que deseja recusar a proposta?
        </Title>
        <TextArea
          placeholder="Motivo da recusa (Opcional)"
          value={refusedCommentary}
          onChange={(e) => setRefusedCommentary(e?.currentTarget?.value)}
          maxLength={100}
        />
        <ButtonContainer>
          <ConfirmButton
            type={"button"}
            onClick={() => {
              setHasRefused(false)
              setRefusedCommentary("")
            }}
            disabled={isRefusing}
          >
            <span>Voltar</span>
          </ConfirmButton>
          <StyledContainedButton
            type={"button"}
            disabled={isRefusing}
            onClick={async () => {
              tracking.trackEvent({
                name: `company_hiring_${flow.subcategory}_movecardtoarchived_clicked`,
              })

              handleRefuseProposal({
                refusedReason: refusedCommentary?.length
                  ? refusedCommentary
                  : null,
              })
            }}
          >
            {isRefusing ? (
              <Loader size="small" variant="primary" />
            ) : (
              <>
                <span>Recusou a oferta</span>
              </>
            )}
          </StyledContainedButton>
        </ButtonContainer>
      </SubContainer>
    </ContentContainer>
  )
}

const GenericProposedLetter: React.FC<HiringContentProps> = ({
  card,
  flow,
}) => {
  const [hasRefused, setHasRefused] = useState(false)

  if (card?.candidate?.proposalLetterInfo?.proposalStatus === "refused") {
    return (
      <RightContainer>
        <StatusRefused card={card} flow={flow} />
      </RightContainer>
    )
  }

  return (
    <RightContainer>
      {hasRefused ? (
        <RefusingProposal
          card={card}
          flow={flow}
          setHasRefused={setHasRefused}
        />
      ) : (
        <Proposal card={card} flow={flow} setHasRefused={setHasRefused} />
      )}
    </RightContainer>
  )
}

export default GenericProposedLetter
