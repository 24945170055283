import { useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Container,
  ContentContainer,
  SubContainer,
  Title,
  Description,
} from "./styles"
import {
  Button,
  Loader,
  Skeleton,
  SelectField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import dispatchToast from "../../../utils/dispatchToast"
import { useNavigate } from "react-router-dom"
import { Flow, HiringCard } from "../../../types"
import {
  ConfirmationModal,
  EmptyStateSVG,
  HiringLimitDate,
  LimitDateRef,
  WizardCardLink,
} from "../../../components"
import { isObjectEmpty } from "../../../utils"
import { DocumentList } from "./DocumentList"
import { HiringLimitDateEnum } from "bff/src/types/hiring"
import { trpc } from "@api/client"
import { useTracking } from "@utils/useTracking"

const getLimitDateStep = (subcategory?: "clt" | "internship" | "pj") => {
  switch (subcategory) {
    case "clt":
      return HiringLimitDateEnum.sendDocuments_Clt
    case "internship":
      return HiringLimitDateEnum.sendDocuments_Estag
    case "pj":
      return HiringLimitDateEnum.sendDocuments_Pj
    default:
      throw new Error("flow subcategory must be clt | internship | pj")
  }
}

const GenericContent = ({ card, flow }: { card: HiringCard; flow: Flow }) => {
  const [confirmationSendModal, setConfirmationSendModal] =
    useState<boolean>(false)
  const [selectDocumentation, setSelectDocumentation] = useState({
    _id: card.formConfigId,
    extensionId: card.formExtensionId,
  })

  const navigate = useNavigate()
  const tracking = useTracking()
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const limitDateRef = useRef<LimitDateRef>(null)

  const findEmailLink = useMemo(
    () =>
      card.emailLink?.find(
        (e) => e.columnId === card.columnId && e.name === "documentsRequest",
      ),
    [card],
  )

  const { data: options, isInitialLoading: isDocumentListLoading } =
    trpc.formConfig.getFormConfigByCompanyId.useQuery(
      {
        companyId: card.companyId,
      },
      {
        enabled: !!card,
        refetchOnWindowFocus: false,
        retry: false,
      },
    )

  const { data: getFormConfigData, isInitialLoading: isFormConfigLoading } =
    trpc.formConfig.getFormConfig.useQuery(
      {
        formConfigId: selectDocumentation?._id,
        extensionId: selectDocumentation?.extensionId,
      },
      {
        enabled: !!selectDocumentation?._id,
        refetchOnWindowFocus: false,
        retry: false,
      },
    )

  const formConfig = getFormConfigData as unknown as any

  const { mutateAsync: sendDocumentsRequest, isLoading: isSendingDocRequest } =
    trpc.hiring.sendDocumentsRequest.useMutation()

  const handleSubmit = async () => {
    tracking.trackEvent({
      name: `company_hiring_${flow.subcategory}_requestdocuments_clicked`,
    })

    const errorsOnValidate = await limitDateRef.current?.validate()
    if (!isObjectEmpty(errorsOnValidate)) {
      dispatchToast({
        content: "Por favor preencha informações de prazo limite",
        type: "error",
      })
      return
    }

    await limitDateRef.current?.submit()

    if (!flow.subcategory) {
      throw new Error("flow subcategory not defined")
    }

    await sendDocumentsRequest(
      {
        flowCardId: card._id,
        columnId: card.columnId,
        candidateId: card.candidateId,
        subcategory: flow.subcategory,
        subject:
          "O RH da sua empresa está solicitando o envio de alguns documentos",
        version: card.version,
        formConfigId: selectDocumentation._id,
        formExtensionId: selectDocumentation.extensionId || "",
      },
      {
        onSuccess: async () => {
          navigate(`/flows/hiring`, {
            replace: true,
          })

          dispatchToast({
            content: "Solicitação de envio de documentos enviada com sucesso",
            type: "success",
          })
        },
        onError: () => {
          dispatchToast({
            content:
              "Houve um problema ao solicitar os documentos do candidato",
            type: "error",
          })
        },
      },
    )
  }

  return (
    <Container>
      <HiringLimitDate
        ref={limitDateRef}
        cardId={card._id}
        step={getLimitDateStep(flow.subcategory)}
        limitDate={card.limitDates?.find(
          (limitDate) => limitDate.step === getLimitDateStep(flow.subcategory),
        )}
        style={{
          marginBottom: 24,
          paddingBottom: "24px",
          borderBottom: "1px solid #EBE6E9",
        }}
      />

      {findEmailLink && (
        <WizardCardLink multiplesEmailLinks={[{ emailLink: findEmailLink }]} />
      )}

      <SubContainer>
        <ContentContainer style={{ paddingBottom: "48px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              textAlign: "left",
              marginBottom: "24px",
            }}
          >
            <Title variant="headline8">Lista de documentos</Title>
            <Description variant="body4">
              Selecione a lista de documentos correspondente ao tipo de
              contratação da pessoa
            </Description>
          </div>
          {isDocumentListLoading || isFormConfigLoading ? (
            <Skeleton variant="rectangular" height={60} />
          ) : (
            <SelectField
              value={selectDocumentation._id}
              variant={"filled"}
              InputProps={{ disableUnderline: true }}
              label={"Modelo de documentação*"}
              noOptionsText="Sem opções disponíveis"
              options={options?.map((e) => ({
                label: e.name,
                value: e._id,
              }))}
              onSelectChange={(e, value) => {
                tracking.trackEvent({
                  name: `company_hiring_${flow.subcategory}_documents_selectmodel_clicked`,
                })

                const documentList = value.value
                const foundDocument = options?.find(
                  (s) => s._id === documentList,
                )

                setSelectDocumentation({
                  _id: foundDocument?._id || "",
                  extensionId: foundDocument?.extensionId || "",
                })
              }}
            />
          )}
        </ContentContainer>
      </SubContainer>

      {!isFormConfigLoading && !isDocumentListLoading ? (
        <>
          {formConfig ? (
            <DocumentList flowId={card.flowId} formConfig={formConfig} />
          ) : (
            <div
              style={{
                borderTop: "1px solid #EBE6E9",
                borderBottom: "1px solid #EBE6E9",
                padding: "24px 0px",
                marginBottom: "24px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <EmptyStateSVG width={"100%"} />
              <Typography
                variant="body2"
                variantColor="#83727D"
                style={{
                  marginTop: "-30px",
                }}
              >
                {t("documentsSend.emptyStateText")}
              </Typography>
            </div>
          )}

          <ContentContainer>
            <SubContainer>
              <Button
                variant="primary"
                size="large"
                onClick={() => setConfirmationSendModal(true)}
                disabled={!selectDocumentation._id || isSendingDocRequest}
                style={{
                  marginBottom: "20px",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                {isSendingDocRequest ? (
                  <Loader variant="primary" size="small" />
                ) : (
                  <>{t("documentsSend.buttonText")}</>
                )}
              </Button>

              <ConfirmationModal
                variant="primary"
                isOpen={confirmationSendModal}
                onClose={() => setConfirmationSendModal(false)}
                title="Tem certeza que deseja solicitar os documentos?"
                description="Verifique se todas as informações foram preenchidas corretamente."
                confirmButton={{
                  onClick: () => handleSubmit(),
                  isLoading: isSendingDocRequest,
                  text: "Prosseguir e enviar",
                  icon: "IconArrowRight",
                }}
              />
            </SubContainer>
          </ContentContainer>
        </>
      ) : (
        <></>
      )}
    </Container>
  )
}

export default GenericContent
