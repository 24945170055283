import React from "react"
import { CandidateSignInformation } from "../../../types"
import { useNavigate, useSearchParams } from "react-router-dom"
import dispatchToast from "../../../utils/dispatchToast"
import {
  ConfirmationModal,
  FocusedFlow,
  Footer,
  FooterOptions,
  Header,
  HeaderBreadcrumb,
} from "../../../components"
import { MassSignatureContracts, MassSignatureSigning } from "./screen"
import { SpinnerContainer } from "./styles"
import { Loader, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import { ModalIneligibleCandidates } from "./components/ModalIneligibleCandidates"
import { trpc } from "@api/client"
import i18n from "@i18n"
import { useTranslation } from "react-i18next"

export enum StepsEnum {
  Contract = 0,
  Signing = 1,
}

type StepContent = {
  stepLabel: string
  title: string
  description: string
}

type ConfirmationData = {
  name: string
  documentNumber: string
  email: string
}

export interface IMassSignatureContext {
  setStep(step: StepsEnum): void
  validCandidates: CandidateSignInformation[]
  invalidCandidates: CandidateSignInformation[]
  confirmationData?: ConfirmationData
  removeCandidates(flowCardId: string): void
  setFooter(options: FooterOptions): void
  setCurrentStep(step: StepsEnum): void
  setConfirmationData(data: ConfirmationData): void
}

const stepContents: { [key in StepsEnum]: StepContent } = {
  [StepsEnum.Contract]: {
    stepLabel: i18n.t("page.hiring.massSignature.contract.stepLabel"),
    title: i18n.t("page.hiring.massSignature.contract.title"),
    description: i18n.t("page.hiring.massSignature.contract.description"),
  },
  [StepsEnum.Signing]: {
    stepLabel: i18n.t("page.hiring.massSignature.signing.stepLabel"),
    title: i18n.t("page.hiring.massSignature.signing.title"),
    description: i18n.t("page.hiring.massSignature.signing.description"),
  },
}

const breadcrumbs: HeaderBreadcrumb[] = [
  {
    label: i18n.t("page.hiring.massSignature.breadcrumbs.hiring"),
    route: `/flows/hiring`,
  },
  {
    label: i18n.t("page.hiring.massSignature.breadcrumbs.massSignature"),
  },
]

export const massSignatureContext = React.createContext<IMassSignatureContext>({
  setStep: () => {},
  validCandidates: [],
  invalidCandidates: [],
  removeCandidates: () => {},
  setFooter: () => {},
  setCurrentStep: () => {},
  setConfirmationData: () => {},
})

export const MassSignaturePage = () => {
  const [confirmationData, setConfirmationData] = React.useState<
    ConfirmationData | undefined
  >(undefined)
  const [modalDeleteConfirmation, setModalDeleteConfirmation] =
    React.useState<boolean>(false)
  const [modalCandidatesIneligibles, setModalCandidatesIneligibles] =
    React.useState<boolean>(false)
  const [currentStep, setCurrentStep] = React.useState<StepsEnum>(
    StepsEnum.Contract,
  )
  const [currentFooter, setCurrentFooter] = React.useState<
    FooterOptions | undefined
  >(undefined)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const theme = useTheme()

  const [t] = useTranslation("translations", {
    keyPrefix: "page.hiring.massSignature",
  })

  const flowCardsId = searchParams.get("flowCardsId")?.split(",") || []

  const { data: candidates, isInitialLoading: isCandidatesLoading } =
    trpc.massSignature.getAndValidateSigningCandidates.useQuery(
      { flowCardIds: flowCardsId },
      {
        retry: 0,
        refetchOnWindowFocus: false,
        enabled: !!flowCardsId,
        onError: (err: any) => {
          dispatchToast({ content: err.message, type: "error" })
          navigate(-1)
        },
      },
    )

  React.useEffect(() => {
    if (!candidates) return
    const hasSomeoneIneligible =
      candidates.filter((candidate) => !candidate.isElegibleToSign)?.length ?? 0
    if (hasSomeoneIneligible) setModalCandidatesIneligibles(true)
  }, [candidates])

  React.useEffect(() => {
    if (!flowCardsId || flowCardsId?.length === 0) navigate("/flows/hiring")
  }, [flowCardsId])

  const value: IMassSignatureContext = React.useMemo(
    () => ({
      setStep: (step: StepsEnum) => setCurrentStep(step),
      validCandidates:
        candidates?.filter((candidate) => candidate.isElegibleToSign) || [],
      invalidCandidates:
        candidates?.filter((candidate) => !candidate.isElegibleToSign) || [],
      confirmationData,
      removeCandidates,
      setFooter: (options: FooterOptions) => setCurrentFooter(options),
      setCurrentStep: (step: StepsEnum) => setCurrentStep(step),
      setConfirmationData: (data: ConfirmationData) =>
        setConfirmationData(data),
    }),
    [candidates, confirmationData],
  )

  const step = React.useMemo(() => {
    switch (currentStep) {
      case StepsEnum.Contract:
        return <MassSignatureContracts />
      case StepsEnum.Signing:
        return <MassSignatureSigning />
    }
  }, [currentStep])

  function removeCandidates(toBeRemovedFlowCardId: string) {
    if (flowCardsId?.length === 1) {
      setModalDeleteConfirmation(true)
      return
    }
    const cleanedFlowCardIds = flowCardsId.reduce(
      (prev: string[], flowCardId) => {
        const findValidated = candidates?.find(
          (signInfo) => signInfo.flowCardId === flowCardId,
        )
        if (!findValidated) return prev
        if (!findValidated.isElegibleToSign) return prev

        return [...prev, flowCardId]
      },
      [],
    )
    const filteredFlowCards = cleanedFlowCardIds.filter(
      (id) => id !== toBeRemovedFlowCardId,
    )
    setSearchParams(
      new URLSearchParams(`flowCardsId=${filteredFlowCards.join(",")}`),
    )
  }

  if (!candidates || isCandidatesLoading) {
    return (
      <FocusedFlow>
        <SpinnerContainer>
          <Loader variant="primary" size="large" />
        </SpinnerContainer>
      </FocusedFlow>
    )
  }

  return (
    <massSignatureContext.Provider value={value}>
      <FocusedFlow>
        <Header
          breadcrumbs={breadcrumbs}
          steps={Object.keys(stepContents).map(
            (key) => stepContents[key]?.stepLabel || "",
          )}
          currentStep={currentStep}
          style={{ position: "fixed", zIndex: 2, top: "0px" }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "110px 30px",
          }}
        >
          <Typography
            variant="headline6"
            variantColor={theme.colors.neutral[20]}
            style={{ marginBottom: 30 }}
          >
            {t("title")}
          </Typography>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 15,
              marginBottom: 20,
            }}
          >
            <Typography
              variant="headline7"
              variantColor={theme.colors.secondary[50]}
            >
              {stepContents[currentStep].title}
            </Typography>
            <Typography variant="body3" variantColor={theme.colors.neutral[50]}>
              {stepContents[currentStep].description}
            </Typography>
          </div>

          {step}
        </div>

        {currentFooter && (
          <Footer
            options={currentFooter}
            style={{ position: "fixed", zIndex: 2, bottom: "0px" }}
          />
        )}
      </FocusedFlow>

      {modalDeleteConfirmation && (
        <ConfirmationModal
          variant="primary"
          isOpen={modalDeleteConfirmation}
          onClose={() => setModalDeleteConfirmation(false)}
          confirmButton={{
            text: t("deleteConfirmationModal.confirmButton"),
            onClick: async () => {
              navigate("/flows/hiring")
            },
          }}
          title={t("deleteConfirmationModal.title")}
          description={t("deleteConfirmationModal.description")}
        />
      )}

      {modalCandidatesIneligibles && (
        <ModalIneligibleCandidates
          isOpen={modalCandidatesIneligibles}
          handleClose={() => setModalCandidatesIneligibles(false)}
          candidates={candidates}
        />
      )}
    </massSignatureContext.Provider>
  )
}
