import styled from "styled-components"

export const PreviewContainer = styled.div`
  display: grid;
  grid-template-columns: 45px 1fr max-content;
  align-items: center;
  gap: 10px;
  padding: 32px 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral90};
  border-radius: 12px;
  overflow: scroll;
`

export const PreviewImage = styled.div<{ url?: string }>`
  width: 45px;
  height: 45px;
  background-color: #ccc;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
`

export const localStyles = {
  linkButton: {
    alignSelf: "center",
  },
}
