import { LinkButton, LinkButtonProps } from "@flash-tecnologia/hros-web-ui-v2"
import styled, { css } from "styled-components"

export interface LinkButtonFitContentProps extends LinkButtonProps {
  color?: string
}

export const StyledLinkButton = styled(LinkButton)<LinkButtonFitContentProps>`
  ${({ color }) =>
    color
      ? css`
          && {
            &.MuiButtonBase-root {
              color: ${color} !important;

              &::before {
                border-bottom: 2px solid ${color} !important;
              }
            }
          }
        `
      : ""}
`
