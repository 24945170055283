import { trpc } from "@api/client"
import {
  Divider,
  Icons,
  LinkButton,
  SelectField,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import i18n from "@i18n"
import { getSignatureStatus } from "@utils/getSignatureStatus"
import { useFormik } from "formik"
import React, { RefObject, useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import { UploadMultiples } from "../../../components"
import {
  Contract,
  ContractOutput,
  ResignationCard,
  S3File,
  SendSignRequestResponse,
  SignStatus,
} from "../../../types"
import dispatchToast from "../../../utils/dispatchToast"
import {
  Section,
  SectionSubTitleAccordion,
  SectionTitle,
} from "../../Hiring/Signature/styles"
import { SearchEmployeeField } from "./SearchEmployeeField"
import { SignatureAccordion } from "./SignatureAccordion"
import { ContentContainer, FormButton, SubContainer } from "./styles"
import { WitnessForm, WitnessFormRef } from "./WitnessForm"
import { mapSendSignRequestResponseToContract } from "@utils/mapSendSignRequestResponseToContract"
import { useTracking } from "@utils/useTracking"

export const employeeValidationSchema = yup.object({
  name: yup.string().required("Campo obrigatório"),
})
export const personValidationSchema = yup.object({
  email: yup.string().email("E-mail inválido").required("Campo obrigatório"),
})

interface RequestSignatureProps {
  card: ResignationCard
  contract?: Partial<Contract>
  setContract: (contract: Partial<Contract>) => void
}

export const RequestSignature: React.FC<RequestSignatureProps> = ({
  card,
  contract,
  setContract,
}) => {
  const utils = trpc.useUtils()
  const tracking = useTracking()
  const [witnessSignatures, setWitnessSignatures] = useState<
    RefObject<WitnessFormRef>[]
  >([])

  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignation",
  })
  React.useEffect(() => {
    if (witnessSignatures?.length > 0) return
    setWitnessSignatures([React.createRef<WitnessFormRef>()])
  }, [])

  const phone = React.useMemo(
    () => card?.employee?.phoneNumber,
    [card?.employee?.phoneNumber],
  )
  const {
    mutateAsync: updateResignationCard,
    isLoading: isUpdatingCardLoading,
  } = trpc.card.updateResignationCard.useMutation({
    retry: false,
    onError: (err: any) => {
      dispatchToast({
        type: "error",
        content: err.message || "Ocorreu um erro ao atualizar o card.",
      })
    },
  })

  const {
    mutateAsync: sendResignationSignRequest,
    isLoading: isLoadingSendSignRequest,
  } = trpc.resignation.sendSignRequest.useMutation({
    onError: (err: any) => {
      dispatchToast({
        content:
          err.data?.userFriendlyError?.message ??
          i18n.t("error.internalServerError"),
        type: "error",
      })
    },
  })

  const { mutateAsync: updateResignationCardStatus } =
    trpc.resignationCard.updateResignationCardStatus.useMutation({
      onSuccess: () => {
        dispatchToast({
          content: t("messages.sendingSignRequestSuccessful"),
          type: "success",
        })

        utils.resignationCard.getResignationCardById.invalidate({
          cardId: card._id,
        })

        utils.contract.getContractByFlowCardId.invalidate({
          flowCardId: card._id,
        })
      },
      onError: (err: any) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
    })

  const employeeFormik = useFormik<{
    name: string
    id: string
    email: string
    phone: string
  }>({
    initialValues: {
      name: "",
      id: "",
      email: "",
      phone: "",
    },
    onSubmit: () => {},
    validationSchema: employeeValidationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    validateOnBlur: true,
  })

  const personFormik = useFormik<{
    name: string
    id: string
    email: string
    phone: string
  }>({
    initialValues: {
      name: card?.employee?.name || "",
      id: card?.employee?.id || "",
      email: "",
      phone: phone || "",
    },
    onSubmit: () => {},
    validationSchema: personValidationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  })

  const handleUpload: React.ComponentProps<typeof UploadMultiples>["onUpload"] =
    useCallback(
      async (file) => {
        tracking.trackEvent({
          name: `company_resignation_fileupload_contract_clicked`,
        })

        const templates: S3File[] = contract?.template || []
        const contractOutputParams: ContractOutput[] =
          contract?.contractOutput || []

        templates.push(file)
        contractOutputParams.push({
          ...file,
          version: (contract?.contractOutput?.length || 0) + 1,
        })

        setContract({
          template: templates,
          contractOutput: contractOutputParams,
          signature: [],
          _id: "",
          status: SignStatus.pending,
          category: "resignation",
          companyId: card.companyId,
          flowCardId: card._id,
        })
      },
      [contract, card],
    )

  const validateWitness = (
    acc: boolean,
    ref: React.RefObject<WitnessFormRef>,
  ) => {
    ref?.current?.handleSubmit()
    return acc || ref?.current?.isValid === false
  }

  const isWitnessInvalid = () => {
    return witnessSignatures.reduce(validateWitness, false)
  }

  const handleSubmit = async () => {
    if (!card?.employee) {
      return dispatchToast({
        type: "error",
        content:
          "Houve um erro ao obter dados pessoais. Por favor, contate o suporte!",
      })
    }

    if (!contract?.template?.length) {
      return dispatchToast({
        type: "error",
        content: "Por favor, faça o upload do contrato!",
      })
    }
    personFormik.handleSubmit()
    if (!personFormik.isValid) {
      return dispatchToast({
        type: "error",
        content: "Verifique os campos do formulario da pessoa",
      })
    }
    if (isWitnessInvalid()) {
      return dispatchToast({
        type: "error",
        content: "Verifique os campos do formulario da testemunha",
      })
    }
    employeeFormik.handleSubmit()
    if (!employeeFormik.isValid) {
      return dispatchToast({
        type: "error",
        content: "Verifique os campos do formulario da empresa",
      })
    }
    await updateResignationCard({
      resignationEmail: personFormik.values.email,
      flowCardId: card._id,
    })

    const filteredWitness = witnessSignatures.filter((ref) => {
      const values = ref.current?.values

      if (values?.isEmployee && (!values.id || values.id === "")) return false
      if (!values?.name || !values?.email) return false

      return true
    })

    const sendSignRequestResponse: SendSignRequestResponse =
      await sendResignationSignRequest({
        cardId: card?._id,
        companyId: card?.companyId,
        columnId: card?.columnId,
        employee: {
          id: card?.employee.id,
          name: card?.employee.name,
          email: personFormik.values.email,
          phone,
        },
        witness: filteredWitness.map((ref) => {
          const values = ref.current?.values
          return {
            name: values?.name || "",
            email: values?.email || "",
            phone: values?.phone || "",
          }
        }),
        companyEmployee: {
          id: employeeFormik.values.id,
          name: employeeFormik.values.name,
          email: employeeFormik.values.email,
          phone: employeeFormik.values.phone,
        },
        template: contract.template.map(
          ({ value, ...s3WithoutValue }) => s3WithoutValue,
        ),
        contractOutput:
          contract.contractOutput?.map(
            ({ value, ...s3WithoutValue }) => s3WithoutValue,
          ) ?? [],
      })

    const updatedContract = mapSendSignRequestResponseToContract(
      sendSignRequestResponse,
    )

    const signatureStatus = getSignatureStatus(updatedContract.signature)

    await updateResignationCardStatus({
      flowCardId: card._id,
      newStatus: signatureStatus,
      version: card.version,
    })
  }

  const onFileRemove: React.ComponentProps<
    typeof UploadMultiples
  >["onRemove"] = async (file) => {
    const filteredTemplate = contract?.template?.filter(
      (c) => c.path !== file.path,
    )
    const filteredOutput = contract?.contractOutput?.filter(
      (c) => c.path !== file.path,
    )

    setContract({
      ...contract,
      template: filteredTemplate,
      contractOutput: filteredOutput,
    })
  }
  return (
    <SubContainer>
      <ContentContainer>
        <div>
          <Typography variant="headline8" weight={700} variantColor="#53464F">
            Documentos a serem assinados
          </Typography>
          <Typography variant="body4" weight={400} variantColor="#83727D">
            Faça o upload dos documentos a serem assinados.
          </Typography>
        </div>
        <UploadMultiples
          accept={["pdf"]}
          module="employee-pii"
          folder={`${card.companyId}/${card.employeeId}/${card._id}/resignation/contract/${card.name}`}
          label="Documentos para assinar"
          maxSize={5242880}
          onUpload={handleUpload}
          onRemove={onFileRemove}
          values={contract?.template}
        />
      </ContentContainer>
      <Divider orientation="horizontal" />
      <ContentContainer>
        <Section>
          <SectionTitle variant="headline8">Destinatários</SectionTitle>
          <SectionSubTitleAccordion variant="body4">
            Confira e preencha os dados dos destinatários no fluxo de assinatura
          </SectionSubTitleAccordion>
        </Section>
        <SignatureAccordion
          title="Pessoa"
          description="Preencha as informações abaixo conforme os dados do destinatário."
        >
          <>
            <div>
              <TextField
                id="name"
                name="name"
                label="Nome completo"
                disabled
                fullWidth
                value={card?.employee?.name}
              />
            </div>
            <div>
              <TextField
                id="email"
                name="email"
                label="E-mail"
                fullWidth
                value={personFormik.values.email}
                onChange={personFormik.handleChange}
                onBlur={personFormik.handleBlur}
                error={
                  personFormik.touched.email &&
                  Boolean(personFormik.errors.email)
                }
                helperText={
                  personFormik.touched.email && personFormik.errors.email
                }
              />
            </div>
            <div>
              <TextField
                id="phone"
                name="phone"
                label="Celular"
                disabled
                fullWidth
                defaultValue={phone || "Não cadastrado"}
              />
            </div>
            <div>
              <SelectField
                name="toSign"
                label="Assinar como"
                disabled
                fullWidth
                value="person"
                options={[
                  {
                    label: "Pessoa",
                    value: "person",
                  },
                ]}
              />
            </div>
          </>
        </SignatureAccordion>
        <SignatureAccordion
          title="Testemunhas"
          description={`Preencha as informações abaixo conforme os dados dos destinatários.\nObs: As testemunhas da assinatura devem ser funcionários ativos na empresa.`}
        >
          <>
            {witnessSignatures.map((ref, index) => (
              <ContentContainer
                key={`witness_form${index + new Date().toISOString()}`}
              >
                <WitnessForm
                  title={`Testemunha ${index + 1}`}
                  ref={ref}
                  oldValues={ref?.current?.values}
                  isWitnessDuplicated={(employeeId) => {
                    return (
                      employeeFormik.values.id === employeeId ||
                      witnessSignatures.some(
                        (ref) => ref?.current?.values?.id === employeeId,
                      )
                    )
                  }}
                  onRemove={() => {
                    if (witnessSignatures?.length === 1) {
                      witnessSignatures[index].current?.resetForm()
                      return
                    }

                    setWitnessSignatures((prev) =>
                      prev.filter((_, i) => i !== index),
                    )
                  }}
                />
                <div>
                  {index < witnessSignatures.length - 1 ? (
                    <Divider orientation="horizontal" />
                  ) : null}
                </div>
              </ContentContainer>
            ))}
            {witnessSignatures.length < 2 ? (
              <ContentContainer>
                <LinkButton
                  variant="primary"
                  onClick={() => {
                    setWitnessSignatures((prev) => [
                      ...prev,
                      React.createRef<WitnessFormRef>(),
                    ])
                  }}
                >
                  <Icons name="IconPlus" fill="transparent" size={24} />
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    Adicionar nova testemunha
                  </Typography>
                </LinkButton>
              </ContentContainer>
            ) : null}
          </>
        </SignatureAccordion>
        <SignatureAccordion
          title="Empresa"
          description="Preencha as informações abaixo conforme os dados do destinatário."
        >
          <>
            <div>
              <SelectField
                name="companyToSign"
                label="Assinar como"
                fullWidth
                disabled
                value="company"
                options={[
                  {
                    label: "Empresa",
                    value: "company",
                  },
                ]}
              />
            </div>
            <div>
              <TextField
                id="company"
                name="company"
                label="Nome da empresa"
                fullWidth
                disabled
                defaultValue={card?.companyName || "Não cadastrado"}
              />
            </div>
            <div>
              <SearchEmployeeField
                value={employeeFormik.values?.name}
                onBlur={employeeFormik.handleBlur}
                hasError={
                  employeeFormik.touched.name &&
                  Boolean(employeeFormik.errors.name)
                }
                errorText={
                  employeeFormik.touched.name && employeeFormik.errors.name
                    ? employeeFormik.errors.name
                    : ""
                }
                callback={(data) => {
                  employeeFormik.setValues({
                    ...employeeFormik.values,
                    name: data.name,
                    id: data.id,
                    email: data.email || "",
                  })
                }}
              />
            </div>
          </>
        </SignatureAccordion>
      </ContentContainer>
      <Divider orientation="horizontal" />
      <FormButton
        size="large"
        variant="primary"
        onClick={handleSubmit}
        disabled={isLoadingSendSignRequest || isUpdatingCardLoading}
        loading={isLoadingSendSignRequest || isUpdatingCardLoading}
      >
        Enviar documentos <Icons name="IconArrowRight" />
      </FormButton>
    </SubContainer>
  )
}
