import { FooterBar } from "../../common/Components/FooterBar"

import {
  EmailField,
  EmployeeName,
  StyledAvatar,
  StyledDescription,
  StyledGrid,
  StyledPageContainer,
  StyledTitle,
  TableData,
  TableDataArea,
  Title,
  TransferListArea,
} from "./styles"

import { trpc } from "@api/client"
import { TransferList } from "@flash-tecnologia/hros-web-ui-v2"
import { OptionType } from "@flash-tecnologia/hros-web-ui-v2/dist/components/TransferList/components/Filter/components/Section/types"
import { useQueryClient } from "@tanstack/react-query"
import { useContext, useEffect, useState } from "react"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { REPLACE_LEADER_PAGE_URL } from "src/routes/routesConfig"
import {
  EnumResignationActions,
  globalContext,
  resignationContext,
} from "../../../context"
import dispatchToast from "../../../utils/dispatchToast"

interface SelectPeopleContentProps {
  steps: string[]
  currentStep: number
  unselectableEmployeeIds?: string[]
}

export const SelectPeopleContent = ({
  currentStep,
  unselectableEmployeeIds,
  steps,
}: SelectPeopleContentProps) => {
  const [groups, setGroups] = useState<string[]>([])
  const [rightListData, setRightListData] = useState<any[]>([])
  const [leftListData, setLeftListData] = useState<any[]>([])
  const [searchFilters, setSearchFilters] = useState<OptionType[]>([])

  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { flowId = "" } = useParams()
  const { dispatch } = useContext(resignationContext)

  const { mutate: createManyCard, isLoading: isCreating } =
    trpc.card.createManyResignationCards.useMutation()

  const replaceLeaderPageRouterPath = generatePath(REPLACE_LEADER_PAGE_URL, {
    flowId,
  })

  const { mutate: checkLeader, isLoading: isCheckingLeader } =
    trpc.orgchart.checkLeaderByEmployeeId.useMutation({
      onSuccess: async (data) => {
        if (dispatch && data.totalLeaders > 0) {
          dispatch({
            type: EnumResignationActions.SET_LEADERS,
            payload: {
              employees: data?.leaders,
              totalLeaders: data?.totalLeaders,
              totalEmployees: data.totalEmployees,
              rightListEmployees: rightListData,
            },
          })
          navigate(replaceLeaderPageRouterPath)
        } else {
          await createManyCard(
            {
              employeeIds: rightListData.map((r) => r._id),
              flowId,
            },
            {
              onSuccess: () => {
                dispatchToast({
                  content: "Pessoas incluidas ao fluxo com sucesso!",
                  type: "success",
                })

                queryClient.invalidateQueries()
                navigate(`/flows/resignation`)
              },
            },
          )
        }
      },
    })

  const { companyId } = useContext(globalContext)

  const { data: companyGroups } = trpc.company.getGroups.useQuery(undefined, {
    enabled: !!companyId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onSuccess: (companyGroups) => {
      const newSearchFilters = companyGroups?.map<OptionType>((group) => ({
        key: group.id,
        checked: false,
        label: group.name,
        hidden: false,
      }))
      setSearchFilters(newSearchFilters)
    },
  })

  const {
    data: companyEmployees,
    isInitialLoading: isLoading,
    isRefetching,
  } = trpc.employee.getEmployees.useQuery(
    {
      groupIds: groups,
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!companyId,
    },
  )

  useEffect(() => {
    const leftList = getLeftListFiltered()
    setLeftListData(leftList || [])
  }, [unselectableEmployeeIds, companyEmployees, rightListData])

  const getLeftListFiltered = () => {
    const employeesRow = companyEmployees?.map((row) => ({
      ...row,
      _id: row.id,
    }))
    const filteredEmployees = employeesRow?.filter(
      (e) => !unselectableEmployeeIds?.some((a) => a === e?.id),
    )
    const employeesThatIsntOnRightList = filteredEmployees?.filter(
      ({ id }) => !rightListData?.some(({ id: idRight }) => idRight === id),
    )
    return employeesThatIsntOnRightList
  }

  const getListSelectedCount = (list?: any[]) => {
    return list?.filter(({ checked }) => checked === true)?.length || 0
  }

  const columns = [
    {
      Header: "Nome",
      accessor: "name",
      Cell: (cell: any) => {
        const nameInitial: string = cell.row.original?.name
          ? cell.row.original?.name?.charAt(0)
          : ""

        return (
          <TableData>
            <StyledAvatar>{nameInitial}</StyledAvatar>
            <TableDataArea>
              <EmployeeName variant="body3">
                {cell.row.original?.name}
              </EmployeeName>
              {cell.row.original?.contacts?.length ? (
                <EmailField variant="body3">
                  {cell.row.original?.contacts?.find(
                    ({ type }) => type === "email",
                  )?.value ?? null}
                </EmailField>
              ) : null}
            </TableDataArea>
          </TableData>
        )
      },
    },
  ]

  const sections = [
    {
      id: "Grupos",
      title: `Grupos (${groups?.length ?? 0}/${companyGroups?.length ?? 0})`,
      options: searchFilters,
    },
  ]

  const handleFiltersSearch = ({ text }: { text: string }) => {
    const cleanValue = text.replace(/[-[/\]{}()*+?.,\\^$|#]/g, "")
    const regex = new RegExp(cleanValue, "gi")

    const newSearchFilters =
      companyGroups?.reduce<OptionType[]>((acc, group) => {
        if (group.name?.match(regex)) {
          acc.push({
            key: group.id,
            checked: false,
            label: group.name,
            hidden: false,
          })
        }
        return acc
      }, []) ?? []

    setSearchFilters(newSearchFilters)
  }

  return (
    <div
      style={{
        overflow: "hidden hidden",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <StyledPageContainer>
        <div style={{ marginTop: "40px" }}>
          <Title variant="headline6">Selecionar pessoa</Title>
        </div>

        <StyledGrid container>
          <StyledGrid item sm={12} md={5} lg={4}>
            <div style={{ marginRight: "24px", marginTop: "40px" }}>
              <StyledTitle variant="headline7">
                Selecionar para desligamento
              </StyledTitle>
              <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                <StyledDescription variant="body3">
                  Selecione pessoas e inclua-as no fluxo de desligamento. Não se
                  preocupe, elas não serão notificadas sobre isso por enquanto.
                </StyledDescription>
              </div>
            </div>
          </StyledGrid>
          <StyledGrid item sm={12} md={7} lg={8}>
            <TransferListArea>
              <TransferList
                columns={columns}
                leftList={{
                  data: leftListData,
                  total: leftListData?.length,
                  loading: isLoading || isRefetching,
                  emptyStageMessage:
                    "Colaboradores da empresa aparecerão aqui.",
                  title: `Todos os colaboradores (${getListSelectedCount(
                    leftListData,
                  )}/${leftListData?.length || 0})`,
                  onSearch: (result) => {
                    const cleanValue = result.replace(
                      /[-[/\]{}()*+?.,\\^$|#]/g,
                      "",
                    )
                    const regex = new RegExp(cleanValue, "gi")

                    const originalLeftList = getLeftListFiltered()
                    const filteredOriginalLeftList = originalLeftList?.filter(
                      ({ name }) => name?.match(regex),
                    )
                    if (filteredOriginalLeftList !== leftListData)
                      setLeftListData(filteredOriginalLeftList || [])
                  },
                  onCheck: ({ allChecked, data }) => {
                    setLeftListData(() =>
                      data?.map((row) => ({
                        ...row,
                        checked:
                          allChecked !== undefined ? allChecked : row?.checked,
                      })),
                    )
                  },
                  customFilters: {
                    title: "Filtros",
                    sections: sections,
                    onCheck: ({ options }) => setSearchFilters(options),
                    onClear: () =>
                      setSearchFilters((prev) =>
                        prev.map((group) => {
                          return {
                            ...group,
                            checked: false,
                          }
                        }),
                      ),
                    onFilter: (result) => {
                      const filteredGroups = result[0]?.options?.filter(
                        ({ checked }) => checked,
                      )
                      setGroups(filteredGroups.map((section) => section.key))
                    },
                    onSearch: handleFiltersSearch,
                  },
                }}
                rightList={{
                  data: rightListData,
                  total: rightListData?.length,
                  loading: isLoading || isRefetching,
                  emptyStageMessage:
                    "Colaboradores movidos para desligamento aparecerão aqui.",
                  title: `Selecionados para desligamento (${getListSelectedCount(
                    rightListData,
                  )}/${rightListData?.length ?? 0})`,
                  onSearch: (result) => {
                    const cleanValue = result.replace(
                      /[-[/\]{}()*+?.,\\^$|#]/g,
                      "",
                    )
                    const regex = new RegExp(cleanValue, "gi")

                    setRightListData(() =>
                      rightListData?.filter(({ name }) => name?.match(regex)),
                    )
                  },
                  onCheck: ({ allChecked, data }) => {
                    setRightListData(() =>
                      data?.map((row) => ({
                        ...row,
                        checked:
                          allChecked !== undefined ? allChecked : row?.checked,
                      })),
                    )
                  },
                }}
                onTransfer={({ leftList, rightList }) => {
                  setLeftListData(leftList?.data)
                  setRightListData(rightList?.data)
                }}
              />
            </TransferListArea>
          </StyledGrid>
        </StyledGrid>
      </StyledPageContainer>
      <FooterBar
        isLoading={isCreating || isCheckingLeader}
        previousHandleClick={() => {
          setGroups([])
          setRightListData([])
          setLeftListData([])
          setSearchFilters([])
          navigate(`/flows/resignation`)
        }}
        cancelHandleClick={() => {
          setGroups([])
          setRightListData([])
          setLeftListData([])
          setSearchFilters([])
          navigate(`/flows/resignation`)
        }}
        nextHandleClick={async () => {
          if (!rightListData.length) {
            dispatchToast({
              content:
                "Por favor, selecione pelo menos 1 pessoa para desligamento",
              type: "error",
            })

            return
          }

          checkLeader({ employeeIds: rightListData.map((r) => r._id) })
        }}
        steps={steps}
        currentStep={currentStep}
      />
    </div>
  )
}
