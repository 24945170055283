import { trpc } from "@api/client"
import { GenericModal } from "@components/common"
import { LinkButtonFitContent } from "@components/common/atoms/LinkButtonFitContent"
import {
  ReportPayrollProviderEnum,
  ReportTypeEnum,
} from "@customTypes/payroll-integration-report"
import {
  DateRangeField,
  dayjs,
  NotificationCard,
  SelectField,
  ShapeIcon,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  getAuthenticatedUser,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility"
import dispatchToast from "@utils/dispatchToast"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import {
  StyledContainer,
  StyledDateRangeHelperText,
  StyledFinishedContainer,
} from "./styles"

type ExportPayrollIntegrationReportModalProps = {
  handleClose: () => void
  columnId: string
}

export const ExportPayrollIntegrationReportModal = ({
  handleClose,
  columnId,
}: ExportPayrollIntegrationReportModalProps) => {
  const theme = useTheme()
  const { selectedCompany } = useSelectedCompany()
  const [selectedPayrollProvider, setSelectedPayrollProvider] = useState<
    ReportPayrollProviderEnum | undefined
  >()
  const [dateRange, setDateRange] = useState<{
    from: Date | undefined
    to: Date | undefined
  }>({
    from: undefined,
    to: undefined,
  })
  const [userEmail, setUserEmail] = useState<string | undefined>()
  const [isGetAuthenticatedUserLoading, setIsGetAuthenticatedUserLoading] =
    useState(false)
  const [isExportFinished, setIsExportFinished] = useState(false)

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  useEffect(() => {
    setIsGetAuthenticatedUserLoading(true)
    getAuthenticatedUser()
      .then((authUser) => {
        setUserEmail(authUser?.email)
      })
      .finally(() => setIsGetAuthenticatedUserLoading(false))
  }, [])

  const { mutate: createReport, isLoading: isCreateReportLoading } =
    trpc.payrollIntegrationReport.createReport.useMutation({
      onSuccess: () => {
        setIsExportFinished(true)
      },
      onError: () => {
        dispatchToast({
          content: `${t("home.modalExportIntegrationData.toastMessages.createReportError")}`,
          type: "error",
        })
      },
    })

  const handleCreateReport = () => {
    const errors = {
      payrollProvider: !selectedPayrollProvider,
      dateRange: !dateRange.from || !dateRange.to,
    }

    if (Object.values(errors).some((v) => !!v)) {
      dispatchToast({
        content: `${t("home.modalExportIntegrationData.toastMessages.requiredFieldsError")}`,
        type: "error",
      })
      return
    }

    if (!userEmail) {
      dispatchToast({
        content: `${t("home.modalExportIntegrationData.toastMessages.noEmailError")}`,
        type: "warning",
      })
      handleClose()
      return
    }

    createReport({
      companyId: selectedCompany.id,
      email: userEmail,
      payrollProvider: selectedPayrollProvider as ReportPayrollProviderEnum,
      reportType: ReportTypeEnum.HIRING,
      hiringFilter: {
        columnId,
        startDate: dayjs(dateRange.from as Date)
          .startOf("day")
          .toISOString(),
        endDate: dayjs(dateRange.to as Date)
          .endOf("day")
          .toISOString(),
      },
    })
  }

  return (
    <GenericModal
      isOpen
      handleClose={handleClose}
      size={isExportFinished ? "xs" : "sm"}
      centerActionButton={isExportFinished}
      title={
        isExportFinished
          ? undefined
          : t("home.modalExportIntegrationData.title")
      }
      description={
        isExportFinished
          ? undefined
          : t("home.modalExportIntegrationData.description")
      }
      actionButtons={
        isExportFinished
          ? [
              {
                variant: "primary",
                description: t("home.modalExportIntegrationData.button.finish"),
                handleAction: handleClose,
              },
            ]
          : [
              {
                variant: "link",
                description: t("home.modalExportIntegrationData.button.cancel"),
                handleAction: handleClose,
              },
              {
                variant: "primary",
                description: t("home.modalExportIntegrationData.button.submit"),
                icon: "IconCheck",
                handleAction: handleCreateReport,
                disabled: isGetAuthenticatedUserLoading,
                isLoading: isCreateReportLoading,
              },
            ]
      }
    >
      {isExportFinished ? (
        <>
          <StyledFinishedContainer>
            <ShapeIcon
              variant="default"
              name="IconCheck"
              size={48}
              color={theme.colors.primary}
            />
            <Typography
              variant="body4"
              weight={700}
              variantColor={theme.colors.secondary[50]}
            >
              {t("home.modalExportIntegrationData.finishedStep.iconSubText")}
            </Typography>
            <Typography
              variant="headline8"
              weight={700}
              variantColor={theme.colors.neutral[10]}
            >
              {t("home.modalExportIntegrationData.finishedStep.title")}
            </Typography>
            <Typography
              variant="body4"
              weight={400}
              variantColor={theme.colors.neutral[40]}
            >
              {t(
                "home.modalExportIntegrationData.finishedStep.downloadInstructions",
              )}
            </Typography>
            <Typography
              variant="body4"
              weight={700}
              variantColor={theme.colors.neutral[30]}
            >
              {userEmail}
            </Typography>
          </StyledFinishedContainer>
        </>
      ) : (
        <>
          <StyledContainer>
            <NotificationCard.Root variant="outlined" type="info">
              <>
                <NotificationCard.Icon iconName="IconInfoCircle" />
                <NotificationCard.WrapperTexts>
                  <NotificationCard.Title>
                    {t("home.modalExportIntegrationData.extraInfo.title")}
                  </NotificationCard.Title>
                  <NotificationCard.Subtitle>
                    {t("home.modalExportIntegrationData.extraInfo.subTitle")}
                  </NotificationCard.Subtitle>
                  <LinkButtonFitContent
                    color={theme.colors.feedback.info[40]}
                    variant="secondary"
                    onClick={() =>
                      window.open(
                        "https://beneficios.flashapp.com.br/faq/n%C3%A3o-estou-encontrando-templates-de-exporta%C3%A7%C3%A3o-da-folha-que-utilizo-na-minha-empresa-o-que-eu-fa%C3%A7o",
                        "_blank",
                      )
                    }
                  >
                    {t("home.modalExportIntegrationData.extraInfo.buttonText")}
                  </LinkButtonFitContent>
                </NotificationCard.WrapperTexts>
              </>
            </NotificationCard.Root>
            <SelectField
              label={t(
                "home.modalExportIntegrationData.fields.payrollProviderSelect",
              )}
              value={selectedPayrollProvider}
              onSelectChange={(_, { value }) =>
                setSelectedPayrollProvider(value as ReportPayrollProviderEnum)
              }
              options={[
                {
                  label: "RM TOTVS",
                  value: ReportPayrollProviderEnum.TOTVS_RM,
                },
                { label: "LG", value: ReportPayrollProviderEnum.LG },
                { label: "Questor", value: ReportPayrollProviderEnum.QUESTOR },
              ]}
            />
            <DateRangeField
              label={t(
                "home.modalExportIntegrationData.fields.datePickerFilter",
              )}
              fullWidth
              onChange={(range) =>
                setDateRange({
                  from: range?.from,
                  to: range?.to,
                })
              }
            />
            <StyledDateRangeHelperText
              variant="caption"
              variantColor={theme.colors.neutral[70]}
              weight={700}
            >
              {t(
                "home.modalExportIntegrationData.fields.datePickerFilterHelperText",
              )}
            </StyledDateRangeHelperText>
          </StyledContainer>
        </>
      )}
    </GenericModal>
  )
}
