import {
  ActionWrapper,
  EmptyFileContainer,
  FieldContainer,
  FileContainer,
  PreviewWrapper,
  ShareLinkButton,
  ShareText,
  ThumbnailPreview,
  Value,
} from "./styles"
import { downloadArchive, removeDropzoneTimestamp } from "../../../../utils"
import {
  IconButton,
  Icons,
  Typography,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2"
import { Component, FieldValue } from "@customTypes/documents"
import dispatchToast from "../../../../utils/dispatchToast"

function getSelectValue(field: FieldValue) {
  if (!field.options || field.options?.length === 0) {
    return field.value
  }
  // options is either string[] or {label,value}[]
  const selectedOption = field.options.find((option) => {
    if (typeof option === "string") return option === field.value
    return option.value === field.value
  })
  if (typeof selectedOption === "string") return selectedOption
  return selectedOption?.label
}

const renderFileField = (field: FieldValue) => {
  const { value = "", key = "" } = field.value || {}

  const lastIndexName = key ? key.lastIndexOf("/") : ""
  const nameOfArchive =
    key && lastIndexName
      ? removeDropzoneTimestamp(key.substring(lastIndexName + 1))
      : "Nome não identificado"

  return (
    <div>
      <FileContainer hasFile={!!value}>
        <PreviewWrapper>
          <ThumbnailPreview url={value} />
          <span>{nameOfArchive || field.placeholder}</span>
        </PreviewWrapper>

        <ActionWrapper>
          {value ? (
            <IconButton
              size="medium"
              variant="line"
              icon={"IconDownload"}
              onClick={async () => {
                if (!value) return
                await downloadArchive(value, nameOfArchive)
              }}
            />
          ) : (
            <EmptyFileContainer>
              <Icons name="IconX" fill="transparent" color="#C96C01" />
            </EmptyFileContainer>
          )}
        </ActionWrapper>
      </FileContainer>

      {!!value && (
        <ShareLinkButton
          variant="primary"
          onClick={async () => {
            navigator.clipboard.writeText(value)
            dispatchToast({
              content: "Link copiado",
              type: "success",
            })
          }}
        >
          <>
            <ShareText variant="caption" style={{ fontWeight: 700 }}>
              Compartilhar link
            </ShareText>

            <Icons
              name="IconLink"
              size={16}
              style={{
                fill: "transparent",
                color: "#6B5B66",
              }}
            />
          </>
        </ShareLinkButton>
      )}
    </div>
  )
}

const renderOtherField = (field: FieldValue) => {
  function parseValue() {
    switch (field.component) {
      case Component.DatePicker:
        return field.value ? dayjs(field.value).format("DD/MM/YYYY") : null
      case Component.Checkbox:
        return field.value ? "Marcado" : "Não marcado"
      case Component.Select:
        return getSelectValue(field)
      case Component.MultiSelect:
        return field.value
          ? field.value.map((value) => <span>{value.value}</span>)
          : null
      default:
        return field.value || null
    }
  }

  const value = parseValue()

  return (
    <FieldContainer>
      <Typography variant="caption">{field.placeholder}</Typography>
      <Value hasValue={!!value} variant="body4">
        {value || "Não preenchido"}
      </Value>
    </FieldContainer>
  )
}

export const RenderField = ({ field }: { field: FieldValue }) => {
  const isFile = field.component === "attachment" || field.fieldType === "file"
  return isFile ? renderFileField(field) : renderOtherField(field)
}
