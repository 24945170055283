import { trpc } from "@api/client"
import { HiringCard } from "@customTypes/card"
import {
  Component,
  ConfirmationData,
  ConfirmationDependent,
  FieldType,
  FieldValue,
} from "@customTypes/documents"
import {
  Flow,
  FlowCategory,
  FlowSubcategory,
  WorkerTypeCode,
} from "@customTypes/index"
import { Button, Loader, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useQueryClient } from "@tanstack/react-query"
import React, { useMemo, useState } from "react"
import {
  ConfirmationModal,
  ValidationAccordion,
  WizardCardLink,
} from "../../../components"
import dispatchToast from "../../../utils/dispatchToast"
import { EmptyState } from "./EmptyState"
import { RenderField } from "./RenderField"
import { RenderSkeleton } from "./Skeleton"
import { AccordionWrapper, Container, FieldWrapper } from "./styles"
import {
  PayrollIntegration,
  PayrollIntegrationFormRef,
  PayrollIntegrationFormType,
} from "./PayrollIntegration"
import { globalContext } from "@context/GlobalContext"
import { checkUnleashToggles, useUnleashToggles } from "src/hooks"
import { UnleashFlags } from "@utils/unleash"
import { Box } from "@components/common/atoms/Box"
import { useTranslation } from "react-i18next"
import { useTracking } from "@utils/useTracking"

const isAllDocumentsValidated = (data: ConfirmationData) => {
  const isAllCandidateDocValidated = data.documentFields.every(
    (doc) => doc.validation?.status !== "waiting",
  )

  let isAllDependentsDocValidated = true
  if (data.dependents && data.dependents.length > 0) {
    isAllDependentsDocValidated = data.dependents.every((dep) =>
      dep.documents.every((doc) => doc.validation?.status !== "waiting"),
    )
  }

  return isAllCandidateDocValidated && isAllDependentsDocValidated
}

const isAllDocumentsApproved = (data: ConfirmationData) => {
  if (!data) return false
  const hasAnyDeclinedDocument = data.documentFields.some(
    (doc) => doc.validation?.status === "declined",
  )

  const filteredRequiredDocumentFields = data.documentFields.filter((doc) =>
    doc.fields?.every((f) => f.fieldRule?.required),
  )

  const isAllCandidateDocApproved = filteredRequiredDocumentFields.every(
    (doc) => doc.validation?.status === "approved",
  )

  const hasAnyDependentDeclinedDocument = data.dependents?.some((dep) => {
    return dep.documents.some((doc) => doc.validation?.status === "declined")
  })

  const isAllDependentsDocApproved = data.dependents?.every((dep) => {
    const filteredRequiredDocumentFields = dep.documents.filter((doc) =>
      doc.fields?.every((f) => f.fieldRule?.required),
    )
    return filteredRequiredDocumentFields.every(
      (doc) => doc.validation?.status === "approved",
    )
  })

  return (
    isAllCandidateDocApproved &&
    isAllDependentsDocApproved &&
    !hasAnyDeclinedDocument &&
    !hasAnyDependentDeclinedDocument
  )
}

const GenericContent = ({ card, flow }: { card: HiringCard; flow: Flow }) => {
  const [confirmationData, setConfirmationData] = useState<ConfirmationData>()
  const [confirmationSendModal, setConfirmationSendModal] =
    useState<boolean>(false)

  const [t] = useTranslation("translations", {
    keyPrefix: "page.documentsValidation",
  })

  const queryClient = useQueryClient()
  const tracking = useTracking()
  const { companyId } = React.useContext(globalContext)
  const unleashToggles = useUnleashToggles({ companyId: companyId ?? "" })
  const hasPayrollFlag = checkUnleashToggles(
    unleashToggles,
    UnleashFlags.PAYROLL_INTEGRATION,
  )
  const isAllApproved = isAllDocumentsApproved(confirmationData as any)

  const shouldShowPayrollIntegration =
    isAllApproved && hasPayrollFlag && flow.subcategory !== "pj"

  const payrollIntegrationFormRef =
    React.useRef<PayrollIntegrationFormRef>(null)

  const { isInitialLoading: isLoading, isRefetching } =
    trpc.card.getConfirmationData.useQuery(
      {
        candidateId: card.candidateId,
        formConfigId: card.formConfigId,
        extensionId: card.formExtensionId,
      },
      {
        enabled: !!(card.formConfigId && card.candidateId),
        refetchOnWindowFocus: false,
        retry: false,
        onSuccess: (data) => setConfirmationData(data as unknown as any),
      },
    )

  const { mutateAsync: validateCandidateDocument } =
    trpc.candidate.validateDocument.useMutation({
      onMutate: async ({ formGroupId, status, statusComment }) => {
        const oldConfirmationData = { ...confirmationData }

        setConfirmationData((oldData: any) => {
          return {
            ...oldData,
            documentFields: oldData.documentFields.map((doc) => {
              if (doc._id !== formGroupId) return doc

              return {
                ...doc,
                validation: {
                  formGroupId: formGroupId || doc.validation?.formGroupId,
                  status: status || doc.validation?.status,
                  statusComment: statusComment || undefined,
                },
              }
            }),
          }
        })

        return { oldConfirmationData }
      },
      onError: (_error, _var, context) => {
        setConfirmationData(context?.oldConfirmationData as any)
      },
    })

  const { mutateAsync: validateDependentDocument } =
    trpc.candidate.validateDependentDocument.useMutation({
      onMutate: async ({ dependentId, formGroupId, status, statusComment }) => {
        const oldConfirmationData = { ...confirmationData }

        setConfirmationData((oldData: any) => {
          return {
            ...oldData,
            dependents: oldData.dependents.map((dependent) => {
              if (dependent._id !== dependentId) return dependent

              return {
                ...dependent,
                documents: dependent.documents.map((doc) => {
                  if (doc._id !== formGroupId) return doc

                  return {
                    ...doc,
                    validation: {
                      formGroupId,
                      status,
                      statusComment,
                    },
                  }
                }),
              }
            }),
          }
        })

        return { oldConfirmationData }
      },
      onError: (_error, _var, context) => {
        setConfirmationData(context?.oldConfirmationData as any)
      },
    })

  const { mutateAsync: updateCandidate } = trpc.candidate.update.useMutation()

  const { mutateAsync: resendDocumentsRequest, isLoading: isSubmitLoading } =
    trpc.hiring.resendDocumentsRequest.useMutation()

  const findEmailLink = useMemo(
    () =>
      card.emailLink?.find(
        (e) =>
          e.columnId === card.columnId && e.name === "resendDocumentsRequest",
      ),
    [card.emailLink],
  )

  const renderCandidateBasicData = () => {
    return (
      <ValidationAccordion
        title={"Dados básicos"}
        description={"Preencha as informações abaixo com seus dados pessoais."}
        required={true}
      >
        <FieldWrapper>
          {confirmationData?.candidateFields.map((field, idx) => (
            <RenderField key={idx} field={field} />
          ))}
        </FieldWrapper>
      </ValidationAccordion>
    )
  }

  const renderCandidateDocuments = () => {
    return confirmationData?.documentFields?.map((doc, idx) => {
      const status = doc.validation ? doc.validation.status : "notTouched"
      const isRequired = doc.fields.every(
        (field) => field.fieldRule?.required || false,
      )

      const handleValidate = async (
        status: "approved" | "declined",
        statusComment: string,
      ) => {
        validateCandidateDocument({
          candidateId: card.candidateId,
          formGroupId: doc._id,
          status,
          statusComment,
        }).catch(() => {
          dispatchToast({
            type: "error",
            content: "Houve um problema ao atualizar o documento",
          })
        })
      }

      return (
        <ValidationAccordion
          key={idx}
          title={doc.title}
          description={doc.description}
          required={isRequired}
          status={status}
          onAccept={() => handleValidate("approved", "")}
          onRefuse={(refusedReason: string) =>
            handleValidate("declined", refusedReason)
          }
        >
          <FieldWrapper>
            {doc.fields.map((field, idx) => (
              <RenderField key={idx} field={field} />
            ))}
          </FieldWrapper>
        </ValidationAccordion>
      )
    })
  }

  const renderDependentMandatoryField = (dependent: ConfirmationDependent) => {
    const mandatoryFields: FieldValue[] = [
      {
        candidateId: dependent.candidateId,
        component: Component.ShortText,
        enabled: true,
        fieldId: "kinship",
        customFieldId: "kinship",
        fieldType: FieldType.Text,
        value: dependent.kinship,
        placeholder: "Grau de parentesco",
        formGroupId: "basic-data",
        type: "base",
      },
      {
        candidateId: dependent.candidateId,
        component: Component.ShortText,
        enabled: true,
        fieldId: "name",
        customFieldId: "name",
        fieldType: FieldType.Text,
        value: dependent.name,
        placeholder: "Nome do dependente",
        formGroupId: "basic-data",
        type: "base",
      },
      {
        candidateId: dependent.candidateId,
        component: Component.Select,
        enabled: true,
        fieldId: "maritalStatus",
        customFieldId: "maritalStatus",
        fieldType: FieldType.Text,
        value: dependent.maritalStatus,
        options: [
          {
            label: "Solteiro",
            value: "single",
          },
          {
            label: "Casado",
            value: "married",
          },
          {
            label: "União estável",
            value: "domestic partnership",
          },
          {
            label: "Divorciado",
            value: "divorced",
          },
          {
            label: "Separado",
            value: "separated",
          },
          {
            label: "Viúvo",
            value: "widowed",
          },
        ],
        placeholder: "Estado Civil",
        formGroupId: "basic-data",
        type: "base",
      },
      {
        candidateId: dependent.candidateId,
        component: Component.DatePicker,
        enabled: true,
        fieldId: "birthDate",
        customFieldId: "birthDate",
        fieldType: FieldType.Text,
        value: dependent.birthDate,
        placeholder: "Data de nascimento",
        formGroupId: "basic-data",
        type: "base",
      },
    ]

    return (
      <ValidationAccordion title={"Dados básicos"} required={true}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 24,
          }}
        >
          {mandatoryFields.map((field, idx) => (
            <RenderField key={idx} field={field} />
          ))}
        </div>
      </ValidationAccordion>
    )
  }

  const renderDependents = () => {
    return confirmationData?.dependents?.map((dependent, idx) => {
      return (
        <ValidationAccordion
          key={idx}
          title={dependent.name}
          description={
            "Preencha as informações abaixo de acordo com os dados do dependente."
          }
          required={false}
        >
          <FieldWrapper>
            {renderDependentMandatoryField(dependent)}

            {dependent.documents?.map((doc, idx) => {
              const status = doc.validation
                ? doc.validation.status
                : "notTouched"
              const isRequired = doc.fields.every(
                (field) => field.fieldRule?.required || false,
              )

              const handleValidate = async (
                status: "approved" | "declined",
                statusComment: string,
              ) => {
                validateDependentDocument({
                  dependentId: dependent._id,
                  formGroupId: doc._id,
                  status,
                  statusComment,
                }).catch(() => {
                  dispatchToast({
                    type: "error",
                    content: "Houve um problema ao atualizar o documento",
                  })
                })
              }

              return (
                <ValidationAccordion
                  key={idx}
                  title={doc.title}
                  description={doc.description}
                  required={isRequired}
                  status={status}
                  onAccept={() => handleValidate("approved", "")}
                  onRefuse={(refusedReason: string) =>
                    handleValidate("declined", refusedReason)
                  }
                >
                  <FieldWrapper>
                    {doc.fields.map((field, idx) => (
                      <RenderField key={idx} field={field} />
                    ))}
                  </FieldWrapper>
                </ValidationAccordion>
              )
            })}
          </FieldWrapper>
        </ValidationAccordion>
      )
    })
  }

  const handleSubmit = async () => {
    const eventName = isAllApproved
      ? `company_hiring_${flow.subcategory}_approveddocuments_next_clicked`
      : `company_hiring_${flow.subcategory}_resenddocumentsrequest_clicked`

    tracking.trackEvent({
      name: eventName,
    })

    try {
      if (shouldShowPayrollIntegration) {
        if (!payrollIntegrationFormRef.current)
          throw new Error("Form not defined")

        const errors = await payrollIntegrationFormRef?.current?.validateForm()
        if (Object.keys(errors)?.length > 0) {
          dispatchToast({
            content: t("formMissingFieldError"),
            type: "error",
          })
          return
        }
        const payrollIntegrationForm: PayrollIntegrationFormType =
          await payrollIntegrationFormRef?.current?.submitForm()

        if (
          !payrollIntegrationForm.assignmentTermCode ||
          !payrollIntegrationForm.remunerationBasisCode ||
          !payrollIntegrationForm.workerGroups ||
          !payrollIntegrationForm.standardHours
        )
          throw new Error("Form values not defined")

        const workerTypeCode = payrollIntegrationForm.isYoungApprentice
          ? WorkerTypeCode.YOUNG_APPRENTICE
          : flow.subcategory === FlowSubcategory.CLT
            ? WorkerTypeCode.CLT
            : WorkerTypeCode.INTERN

        await updateCandidate({
          candidateId: card.candidateId,
          fields: {
            workerTypeCode: workerTypeCode,
            assignmentTermCode: payrollIntegrationForm.assignmentTermCode,
            remunerationBasisCode: payrollIntegrationForm.remunerationBasisCode,
            workerGroups: payrollIntegrationForm.workerGroups,
            standardHours: Number(payrollIntegrationForm.standardHours),
          },
        })
      }

      await resendDocumentsRequest({
        flowCardId: card._id,
        columnId: card.columnId,
        category: FlowCategory.HIRING,
        subcategory: flow.subcategory,
        metadata: card.metadata,
        documentStatus: isAllApproved ? "approved" : "disapproved",
        disapprovedReason: "",
        candidateId: card.candidateId,
        version: card.version,
      })

      const message = isAllApproved
        ? "Documentos validados com sucesso!"
        : "Solicitação reenviada com sucesso!"

      dispatchToast({
        content: message,
        type: "success",
      })
      queryClient.invalidateQueries()
    } catch (err) {
      dispatchToast({
        content: "Houve um erro ao finalizar a analise de documentos",
        type: "error",
      })
    }
  }

  if (!card.formConfigId) return <EmptyState card={card} flow={flow} />

  return (
    <Container>
      {findEmailLink && (
        <WizardCardLink multiplesEmailLinks={[{ emailLink: findEmailLink }]} />
      )}

      {!confirmationData || isRefetching || isLoading ? (
        <RenderSkeleton />
      ) : (
        <>
          <div style={{ marginBottom: 40 }}>
            <Typography
              variant="headline8"
              style={{
                color: "#53464F",
              }}
            >
              Documentos da pessoa
            </Typography>

            <Typography
              variant="body4"
              style={{
                color: "#83727D",
              }}
            >
              Revise e valide os documentos solicitados à pessoa.
            </Typography>
          </div>

          <AccordionWrapper>
            {renderCandidateBasicData()}
            {renderCandidateDocuments()}
          </AccordionWrapper>

          {(confirmationData?.dependents || []).length > 0 ? (
            <>
              <div style={{ marginBottom: 40 }}>
                <Typography
                  variant="headline8"
                  style={{
                    color: "#53464F",
                  }}
                >
                  Documentos de dependentes
                </Typography>

                <Typography
                  variant="body4"
                  style={{
                    color: "#83727D",
                  }}
                >
                  Podem ser considerados dependentes cônjuges ou
                  companheiros(as), filhos não emancipados de qualquer condição,
                  menores de vinte e um anos ou pessoas com deficiência.
                </Typography>
              </div>

              <AccordionWrapper>{renderDependents()}</AccordionWrapper>
            </>
          ) : (
            <></>
          )}

          {shouldShowPayrollIntegration && (
            <Box $pb="m">
              <PayrollIntegration
                ref={payrollIntegrationFormRef}
                flow={flow}
                initialValues={{
                  assignmentTermCode: card.candidate.assignmentTermCode,
                  isYoungApprentice:
                    card.candidate.workerTypeCode ===
                      WorkerTypeCode.YOUNG_APPRENTICE || false,
                  remunerationBasisCode: card.candidate.remunerationBasisCode,
                  standardHours: card.candidate.standardHours,
                  workerGroups: card.candidate.workerGroups,
                }}
              />
            </Box>
          )}

          <div
            style={{
              display: "flex",
              flex: "1",
            }}
          >
            <Button
              size="large"
              variant="primary"
              disabled={
                isSubmitLoading || !isAllDocumentsValidated(confirmationData)
              }
              onClick={() => setConfirmationSendModal(true)}
              style={{ width: "100%" }}
            >
              {isSubmitLoading ? (
                <Loader size="extraSmall" variant="secondary" />
              ) : (
                <span>Finalizar validação</span>
              )}
            </Button>

            <ConfirmationModal
              variant="primary"
              isOpen={confirmationSendModal}
              onClose={() => setConfirmationSendModal(false)}
              title="Tem certeza que deseja finalizar a validação?"
              description="Verifique se todas as informações foram preenchidas corretamente."
              confirmButton={{
                onClick: () => handleSubmit(),
                isLoading: isSubmitLoading,
                text: "Prosseguir e finalizar",
                icon: "IconArrowRight",
              }}
            />
          </div>
        </>
      )}
    </Container>
  )
}

export default GenericContent
