import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { DeprecatedUpload, UploadPreview } from "../../../components"
import { uploadHelper } from "@flash-tecnologia/hros-web-utility"
import {
  ContentContainer,
  SubContainer,
  StyledIconContainer,
  StyledPreviewProgress,
  Divider,
  StyledPOneLine,
} from "./styles"

import {
  Typography,
  Icons,
  TextField,
  LinkButton,
  dayjs,
  DatePicker,
} from "@flash-tecnologia/hros-web-ui-v2"
import dispatchToast from "../../../utils/dispatchToast"
import { useFormik } from "formik"
import { HiringCard } from "../../../types"
import { trpc } from "@api/client"
import { mergeAdmissionalExams } from "@utils/index"

const InternshipContent = ({ card }: { card: HiringCard }) => {
  const [localCard, setLocalCard] = useState<HiringCard>(card)
  const [hasLinkedinEdit, setHasLinkedinEdit] = useState(false)
  const [hasVacancyEdit, setHasVacancyEdit] = useState(false)
  const [loadingFile, setLoadingFile] = useState<any>(false)

  const [s3FilePath, setS3FilePath] = useState<string>()
  const [cvData, setCVData] = useState<{ key: string; path: string }>()

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const { mutateAsync: updateCard } = trpc.card.updateHiringCard.useMutation()

  const { mutateAsync: updateCandidate } = trpc.candidate.update.useMutation()

  const { data: s3File } = trpc.helpers.getS3File.useQuery(
    { path: s3FilePath as string, module: "hiring" },
    { enabled: !!s3FilePath },
  )

  useEffect(() => {
    if (cvData && s3File) {
      updateCard(
        {
          metadata: {
            ...localCard.metadata,
            candidate: {
              ...localCard.metadata?.candidate,
              cv: { key: cvData.key, path: cvData.path, type: "internal" },
            },
          },
          flowCardId: localCard._id,
        },
        {
          onSuccess: (_, ctx) => {
            setLocalCard((prevState: any) => ({
              ...prevState,
              metadata: {
                ...prevState.metadata,
                candidate: {
                  ...prevState.metadata?.candidate,
                  cv: {
                    ...ctx.metadata?.candidate.cv,
                    value: s3File.signedUrl,
                  },
                },
              },
            }))
            setLoadingFile(false)
          },
        },
      )
    }
  }, [s3File])

  const updateFieldGlobally = useCallback(
    async ({ values }) => {
      await updateCandidate(
        {
          candidateId: localCard.candidateId,
          fields: values,
        },
        {
          onError: (err: any) => {
            if (err instanceof Error) {
              console.log(err.stack)
            }
          },
        },
      )
    },
    [localCard.candidateId],
  )

  const formik = useFormik({
    initialValues: {
      institutionName: localCard.candidate.internship?.institutionName || "",
      courseName: localCard.candidate.internship?.courseName || "",
      endDate: localCard.candidate.internship?.endDate || "",
    },
    onSubmit: async (values) => {},
  })

  const handleFile = async ({
    name,
    file,
  }: {
    name: any
    file: any
  }): Promise<void> => {
    setLoadingFile(true)

    const url = `${
      localCard.companyId + "/" + localCard.candidateId + "/" + localCard._id
    }`
    if (!localCard.companyId || !localCard.candidateId || !localCard._id) return

    const fileName = `${Date.now().toString()}-Curriculo_${card.candidate.name.replace(" ", "_")}`
    const { key, path } = await uploadHelper({
      key: url,
      file,
      fileName,
      module: "hiring",
    })
    setCVData({ key, path })
    setS3FilePath(path)
  }

  const renderPreviewUploadComponent = () => {
    if (!localCard.metadata?.candidate?.cv) return <></>
    return (
      <UploadPreview
        file={localCard.metadata?.candidate?.cv}
        options={{
          canDownload: true,
          canRemove: true,
          handleRemove: async () => {
            const updatedCard = await updateCard({
              metadata: {
                ...localCard.metadata,
                candidate: { ...localCard.metadata?.candidate, cv: {} },
              },
              flowCardId: localCard._id,
            })
            setLocalCard((prevState) => ({
              ...prevState,
              ...updatedCard,
              admissionalExam: mergeAdmissionalExams(
                prevState.admissionalExam,
                updatedCard.admissionalExam,
              ),
            }))
          },
        }}
      />
    )
  }

  const renderPreviewLoading = () => {
    return (
      <div
        style={{
          pointerEvents: "none",
          userSelect: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "32px 40px",
            border: "1px solid #EBE6E9",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginRight: "7px",
            }}
          >
            <div
              style={{
                marginRight: "7px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icons name={"IconFile"} style={{ fill: "transparent" }} />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "#3B2E37",
                }}
              >
                <StyledPOneLine numberOfLines={1}>
                  Carregando o arquivo, por favor, aguarde!
                </StyledPOneLine>
              </span>
              <StyledPreviewProgress />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderPreviewComponent = ({ label, title, url }) => {
    return (
      <div>
        <div
          style={{
            marginBottom: "16px",
          }}
        >
          <span
            style={{
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "22px",
              color: "#83727D",
            }}
          >
            {label}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "32px 40px",
            border: "1px solid #EBE6E9",
            borderRadius: "10px",
          }}
        >
          <LinkButton
            variant="primary"
            style={{ alignSelf: "center" }}
            onClick={() => {
              window.open(url, "_blank")
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body3">
                <StyledPOneLine numberOfLines={1}>{title}</StyledPOneLine>
              </Typography>
              <Icons
                name="IconExternalLink"
                style={{ width: "16px", height: "16px", marginLeft: "4px" }}
                fill="transparent"
              />
            </div>
          </LinkButton>

          <StyledIconContainer
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              if (label === "Linkedin") setHasLinkedinEdit(true)
              if (label === "Link da vaga") setHasVacancyEdit(true)
            }}
          >
            <Icons
              name="IconEdit"
              fill="transparent"
              style={{ width: "16px", height: "16px" }}
            />
          </StyledIconContainer>
        </div>
      </div>
    )
  }

  return (
    <ContentContainer>
      <SubContainer>
        <Typography
          variant={"headline8"}
          style={{ color: "#53464F", fontWeight: 700 }}
        >
          Instituição de ensino
        </Typography>

        <Typography
          variant={"body4"}
          style={{ color: "#83727D", fontWeight: 400 }}
        >
          Preencha o campo abaixo com o nome da instituição cursada pela pessoa
        </Typography>

        <div style={{ marginBottom: "24px", marginTop: "24px" }}>
          <TextField
            type={"text"}
            label={"Instituição de ensino"}
            placeholder={"Instituição de ensino"}
            name={"institutionName"}
            onChange={formik.handleChange}
            onBlur={async (e) => {
              await updateFieldGlobally({
                values: {
                  internship: {
                    institutionName: e.target.value || null,
                  },
                },
              })
            }}
            value={formik.values.institutionName}
            fullWidth={true}
          />
        </div>

        <div style={{ marginBottom: "24px" }}>
          <TextField
            type={"text"}
            label={"Curso"}
            placeholder={"Curso"}
            name={"courseName"}
            value={formik.values.courseName}
            onChange={formik.handleChange}
            onBlur={async (e) => {
              await updateFieldGlobally({
                values: {
                  internship: {
                    courseName: e.target.value || null,
                  },
                },
              })
            }}
            fullWidth={true}
          />
        </div>

        <div>
          <DatePicker
            id={"hiringEndDate"}
            name={"hiringEndDate"}
            label={"Data prevista de término"}
            placeholder={"Digite a data prevista de término"}
            fromDate={dayjs()}
            value={formik.values.endDate}
            onDateChange={async (value) => {
              const date = value && dayjs(value).isValid() ? dayjs(value) : null
              const parsedValue = dayjs(value).isValid()
                ? date?.toISOString()
                : null

              formik.handleChange({
                target: { id: "endDate", value: parsedValue },
              })

              if (!date) return

              await updateFieldGlobally({
                values: {
                  internship: {
                    endDate: parsedValue,
                  },
                },
              })
            }}
            fullWidth
          />
        </div>

        <Divider style={{ marginTop: "40px", marginBottom: "40px" }} />

        <Typography
          variant={"headline8"}
          style={{ color: "#53464F", fontWeight: 700 }}
        >
          {t("candidate.recruitmentDataTitle")}
        </Typography>

        <Typography
          variant={"body4"}
          style={{ color: "#83727D", fontWeight: 400 }}
        >
          {t("candidate.recruitmentDataDescription")}{" "}
        </Typography>

        <div style={{ marginTop: "24px" }}>
          {localCard.metadata?.candidate?.cv?.path ? (
            renderPreviewUploadComponent()
          ) : (
            <DeprecatedUpload
              label={"CV"}
              onChange={handleFile}
              accept={["pdf"]}
              customPreview={() =>
                loadingFile
                  ? renderPreviewLoading()
                  : renderPreviewUploadComponent()
              }
              maxSize={5242880}
              onFileSizeError={() => {
                dispatchToast({
                  content:
                    "Arquivo maior que 5mb. Por favor, faça upload de arquivo menor que 5mb",
                  type: "warning",
                })
              }}
            />
          )}
        </div>

        <div style={{ marginTop: "24px", marginBottom: "24px" }}>
          {localCard.metadata?.candidate?.linkedin && !hasLinkedinEdit ? (
            renderPreviewComponent({
              label: "Linkedin",
              title: "Visualizar Linkedin",
              url: localCard.metadata?.candidate?.linkedin,
            })
          ) : (
            <TextField
              defaultValue={localCard.metadata?.candidate?.linkedin}
              name={"linkedin"}
              onBlur={async (e) => {
                await updateCard(
                  {
                    metadata: {
                      ...localCard.metadata,
                      candidate: {
                        ...localCard.metadata?.candidate,
                        linkedin: e.target.value,
                      },
                    },
                    flowCardId: localCard._id,
                  },
                  {
                    onSuccess: (_, ctx) => {
                      setLocalCard((prevState: any) => ({
                        ...prevState,
                        ...ctx,
                      }))
                    },
                  },
                )
              }}
              label={"Linkedin"}
              type={"text"}
              placeholder={t("candidate.linkedinPlaceHolder")}
              style={{ width: "100%" }}
            />
          )}
        </div>
        <div>
          {localCard.metadata?.candidate?.vacancyLink && !hasVacancyEdit ? (
            renderPreviewComponent({
              label: "Link da vaga",
              title: "Visualizar link da vaga",
              url: localCard.metadata?.vacancyLink,
            })
          ) : (
            <TextField
              defaultValue={localCard.metadata?.candidate?.vacancyLink || ""}
              name={"vacancyLink"}
              onBlur={async (e) => {
                await updateCard(
                  {
                    metadata: {
                      ...localCard.metadata,
                      candidate: {
                        ...localCard.metadata?.candidate,
                        vacancyLink: e.target.value,
                      },
                    },
                    flowCardId: localCard._id,
                  },
                  {
                    onSuccess: (_, ctx) => {
                      setLocalCard((prevState: any) => ({
                        ...prevState,
                        ...ctx,
                      }))
                    },
                  },
                )
              }}
              label={t("candidate.vacantJobLabel")}
              type={"text"}
              placeholder={t("candidate.vacantJobPlaceholder")}
              style={{ width: "100%" }}
            />
          )}
        </div>
      </SubContainer>
    </ContentContainer>
  )
}

export default InternshipContent
