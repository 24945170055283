import { IconTypes, IconButton } from "@flash-tecnologia/hros-web-ui-v2"
import React from "react"
import { previewContext } from "../Preview"

export type ClickParams = {
  url?: string
  fileName?: string
}

type ActionProps = {
  icon: IconTypes
  onClick: (params: ClickParams) => void
}

export const Action = ({ icon, onClick }: ActionProps) => {
  const { url, fileName } = React.useContext(previewContext)
  const handleClick = () => {
    const params: ClickParams = { url, fileName }
    onClick(params)
  }

  return (
    <IconButton
      icon={icon}
      variant="line"
      size="medium"
      onClick={handleClick}
    />
  )
}
