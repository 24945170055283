import { LinkButtonFitContentProps, StyledLinkButton } from "./styles"

export const LinkButtonFitContent = (props: LinkButtonFitContentProps) => {
  return (
    <StyledLinkButton
      {...props}
      style={{ ...props.style, ...localStyles.linkButton }}
      color={props.color}
    />
  )
}

const localStyles = {
  linkButton: {
    width: "fit-content",
  },
}
