import { useEffect, useState } from "react"
import {
  Button,
  Divider,
  Icons,
  LinkButton,
  Modal,
  MultiSelect,
  ShapeIcon,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import dispatchToast from "../../../../utils/dispatchToast"
import { trpc } from "@api/client"
import { useFormik } from "formik"
import * as yup from "yup"
import i18n from "@i18n"
import { EmailMask, validateEmail } from "@utils/index"
import { Box } from "@components/common/atoms/Box"
import { useTheme } from "styled-components"
import useLocalStorage from "src/hooks/useLocalStorage"
import { HiringCard } from "@customTypes/card"
import { useTracking } from "@utils/useTracking"

enum Steps {
  Form = 0,
  Confirmation = 1,
  Success = 2,
}

type Option = {
  label: string
  value: string
}

type ExportCandidateReportForm = {
  candidates: Option[]
  accountingEmail: string
}

const requiredField = i18n.t(
  "components.exportCandidateReportModal.form.requiredField",
)

const validationSchema = yup.object({
  accountingEmail: yup.string().required(requiredField).email(requiredField),
  candidates: yup.array().required(requiredField).min(1, requiredField),
})

type ModalExportCandidateReportProps = {
  isOpen: boolean
  handleClose: () => void
  candidateId?: string
  flowId?: string
}

export const ModalExportCandidateReport = ({
  isOpen,
  handleClose: handleCloseCallback,
  candidateId,
  flowId,
}: ModalExportCandidateReportProps) => {
  const theme = useTheme()
  const [step, setStep] = useState<Steps>(Steps.Form)
  const [candidateList, setCandidateList] = useState<Option[]>([])

  const [candidateSearchTerm, setCandidateSearchTerm] = useState("")

  const [t] = useTranslation("translations", {
    keyPrefix: "components.exportCandidateReportModal",
  })

  const [accountingEmail, setAccountingEmail] = useLocalStorage<string>(
    "accounting-email",
    "",
  )

  const tracking = useTracking()

  const { mutateAsync: createReport, isLoading: isCreateReportLoading } =
    trpc.hiring.createCandidateReport.useMutation({
      onSuccess: () => {
        setStep(Steps.Success)
      },
      onError: (err) => {
        dispatchToast({
          type: "error",
          content: err.shape?.data.userFriendlyError.message ?? err.message,
        })
      },
    })

  const formik = useFormik<ExportCandidateReportForm>({
    initialValues: {
      accountingEmail,
      candidates: candidateId ? [{ label: "", value: candidateId }] : [],
    },
    validationSchema: validationSchema,
    validate: async (values) => {
      const errors: { [key in keyof ExportCandidateReportForm]?: string } = {}

      if (
        values.accountingEmail.length > 1 &&
        !validateEmail(values.accountingEmail)
      )
        errors.accountingEmail = requiredField

      return errors
    },
    onSubmit: (values) => {
      setAccountingEmail(values.accountingEmail)
      setStep(Steps.Confirmation)
    },
  })

  const { data: flowData, isLoading: isCandidateOptionsLoading } =
    trpc.flow.getFlowById.useQuery(
      { flowId: flowId ?? "" },
      { enabled: !!flowId },
    )

  useEffect(() => {
    if (flowData) {
      const flowCandidates: Option[] = []
      flowData?.columns?.forEach((column: any) => {
        column?.cards?.forEach((card: HiringCard) => {
          flowCandidates.push({ label: card.name, value: card.candidateId })
        })
      })
      setCandidateList(flowCandidates)
    }
  }, [flowData])

  const formHandleChange = <K extends keyof ExportCandidateReportForm>(
    key: K,
    value: ExportCandidateReportForm[K],
  ) => {
    formik.handleChange({ target: { name: key, value } })
  }

  const handleClose = () => {
    formik.resetForm({ values: { candidates: [], accountingEmail } })
    setStep(Steps.Form)
    handleCloseCallback()
  }

  const contents = {
    [Steps.Form]: () => (
      <>
        <Box flexDirection="column" gap="xs2" $px="m" $pb="xs">
          <Box flexDirection="column" gap="xs4">
            <Typography
              variant="headline7"
              variantColor={theme.colors.neutral[10]}
            >
              {t("title")}
            </Typography>
            <Typography variant="body4" variantColor={theme.colors.neutral[40]}>
              {t("description")}
            </Typography>
          </Box>
          {!candidateId && (
            <MultiSelect
              renderInput={() => null}
              value={formik.values.candidates}
              onChange={(e) => {
                setCandidateSearchTerm(e.target.value)
              }}
              onSelectChange={(_, options: Option[]) => {
                formHandleChange("candidates", options)
                setCandidateSearchTerm("")
              }}
              error={formik.touched.candidates && !!formik.errors.candidates}
              helperText={
                formik.touched.candidates &&
                formik.errors.candidates?.toString()
              }
              noOptionsText={
                candidateSearchTerm.length > 2
                  ? t("form.candidatesNoOptionsText")
                  : isCandidateOptionsLoading
                    ? t("form.candidatesLoading")
                    : t("form.candidatesInvalidSearchText")
              }
              loadingText={t("form.candidatesLoading")}
              label={t("form.candidatesPlaceholder")}
              fullWidth
              limitTags={2}
              options={candidateList.filter(
                (option) => !formik.values.candidates.includes(option),
              )}
            />
          )}
          <div>
            <TextField
              label={t("form.accountingEmailPlaceholder")}
              name={"accountingEmail"}
              value={formik.values.accountingEmail}
              onChange={(e) => {
                const value = EmailMask(e.target.value)
                formik.handleChange({
                  target: { name: "accountingEmail", value },
                })
              }}
              error={
                formik.touched.accountingEmail &&
                Boolean(formik.errors.accountingEmail)
              }
              helperText={
                (formik.touched.accountingEmail &&
                  formik.errors.accountingEmail?.toString()) ||
                t("form.accountingEmailHelperText")
              }
              fullWidth
            />
          </div>
        </Box>
        <Divider orientation="horizontal" />
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          $width={"100%"}
          $px="m"
          $pt="xs"
        >
          <div>
            <LinkButton variant="secondary" onClick={handleClose}>
              {t("buttons.cancel")}
            </LinkButton>
          </div>
          <Button
            size="large"
            variant="primary"
            onClick={() => formik.handleSubmit()}
          >
            {t("buttons.submit")}
            <Icons
              name="IconCheck"
              fill="transparent"
              color={theme.colors.neutral100}
            />
          </Button>
        </Box>
      </>
    ),
    [Steps.Confirmation]: () => (
      <>
        <Box flexDirection="column" gap="xs3" $px="m" $pb="xs">
          <ShapeIcon
            variant="default"
            name="IconLock"
            size={48}
            color={theme.colors.secondary50}
          />
          <Typography
            variant="body4"
            variantColor={theme.colors.secondary50}
            weight={700}
          >
            {t("confirmation.highlight")}
          </Typography>
          <Typography
            variant="headline8"
            variantColor={theme.colors.neutral10}
            weight={700}
          >
            {t("confirmation.title")}
          </Typography>
          <Typography
            variant="body4"
            variantColor={theme.colors.neutral40}
            weight={400}
          >
            {t("confirmation.description")}
          </Typography>
          <Typography
            variant="body4"
            weight={700}
            variantColor={theme.colors.neutral40}
          >
            {formik.values.accountingEmail}
          </Typography>
        </Box>
        <Divider orientation="horizontal" />
        <Box
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          $width={"100%"}
          $px="m"
          $pt="xs"
        >
          <div>
            <LinkButton variant="secondary" onClick={handleClose}>
              {t("buttons.cancel")}
            </LinkButton>
          </div>
          <Button
            size="large"
            variant="primary"
            onClick={() => {
              tracking.trackEvent({
                name: "hiring_clicked_export_candidate_report",
              })
              createReport({
                mailTo: formik.values.accountingEmail,
                candidateIds: formik.values.candidates.map((it) => it.value),
              })
            }}
            loading={isCreateReportLoading}
          >
            {t("buttons.submit")}
            <Icons
              name="IconCheck"
              fill="transparent"
              color={theme.colors.neutral100}
            />
          </Button>
        </Box>
      </>
    ),
    [Steps.Success]: () => (
      <>
        <Box flexDirection="column" gap="xs3" $px="m" $pb="xs">
          <ShapeIcon
            variant="success"
            name="IconCircleCheck"
            size={48}
            color={theme.colors.success40}
          />
          <Typography
            variant="body4"
            variantColor={theme.colors.success40}
            weight={700}
          >
            {t("success.highlight")}
          </Typography>
          <Typography
            variant="headline8"
            variantColor={theme.colors.neutral10}
            weight={700}
          >
            {t("success.title")}
          </Typography>
          <Typography
            variant="body4"
            variantColor={theme.colors.neutral40}
            weight={400}
          >
            {t("success.description")}
          </Typography>
        </Box>
        <Divider orientation="horizontal" />
        <Box
          justifyContent="center"
          alignItems="center"
          $width={"100%"}
          $px="m"
          $pt="xs"
        >
          <div>
            <Button size="large" variant="primary" onClick={handleClose}>
              <Icons
                name="IconArrowLeft"
                fill="transparent"
                color={theme.colors.neutral100}
              />
              {t("success.returnButton")}
            </Button>
          </div>
        </Box>
      </>
    ),
  }

  return (
    <Modal.Root open={isOpen} onClose={handleClose} size="xs">
      {contents[step]()}
    </Modal.Root>
  )
}
