import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Upload } from "../../../components"
import {
  ContentContainer,
  SubContainer,
  StyledIconContainer,
  Divider,
  StyledPOneLine,
} from "./styles"

import {
  Typography,
  Icons,
  TextField,
  LinkButton,
  dayjs,
  DatePicker,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useFormik } from "formik"
import { FileType, HiringCard } from "../../../types"
import { trpc } from "@api/client"
import { mergeAdmissionalExams } from "@utils/index"

const InternshipContent = ({ card }: { card: HiringCard }) => {
  const [localCard, setLocalCard] = useState<HiringCard>(card)
  const [hasLinkedinEdit, setHasLinkedinEdit] = useState(false)
  const [hasVacancyEdit, setHasVacancyEdit] = useState(false)

  const [t] = useTranslation("translations", { keyPrefix: "page.candidate" })

  const { mutateAsync: updateCard } = trpc.card.updateHiringCard.useMutation()

  const { mutateAsync: updateCandidate } = trpc.candidate.update.useMutation()

  const handleFile = async ({ path }: FileType): Promise<void> => {
    const updatedCard = await updateCard({
      metadata: {
        ...localCard.metadata,
        candidate: {
          ...localCard.metadata?.candidate,
          cv: { key: path, path, type: "internal" },
        },
      },
      flowCardId: localCard._id,
    })
    setLocalCard((prevState: any) => ({
      ...prevState,
      metadata: {
        ...prevState.metadata,
        candidate: {
          ...prevState.metadata?.candidate,
          cv: updatedCard.metadata?.candidate?.cv,
        },
      },
    }))
  }
  const updateFieldGlobally = useCallback(
    async ({ values }) => {
      await updateCandidate(
        { candidateId: localCard.candidateId, fields: values },
        {
          onError: (err: any) => {
            if (err instanceof Error) {
              console.log(err.stack)
            }
          },
        },
      )
    },
    [localCard.candidateId],
  )

  const formik = useFormik({
    initialValues: {
      institutionName: localCard.candidate.internship?.institutionName || "",
      courseName: localCard.candidate.internship?.courseName || "",
      endDate: localCard.candidate.internship?.endDate || "",
    },
    onSubmit: async () => {},
  })

  const folder = `${localCard.companyId}/${localCard.candidateId}/${localCard._id}`

  const handleRemoveFile = async () => {
    const updatedCard = await updateCard({
      metadata: {
        ...localCard.metadata,
        candidate: { ...localCard.metadata?.candidate, cv: {} },
      },
      flowCardId: localCard._id,
    })
    setLocalCard((prevState) => ({
      ...prevState,
      ...updatedCard,
      admissionalExam: mergeAdmissionalExams(
        prevState.admissionalExam,
        updatedCard.admissionalExam,
      ),
    }))
  }

  const renderPreviewComponent = ({ label, title, url }) => {
    return (
      <div>
        <div
          style={{
            marginBottom: "16px",
          }}
        >
          <span
            style={{
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "22px",
              color: "#83727D",
            }}
          >
            {label}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "32px 40px",
            border: "1px solid #EBE6E9",
            borderRadius: "10px",
          }}
        >
          <LinkButton
            variant="primary"
            style={{ alignSelf: "center" }}
            onClick={() => {
              window.open(url, "_blank")
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body3">
                <StyledPOneLine numberOfLines={1}>{title}</StyledPOneLine>
              </Typography>
              <Icons
                name="IconExternalLink"
                style={{ width: "16px", height: "16px", marginLeft: "4px" }}
                fill="transparent"
              />
            </div>
          </LinkButton>

          <StyledIconContainer
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              if (label === "Linkedin") setHasLinkedinEdit(true)
              if (label === "Link da vaga") setHasVacancyEdit(true)
            }}
          >
            <Icons
              name="IconEdit"
              fill="transparent"
              style={{ width: "16px", height: "16px" }}
            />
          </StyledIconContainer>
        </div>
      </div>
    )
  }

  return (
    <ContentContainer>
      <SubContainer>
        <Typography
          variant={"headline8"}
          style={{ color: "#53464F", fontWeight: 700 }}
        >
          Instituição de ensino
        </Typography>

        <Typography
          variant={"body4"}
          style={{ color: "#83727D", fontWeight: 400 }}
        >
          Preencha o campo abaixo com o nome da instituição cursada pela pessoa
        </Typography>

        <div style={{ marginBottom: "24px", marginTop: "24px" }}>
          <TextField
            type={"text"}
            label={"Instituição de ensino"}
            placeholder={"Instituição de ensino"}
            name={"institutionName"}
            onChange={formik.handleChange}
            onBlur={async (e) => {
              await updateFieldGlobally({
                values: {
                  internship: {
                    institutionName: e.target.value || null,
                  },
                },
              })
            }}
            value={formik.values.institutionName}
            fullWidth={true}
          />
        </div>

        <div style={{ marginBottom: "24px" }}>
          <TextField
            type={"text"}
            label={"Curso"}
            placeholder={"Curso"}
            name={"courseName"}
            value={formik.values.courseName}
            onChange={formik.handleChange}
            onBlur={async (e) => {
              await updateFieldGlobally({
                values: {
                  internship: {
                    courseName: e.target.value || null,
                  },
                },
              })
            }}
            fullWidth={true}
          />
        </div>

        <div>
          <DatePicker
            id={"hiringEndDate"}
            name={"hiringEndDate"}
            label={"Data prevista de término"}
            placeholder={"Digite a data prevista de término"}
            fromDate={dayjs()}
            value={formik.values.endDate}
            onDateChange={async (value) => {
              const date = value && dayjs(value).isValid() ? dayjs(value) : null
              const parsedValue = dayjs(value).isValid()
                ? date?.toISOString()
                : null

              formik.handleChange({
                target: { id: "endDate", value: parsedValue },
              })

              if (!date) return

              await updateFieldGlobally({
                values: {
                  internship: {
                    endDate: parsedValue,
                  },
                },
              })
            }}
            fullWidth
          />
        </div>

        <Divider style={{ marginTop: "40px", marginBottom: "40px" }} />

        <Typography
          variant={"headline8"}
          style={{ color: "#53464F", fontWeight: 700 }}
        >
          {t("recruitmentDataTitle")}
        </Typography>

        <Typography
          variant={"body4"}
          style={{ color: "#83727D", fontWeight: 400 }}
        >
          {t("recruitmentDataDescription")}{" "}
        </Typography>

        <div style={{ marginTop: "24px" }}>
          <Upload.Root
            accept={["pdf"]}
            folder={folder}
            label="CV"
            awsUtilitiesModule="hiring"
            maxSize={5242880}
            onUpload={handleFile}
            value={
              localCard.metadata?.candidate?.cv?.path
                ? { path: localCard.metadata.candidate.cv.path }
                : undefined
            }
            customFileName={`Curriculo_${card.candidate.name.replace(/\s/g, "_")}`}
          >
            <Upload.Action
              icon="IconDownload"
              onClick={({ url }) => {
                window.open(url, "_blank")
              }}
            />
            <Upload.Action icon="IconTrash" onClick={handleRemoveFile} />
          </Upload.Root>
        </div>

        <div style={{ marginTop: "24px", marginBottom: "24px" }}>
          {localCard.metadata?.candidate?.linkedin && !hasLinkedinEdit ? (
            renderPreviewComponent({
              label: "Linkedin",
              title: "Visualizar Linkedin",
              url: localCard.metadata?.candidate?.linkedin,
            })
          ) : (
            <TextField
              defaultValue={localCard.metadata?.candidate?.linkedin}
              name={"linkedin"}
              onBlur={async (e) => {
                await updateCard(
                  {
                    metadata: {
                      ...localCard.metadata,
                      candidate: {
                        ...localCard.metadata?.candidate,
                        linkedin: e.target.value,
                      },
                    },
                    flowCardId: localCard._id,
                  },
                  {
                    onSuccess: (_, ctx) => {
                      setLocalCard((prevState: any) => ({
                        ...prevState,
                        ...ctx,
                      }))
                    },
                  },
                )
              }}
              label={"Linkedin"}
              type={"text"}
              placeholder={t("linkedinPlaceHolder")}
              style={{ width: "100%" }}
            />
          )}
        </div>
        <div>
          {localCard.metadata?.candidate?.vacancyLink && !hasVacancyEdit ? (
            renderPreviewComponent({
              label: "Link da vaga",
              title: "Visualizar link da vaga",
              url: localCard.metadata?.vacancyLink,
            })
          ) : (
            <TextField
              defaultValue={localCard.metadata?.candidate?.vacancyLink || ""}
              name={"vacancyLink"}
              onBlur={async (e) => {
                await updateCard(
                  {
                    metadata: {
                      ...localCard.metadata,
                      candidate: {
                        ...localCard.metadata?.candidate,
                        vacancyLink: e.target.value,
                      },
                    },
                    flowCardId: localCard._id,
                  },
                  {
                    onSuccess: (_, ctx) => {
                      setLocalCard((prevState: any) => ({
                        ...prevState,
                        ...ctx,
                      }))
                    },
                  },
                )
              }}
              label={t("vacantJobLabel")}
              type={"text"}
              placeholder={t("vacantJobPlaceholder")}
              style={{ width: "100%" }}
            />
          )}
        </div>
      </SubContainer>
    </ContentContainer>
  )
}

export default InternshipContent
