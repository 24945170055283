import { hiringColumnsId, resignationColumnsId } from "./board"

export type StatusDetails = {
  name: string
  colors: {
    background: string
    text: string
    circleDot?: string
  }
}

export const DefaultStatusColors: {
  [key in string]: StatusDetails["colors"]
} = {
  green: {
    text: "#094338",
    background: "#D7F9F3",
    circleDot: "#2ED9B8",
  },
  blue: {
    text: "#00244C",
    background: "#D4E8FF",
    circleDot: "#4DA0FE",
  },
  yellow: {
    text: "#4C2900",
    background: "#FFECD6",
    circleDot: "#FEA034",
  },
}

export type StatusOptions = {
  columnIds: string[]
  status: StatusDetails[]
}

const hiringStatusOptions: StatusOptions[] = [
  {
    columnIds: hiringColumnsId.candidates,
    status: [
      {
        name: "Em andamento",
        colors: {
          background: "#D4E8FF",
          text: "#00244C",
        },
      },
    ],
  },
  {
    columnIds: hiringColumnsId.proposedLetter,
    status: [
      {
        name: "Envio da proposta pendente",
        colors: {
          background: "#FFECD6",
          text: "#4C2900",
        },
      },
      {
        name: "Proposta recusada",
        colors: {
          background: "#FFECD6",
          text: "#4C2900",
        },
      },
      {
        name: "Aguardando retorno do candidato",
        colors: {
          background: "#D4E8FF",
          text: "#00244C",
        },
      },
    ],
  },
  {
    columnIds: hiringColumnsId.documentsSend,
    status: [
      {
        name: "Solicitação dos documentos pendentes",
        colors: {
          background: "#FFECD6",
          text: "#4C2900",
        },
      },
      {
        name: "Aguardando retorno do candidato",
        colors: {
          background: "#D4E8FF",
          text: "#00244C",
        },
      },
    ],
  },
  {
    columnIds: hiringColumnsId.documentsValidation,
    status: [
      {
        name: "Validação pendente",
        colors: {
          background: "#FFECD6",
          text: "#4C2900",
        },
      },
    ],
  },
  {
    columnIds: hiringColumnsId.admission,
    status: [
      {
        name: "Solicitação pendente",
        colors: {
          background: "#FFECD6",
          text: "#4C2900",
        },
      },
      {
        name: "Aguardando retorno do candidato",
        colors: {
          background: "#D4E8FF",
          text: "#00244C",
        },
      },
      {
        name: "Validação pendente",
        colors: {
          background: "#FFECD6",
          text: "#4C2900",
        },
      },
      {
        name: "Candidato apto",
        colors: {
          background: "#D7F9F3",
          text: "#094338",
        },
      },
      {
        name: "Candidato inapto",
        colors: {
          background: "#FFECD6",
          text: "#4C2900",
        },
      },
    ],
  },
  {
    columnIds: hiringColumnsId.signing,
    status: [
      {
        name: "Envio pendente",
        colors: DefaultStatusColors.yellow,
      },
      {
        name: "Assinado",
        colors: DefaultStatusColors.green,
      },
      {
        name: "4 assinaturas pendentes",
        colors: DefaultStatusColors.blue,
      },
      {
        name: "3 assinaturas pendentes",
        colors: DefaultStatusColors.blue,
      },
      {
        name: "2 assinaturas pendentes",
        colors: DefaultStatusColors.blue,
      },
      {
        name: "1 assinatura pendente",
        colors: DefaultStatusColors.blue,
      },
      {
        name: "Assinatura pendente",
        colors: DefaultStatusColors.yellow,
      },
      // deprecated but keep to not disapear old status
      {
        name: "Em andamento",
        colors: {
          background: "#D4E8FF",
          text: "#00244C",
        },
      },
    ],
  },
  {
    columnIds: hiringColumnsId.done,
    status: [],
  },
  {
    columnIds: hiringColumnsId.initialConfiguration,
    status: [
      {
        name: "Não iniciado",
        colors: {
          background: "#EBE6E9",
          text: "#1D161B",
        },
      },
      {
        name: "Em andamento",
        colors: {
          background: "#D4E8FF",
          text: "#1D161B",
        },
      },
      {
        name: "Configurações finalizadas",
        colors: {
          background: "#D7F9F3",
          text: "#1D161B",
        },
      },
    ],
  },
  {
    columnIds: hiringColumnsId.archived,
    status: [],
  },
]

const resignationStatusOptions: StatusOptions[] = [
  {
    columnIds: resignationColumnsId.onHold,
    status: [
      {
        name: "Em andamento",
        colors: {
          background: "#D4E8FF",
          text: "#00244C",
        },
      },
    ],
  },
  {
    columnIds: resignationColumnsId.startProcess,
    status: [
      {
        name: "Em andamento",
        colors: {
          background: "#D4E8FF",
          text: "#00244C",
        },
      },
    ],
  },
  {
    columnIds: resignationColumnsId.interview,
    status: [
      {
        name: "Em andamento",
        colors: {
          background: "#D4E8FF",
          text: "#00244C",
        },
      },
    ],
  },
  {
    columnIds: resignationColumnsId.exam,
    status: [
      {
        name: "Não iniciado",
        colors: DefaultStatusColors.yellow,
      },
      {
        name: "Aguardando retorno do colaborador",
        colors: DefaultStatusColors.blue,
      },
      {
        name: "Validação pendente",
        colors: DefaultStatusColors.yellow,
      },
      {
        name: "Colaborador apto",
        colors: DefaultStatusColors.green,
      },
      {
        name: "Colaborador inapto",
        colors: DefaultStatusColors.yellow,
      },
    ],
  },
  {
    columnIds: resignationColumnsId.rescission,
    status: [
      {
        name: "Em andamento",
        colors: {
          background: "#D4E8FF",
          text: "#00244C",
        },
      },
    ],
  },
  {
    columnIds: resignationColumnsId.signature,
    status: [
      {
        name: "Envio pendente",
        colors: DefaultStatusColors.yellow,
      },
      {
        name: "Assinado",
        colors: DefaultStatusColors.green,
      },
      {
        name: "4 assinaturas pendentes",
        colors: DefaultStatusColors.blue,
      },
      {
        name: "3 assinaturas pendentes",
        colors: DefaultStatusColors.blue,
      },
      {
        name: "2 assinaturas pendentes",
        colors: DefaultStatusColors.blue,
      },
      {
        name: "1 assinatura pendente",
        colors: DefaultStatusColors.blue,
      },
      {
        name: "Assinatura pendente",
        colors: DefaultStatusColors.yellow,
      },
      // deprecated but keep to not disapear old status
      {
        name: "Em andamento",
        colors: {
          background: "#D4E8FF",
          text: "#00244C",
        },
      },
    ],
  },
  {
    columnIds: resignationColumnsId.documentUpdate,
    status: [
      {
        name: "Em andamento",
        colors: {
          background: "#D4E8FF",
          text: "#00244C",
        },
      },
    ],
  },
  {
    columnIds: resignationColumnsId.done,
    status: [],
  },
  {
    columnIds: resignationColumnsId.archived,
    status: [],
  },
]

const allStatusOptions = [...hiringStatusOptions, ...resignationStatusOptions]

export function getColumnStatusOptions(columnId: string) {
  const foundStatus = allStatusOptions.find((statusOption) =>
    statusOption.columnIds.includes(columnId),
  )
  if (!foundStatus)
    return {
      columnIds: [columnId],
      status: [],
    }

  return foundStatus
}
