import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
`

export const StyledFinishedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: ${({ theme }) => theme.spacings.xs3};
`

export const StyledDateRangeHelperText = styled(Typography)`
  margin-left: 16px;
  margin-top: -20px;
`
