import { Dropzone, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2"
import { S3File } from "../../../types"
import { useEffect, useState } from "react"
import { UploadPreview } from "../UploadPreview"
import dispatchToast from "../../../utils/dispatchToast"
import { uploadFile } from "../../../utils"
import { PreviewLoading } from "../PreviewLoading"
import { Description, ErrorContainer, IconContainer, Title } from "./styles"
import { trpc } from "@api/client"
import { DropzoneFile } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Dropzone/types"

interface UploadProps {
  value?: S3File
  label: string
  folder: string
  onUpload?: (file: S3File) => Promise<void>
  onRemove?: (file: S3File) => Promise<void>
  canDelete?: boolean
  canDownload?: boolean
  accept: string[]
  module?: "hiring" | "employee-pii"
  customFilename?: string
  maxSize?: number
  error?: boolean
}

export const Upload = ({
  label,
  value,
  accept,
  onUpload,
  folder,
  canDelete = true,
  canDownload = true,
  maxSize = 5242880,
  module = "hiring",
  customFilename,
  onRemove,
  error = false,
}: UploadProps) => {
  const [localFile, setLocalFile] = useState<S3File | undefined>(value)

  const [uploadedFile, setUploadedFile] = useState<S3File>()
  const [s3FilePath, setS3FilePath] = useState<string>()

  const { data: s3Response, refetch: getS3File } =
    trpc.helpers.getS3File.useQuery(
      { path: s3FilePath as string, module },
      { enabled: !!s3FilePath },
    )

  useEffect(() => {
    if (uploadedFile && s3Response) {
      uploadedFile.value = s3Response.signedUrl
      setUploadedFile((prev) => ({
        ...(prev as S3File),
        value: s3Response.signedUrl,
      }))
      setLocalFile(uploadedFile)
      if (onUpload) onUpload(uploadedFile)
    }
  }, [s3Response])

  const handleRemove = (file: S3File) => {
    setLocalFile(undefined)
    if (onRemove) onRemove(file)
  }

  const handleChange = async (files: DropzoneFile[]) => {
    try {
      const { file, id: fileName } = files[0]
      const name = customFilename
        ? `${Date.now().toString()}-${customFilename}`
        : fileName
      const uploadedFile = await uploadFile(folder, name, file, module)
      setUploadedFile(uploadedFile)
      setS3FilePath(uploadedFile.path)
    } catch (err: any) {
      dispatchToast({
        content: "Algo aconteceu ao fazer o upload do arquivo.",
        type: "error",
      })
    }
  }

  if (error)
    return (
      <ErrorContainer>
        <IconContainer>
          <Icons name={"IconX"} size={32} color="#C96C01" />
        </IconContainer>
        <Title variant="body3" style={{ fontWeight: "700" }}>
          {localFile
            ? localFile.path.split("/").at(-1)
            : "Erro ao fazer o upload do arquivo"}
        </Title>
        <Description variant="body3">
          {localFile
            ? "O arquivo enviado não segue os padrões do modelo flash."
            : "Tente novamente mais tarde."}
        </Description>
        <LinkButton
          variant={"error"}
          style={{ alignSelf: "center", fontWeight: "700", marginTop: "24px" }}
          onClick={() => setLocalFile(undefined)}
        >
          Tentar Novamente
        </LinkButton>
      </ErrorContainer>
    )

  if (localFile) {
    return (
      <UploadPreview
        file={localFile}
        hasShareLink={true}
        options={{
          canRemove: canDelete,
          canDownload: canDownload,
          handleRemove: canDelete ? handleRemove : undefined,
        }}
      />
    )
  }

  return (
    <Dropzone
      accept={accept}
      title={label}
      multiple={false}
      onChange={handleChange}
      style={{ width: "100%" }}
      maxSize={maxSize}
      customPreview={() => <PreviewLoading />}
      onFileSizeError={() => {
        dispatchToast({
          content:
            "Este arquivo ultrapassa o limite máximo. Por favor, faça o upload de um arquivo menor.",
          type: "error",
        })
      }}
    />
  )
}
