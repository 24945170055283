import { Icons, TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { Grid } from "@mui/material"

import styled from "styled-components"
import { DeprecatedModal } from "../../../../common"

const StyledContainer = styled.div`
  padding: 44px 40px;
`

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 32px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding-bottom: 32px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  }
`

const StyledModal = styled(DeprecatedModal)`
  && {
    .modal-container {
      border-radius: 16px;
      width: 870px;
      height: 580px;
      overflow: hidden;
    }
    .modal-content-area {
      padding: 0px;
      overflow: auto;
    }
  }
`

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 40px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`

const StyledTextLinkButton = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
  font-weight: 700 !important;
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[10]};
`

const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

const StyledFieldDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[70]};
`

const StyledToggleText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`

const SectionSelect = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`

const StyledTextField = styled(TextField)`
  width: 100%;
`

const StyledGrid = styled(Grid)``

const LeftContainer = styled.div`
  padding-right: 24px;
  border-right: 1px solid ${({ theme }) => theme.colors.neutral[90]};

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    border-right: none;
  }
`

const StyledFlexCenter = styled.div`
  display: flex;
  align-items: center;
`

const StyledIconsHoverable = styled(Icons)`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

const StyledFlex = styled.div`
  display: flex;
`

const RightContainer = styled.div`
  padding-left: 24px;
  position: relative;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding-left: 0px;
    padding-top: 32px;
  }
`

const CloseContainer = styled.div`
  position: absolute;
  right: -21px;
  top: -21px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    display: none;
  }
`

const localStyles = {
  linkButton: {
    alignSelf: "center",
  },
}

export {
  CloseContainer,
  LeftContainer,
  localStyles,
  RightContainer,
  SectionSelect,
  StyledButtonContainer,
  StyledContainer,
  StyledDescription,
  StyledFieldDescription,
  StyledFlex,
  StyledFlexCenter,
  StyledFooter,
  StyledGrid,
  StyledIconsHoverable,
  StyledModal,
  StyledTextField,
  StyledTextLinkButton,
  StyledTitle,
  StyledToggleText,
  ToggleContainer,
}
