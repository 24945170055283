import { z } from "zod"
import { IDocumentValidation } from "./candidate"

export interface FormConfigList {
  extensionId?: string | null
  name: string
  numberOfGroups: number
  _id: string
  default: boolean
}

export interface FormConfig {
  _id: string
  name: string
  formGroups: FormGroup[]
  companyId?: string
  baseId?: string
  extensionId?: string | null
  type: "base" | "extension"
}

export enum FieldType {
  Text = "text",
  Number = "number",
  Date = "date",
  Boolean = "boolean",
  File = "file",
  Array = "array",
}

export enum Component {
  ShortText = "shortText",
  LongText = "longText",
  Number = "number",
  Attachment = "attachment",
  Link = "link",
  DatePicker = "datePicker",
  TimePicker = "timePicker",
  Select = "select",
  MultiSelect = "multiSelect",
  Checkbox = "checkbox",
}

export enum ComparisonType {
  Equal = "equal",
  NotEqual = "notEqual",
  GreaterThan = "greaterThan",
  GreaterThanOrEqual = "greaterThanOrEqual",
  LesserThan = "lesserThan",
  LesserThanOrEqual = "lesserThanOrEqual",
  Includes = "includes",
  NotIncludes = "notIncludes",
  Exists = "exists",
  NotExists = "notExists",
  Or = "or",
  And = "and",
}

const CompareFieldSchema = z.object({
  _id: z.string().optional(),
  name: z.string().optional(),
  sensitive: z.boolean().optional(),
  type: z.nativeEnum(FieldType),
  isFixedValue: z.boolean().optional(),
  value: z.any().optional(),
})

export type CompareField = z.infer<typeof CompareFieldSchema>

const ComparisonSchema = z.object({
  _id: z.string().optional(),
  compared: CompareFieldSchema.optional(),
  comparedTo: CompareFieldSchema.optional(),
  comparisonType: z.nativeEnum(ComparisonType),
  comparisons: z.array(z.any()).optional(), // any because it would be a self reference to ComparisonSchema
})

export type Comparison = z.infer<typeof ComparisonSchema>

const FieldRuleSchema = z.object({
  validations: z.array(ComparisonSchema).optional(),
  enable: z.array(ComparisonSchema).optional(),
  required: z.boolean().optional(),
  fieldId: z.string(),
})

export type FieldRule = z.infer<typeof FieldRuleSchema>

export interface FieldValue extends FieldConfig {
  value?: any
  candidateId: string
}

export const FieldConfigSchema = z.object({
  fieldId: z.string(),
  customFieldId: z.string(),
  documentTypeId: z.string().optional(),
  formGroupId: z.string(),
  order: z.number().optional(),
  fieldType: z.nativeEnum(FieldType),
  options: z
    .array(
      z.union([z.string(), z.object({ label: z.string(), value: z.string() })]),
    )
    .optional()
    .nullable()
    .transform((value) => (value === null ? undefined : value)),
  component: z.nativeEnum(Component),
  placeholder: z.string(),
  fieldRule: FieldRuleSchema.optional(),
  type: z.enum(["base", "extension"]),
  enabled: z.boolean(),
})

export type FieldConfig = z.infer<typeof FieldConfigSchema>

export const FormGroupSchema = z.object({
  _id: z.string(),
  title: z.string().optional(),
  order: z.number().optional(),
  enabled: z.boolean().optional(),
  category: z.enum(["employee", "dependent"]),
  type: z.enum(["base", "extension"]).optional(),
  description: z.string().optional(),
  fields: z.array(FieldConfigSchema).optional(),
  fillInstruction: z.object({ html: z.string(), json: z.string() }).nullish(),
})

export type FormGroup = z.infer<typeof FormGroupSchema>

export interface ConfirmationData {
  candidateId: string
  formConfigId: string
  extensionId?: string
  candidateFields: FieldValue[]
  documentFields: ConfirmationDocumentsWithValue[]
  dependents?: ConfirmationDependent[]
}

export interface ConfirmationDependent {
  _id: any
  name: string
  active: boolean
  kinship: string
  birthDate: string
  candidateId: string
  formConfig: string
  extensionId: string
  documents: ConfirmationDocumentsWithValue[]
}

export interface ConfirmationDocumentsWithValue {
  _id: string
  formConfigId: string
  extensionId?: string
  name: string
  title: string
  description?: string
  enabled: boolean
  fields: FieldValue[]
  validation?: IDocumentValidation
}
