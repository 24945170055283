import React from "react"

type ProgressAnimationState = {
  startValue: number
  targetValue: number
  startTime: number
  duration: number
}

export const useProgressAnimation = (progress: number, duration = 2000) => {
  const [localProgress, setLocalProgress] = React.useState(progress)
  const animationRef = React.useRef<number>()
  const animationState = React.useRef<ProgressAnimationState>({
    startValue: 0,
    targetValue: 0,
    startTime: 0,
    duration: duration,
  })

  const animate = (timestamp: number) => {
    const state = animationState.current
    const elapsed = timestamp - state.startTime
    const progress = Math.min(elapsed / state.duration, 1)

    const currentProgress =
      state.startValue + (state.targetValue - state.startValue) * progress

    setLocalProgress(currentProgress)

    if (progress < 1) {
      animationRef.current = requestAnimationFrame(animate)
    }
  }

  React.useEffect(() => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current)
    }

    animationState.current = {
      startValue: localProgress,
      targetValue: progress,
      startTime: performance.now(),
      duration: duration,
    }

    animationRef.current = requestAnimationFrame(animate)

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
      }
    }
  }, [progress, duration])

  return Math.round(localProgress)
}
