import { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

import {
  IconButton,
  Icons,
  Menu,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"

import { Option } from "./styled"

import { DeleteModel } from "./DeleteModel"

import dispatchToast from "../../../../../../utils/dispatchToast"

import { trpc } from "@api/client"

type ModelOptionsProps = {
  modelId: string
  flowId: string
}

export const ModelOptions = ({ modelId, flowId }: ModelOptionsProps) => {
  const navigate = useNavigate()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const utils = trpc.useUtils()

  const { mutateAsync: deleteModel, isLoading: deleteLoading } =
    trpc.contract.delete.useMutation({
      onSuccess: () => {
        setDeleteModalOpen(false)
        utils.contract.getByCompany.invalidate()
        dispatchToast({
          type: "success",
          content: "Modelo deletado com sucesso!",
        })
      },
      onError: () => {
        dispatchToast({ type: "error", content: "Erro ao excluir modelo." })
      },
    })

  const { mutateAsync: duplicateModel, isLoading: duplicateLoading } =
    trpc.contract.duplicate.useMutation({
      onSuccess: () => {
        utils.contract.getByCompany.invalidate()
        dispatchToast({
          type: "success",
          content: "Modelo duplicado com sucesso!",
        })
      },
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao duplicar modelo.",
        })
      },
    })

  const options = useMemo(() => {
    const items = [
      {
        key: "preview",
        onClick: () => {
          window.open(
            `/flows/settings/previewContract?modelId=${modelId}`,
            "_blank",
          )
        },
        children: (
          <Option>
            <Icons name="IconFileSearch" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Pré-visualizar modelo
            </Typography>
          </Option>
        ),
      },
      {
        key: "edit",
        onClick: () => {
          navigate(
            `/flows/settings/${flowId}/contracts/edit/basic-info/${modelId}`,
          )
        },
        children: (
          <Option>
            <Icons name="IconPencil" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Editar modelo
            </Typography>
          </Option>
        ),
      },
      {
        key: "duplicate",
        onClick: () => {
          duplicateModel({ modelContractId: modelId })
        },
        children: (
          <Option>
            <Icons name="IconCopy" fill="transparent" />
            <Typography variant="body3" style={{ fontWeight: 600 }}>
              Duplicar modelo
            </Typography>
          </Option>
        ),
      },
      // {
      //   key: "export",
      //   onClick: () => {},
      //   children: (
      //     <Option>
      //       <Icons name="IconDownload" fill="transparent" />
      //       <Typography variant="body3" style={{ fontWeight: 600 }}>
      //         Exportar modelo como PDF
      //       </Typography>
      //     </Option>
      //   ),
      // },
      {
        key: "delete",
        onClick: () => setDeleteModalOpen(true),
        children: (
          <Option>
            <Icons name="IconTrash" fill="transparent" color="#C96C01" />
            <Typography
              variant="body3"
              style={{ fontWeight: 600, color: "#C96C01" }}
            >
              Excluir modelo
            </Typography>
          </Option>
        ),
      },
    ]

    return items
  }, [modelId])

  return (
    <>
      <Menu
        type={"select"}
        options={options}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <IconButton variant="line" size="small" icon="IconDotsVertical" />
      </Menu>
      <DeleteModel
        isOpen={deleteModalOpen}
        loading={deleteLoading}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={() => deleteModel({ modelContractId: modelId })}
      />
    </>
  )
}
