import { IconButton as DeprecatedIconButton } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"
import { IconButtonStyledProps } from "."

export const IconButton = styled(DeprecatedIconButton)<IconButtonStyledProps>`
  && {
    svg {
      color: ${({ iconColor, theme }) =>
        iconColor ?? theme.colors.neutral[100]};
      stroke: currentColor;
    }
  }
`
