import {
  ConfirmationModal,
  HiringLimitDate,
  LimitDateRef,
  UploadOld,
} from "@components/common"
import { HiringCard } from "@customTypes/card"
import { Flow } from "@customTypes/flow"
import dispatchToast from "@utils/dispatchToast"
import { HiringLimitDateEnum } from "bff/src/types/hiring"
import { useFormik } from "formik"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Row } from "../../styles"
import {
  Button,
  DatePicker,
  dayjs,
  Icons,
  Loader,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { trpc } from "@api/client"
import i18n from "@i18n"
import * as yup from "yup"
import { useTracking } from "@utils/useTracking"

const getLimitDateStep = (subcategory?: "clt" | "internship" | "pj") => {
  if (!subcategory) return null
  switch (subcategory) {
    case "clt":
      return HiringLimitDateEnum.aso_Ctl
    case "internship":
      return HiringLimitDateEnum.aso_Estag
  }
}

const validationSchema = yup.object({
  asoLimitDate: yup
    .string()
    .required(i18n.t("page.hiring.admissionalExam.formValidationError")),
  medicalReferral: yup
    .object({
      path: yup.string(),
      key: yup.string(),
      type: yup.string(),
    })
    .nullable(),
})

export const SendRequestContent = ({
  card,
  flow,
}: {
  card: HiringCard
  flow: Flow
}) => {
  const [validateAfterSubmit, setValidateAfterSubmit] = useState<boolean>(false)
  const [confirmationSendModal, setConfirmationSendModal] =
    useState<boolean>(false)

  const [t] = useTranslation("translations", {
    keyPrefix: "page.hiring.admissionalExam",
  })
  const utils = trpc.useUtils()
  const tracking = useTracking()
  const limitDateRef = useRef<LimitDateRef>(null)

  const { mutateAsync: sendAdmissionalExam, isLoading: isSending } =
    trpc.admissionalExam.sendAdmissionalExam.useMutation({
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
      onSuccess: () => {
        utils.card.getHiringCardById.invalidate()
        dispatchToast({
          content: i18n.t("page.hiring.admissionalExam.toastSendRequest"),
          type: "success",
        })
      },
    })

  const formik = useFormik({
    initialValues: {
      asoLimitDate: card.admissionalExam?.limitDate?.toISOString(),
      medicalReferral: card.admissionalExam?.medicalReferral,
    },
    validationSchema: validationSchema,
    validate: async () => {
      const errors: any = await limitDateRef.current?.validate()
      return errors
    },
    onSubmit: async (values) => {
      tracking.trackEvent({
        name: `company_hiring_${flow.subcategory}_admissionexam_send_clicked`,
      })

      if (!values.asoLimitDate) return
      await limitDateRef.current?.submit()

      const medicalReferral = values.medicalReferral
      if (medicalReferral?.value) delete medicalReferral.value

      await sendAdmissionalExam({
        flowCardId: card._id,
        limitDate: new Date(values.asoLimitDate).toISOString(),
        medicalReferral: medicalReferral ?? undefined,
      })
    },
    validateOnChange: validateAfterSubmit,
  })

  const handleSubmit = async () => {
    if (!validateAfterSubmit) setValidateAfterSubmit(true)

    const errors = await formik.validateForm()
    if (Object.keys(errors).length > 0) {
      dispatchToast({
        content: t("toastFieldsNotFilled"),
        type: "error",
      })
      return
    }

    formik.handleSubmit()
  }

  const limitDateStep = getLimitDateStep(flow?.subcategory)

  return (
    <>
      <Row>
        <div>
          <Typography variant="headline8" variantColor="#53464F">
            {t("dateLimitTitle")}
          </Typography>
          <Typography variant="body4" variantColor="#83727D">
            {t("dateLimitDescription")}
          </Typography>
        </div>

        <DatePicker
          label={t("dateLimitLabel")}
          value={formik.values.asoLimitDate}
          fromDate={dayjs()}
          onDateChange={(value) => {
            if (!dayjs(value).isValid()) return

            formik.handleChange({
              target: {
                id: "asoLimitDate",
                value: dayjs(value).toISOString(),
              },
            })
          }}
          error={!!formik.errors.asoLimitDate}
          helperText={
            !!formik.errors.asoLimitDate &&
            formik.errors.asoLimitDate.toString()
          }
          fullWidth
        />

        {limitDateStep ? (
          <HiringLimitDate
            ref={limitDateRef}
            cardId={card._id}
            step={limitDateStep}
            limitDate={card.limitDates?.find(
              (limitDate) => limitDate.step === limitDateStep,
            )}
          />
        ) : (
          <></>
        )}
      </Row>

      <Row>
        <div>
          <Typography variant="headline8" variantColor="#53464F">
            {t("guideTitle")}
          </Typography>
          <Typography variant="body4" variantColor="#83727D">
            {t("guideDescription")}
          </Typography>
        </div>

        <UploadOld
          value={formik.values.medicalReferral}
          label={t("guideLabel")}
          accept={["jpg", "png", "pdf"]}
          folder={`${card.companyId}/${card.candidateId}/${card._id}/aso`}
          module="employee-pii"
          customFilename={`Guia_${card.candidate.name}`}
          onUpload={async (file) => {
            tracking.trackEvent({
              name: `company_hiring_${flow.subcategory}_fileupload_aso_clicked`,
            })

            formik.handleChange({
              target: {
                id: "medicalReferral",
                value: file,
              },
            })
          }}
        />
      </Row>

      <Button
        variant="primary"
        size="medium"
        onClick={() => setConfirmationSendModal(true)}
        style={{ width: "100%" }}
        disabled={isSending}
      >
        {isSending ? (
          <Loader size="extraSmall" variant="secondary" />
        ) : (
          <>
            {t("buttonSendRequest")}
            <Icons fill="transparent" name="IconMail" />
          </>
        )}
      </Button>

      <ConfirmationModal
        variant="primary"
        isOpen={confirmationSendModal}
        onClose={() => setConfirmationSendModal(false)}
        title="Tem certeza que deseja enviar a solicitação de ASO?"
        description="Verifique se todas as informações foram preenchidas corretamente."
        confirmButton={{
          onClick: handleSubmit,
          isLoading: isSending,
          text: "Prosseguir e enviar",
          icon: "IconArrowRight",
        }}
      />
    </>
  )
}
