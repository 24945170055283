import {
  Button,
  Checkbox,
  Icons,
  LinkButton,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import emptyChecklist from "../../../../assets/emptyChecklist.png"
import {
  ChecklistActionWrapper,
  ChecklistContainer,
  ChecklistIcon,
  ChecklistItem,
  ChecklistWrapper,
  EmptyStateContainer,
} from "./styles"
import { useQueryClient } from "@tanstack/react-query"
import { ChecklistData } from "../../../../types"
import dispatchToast from "../../../../utils/dispatchToast"
import _ from "lodash"
import { Banner } from "../../../common"
import { PendoEnum } from "@pendo"
import { useTracking } from "@utils/useTracking"
import { trpc } from "@api/client"

type ChecklistMode = {
  mode: "view" | "create" | "update" | "new" | "empty"
  id?: number
}

interface ManageChecklist {
  flowId: string
  columnId: string
  companyId: string
  subcategory?: string
  checklist?: ChecklistData
  width?: string
  hasBanner?: boolean
}

const ManageChecklist = ({
  flowId,
  columnId,
  subcategory = "clt",
  companyId,
  checklist,
  width = "100%",
  hasBanner = true,
}: ManageChecklist) => {
  const [checklistMode, setChecklistMode] = useState<ChecklistMode>({
    mode: checklist?.items?.length ? "view" : "empty",
  })

  const refEditChecklist = useRef<HTMLInputElement>(null)
  const refCreateChecklist = useRef<HTMLInputElement>(null)

  const queryClient = useQueryClient()

  const tracking = useTracking()

  const mutateNewChecklistTemplate = trpc.modelChecklist.create.useMutation()
  const mutateAddChecklistTemplate = trpc.modelChecklist.addItem.useMutation()
  const mutateRemoveChecklistTemplate =
    trpc.modelChecklist.removeItem.useMutation()
  const mutateUpdateChecklistTemplate =
    trpc.modelChecklist.updateItem.useMutation()

  useEffect(() => {
    setChecklistMode({
      mode: checklist?.items?.length ? "view" : "empty",
    })
  }, [checklist, columnId])

  const handleAddChecklist = (newItem: string) => {
    mutateAddChecklistTemplate.mutate(
      {
        checklistId: checklist?._id as string,
        newItem,
      },
      {
        onError: (err: any) => {
          const erroMessage =
            err?.response?.data?.message ||
            "Algo inesperado aconteceu, tente novamente mais tarde"
          dispatchToast({
            content: erroMessage,
            type: "error",
          })
        },
        onSettled: () => {
          queryClient.invalidateQueries()
        },
      },
    )
    setChecklistMode({ mode: "view" })
  }

  const handleNewChecklist = (newChecklist: string) => {
    mutateNewChecklistTemplate.mutate(
      {
        columnId,
        companyId,
        flowId,
        items: [newChecklist],
      },
      {
        onSettled: () => {
          queryClient.invalidateQueries()
        },
      },
    )
    setChecklistMode({ mode: "view" })
  }

  const handleDelete = (item: string) => {
    mutateRemoveChecklistTemplate.mutate(
      {
        checklistId: checklist?._id as string,
        item,
      },
      {
        onSettled: () => {
          queryClient.invalidateQueries()
        },
      },
    )
  }

  const handleUpdateChecklist = (oldValue: string, newValue: string) => {
    mutateUpdateChecklistTemplate.mutate(
      {
        checklistId: checklist?._id as string,
        oldValue,
        newValue,
      },
      {
        onSettled: () => {
          queryClient.invalidateQueries()
        },
      },
    )
    setChecklistMode({ mode: "view" })
  }

  const renderActions = useCallback(() => {
    const viewMode = () => {
      return (
        <ChecklistActionWrapper>
          <Button
            variant="secondary"
            size="small"
            onClick={async () => {
              tracking.trackEvent({
                name: `company_hiring_${subcategory}_checklistmodel_addtask_clicked`,
              })
              setChecklistMode({ mode: "create" })
            }}
          >
            Adicionar tarefa
          </Button>
        </ChecklistActionWrapper>
      )
    }

    const createMode = () => {
      return (
        <Fragment>
          <ChecklistItem width={width}>
            <TextField
              ref={refCreateChecklist}
              type={"string"}
              label={"Adicionar tarefa"}
              fullWidth={true}
            />
          </ChecklistItem>

          <ChecklistActionWrapper>
            <Button
              variant="primary"
              size="small"
              onClick={() => {
                if (!refCreateChecklist?.current?.value) return

                handleAddChecklist(refCreateChecklist?.current?.value)
              }}
            >
              Adicionar
            </Button>

            <LinkButton
              variant="primary"
              onClick={() => setChecklistMode({ mode: "view" })}
              style={{ marginLeft: 25, paddingTop: 8 }}
            >
              Cancelar
            </LinkButton>
          </ChecklistActionWrapper>
        </Fragment>
      )
    }

    const updateMode = () => {
      return (
        <ChecklistActionWrapper>
          <Button
            variant="primary"
            size="small"
            onClick={() => {
              if (!refEditChecklist.current?.value) return
              const element = document.querySelector(
                `[data-id="${checklistMode.id}"]`,
              )
              if (!element) throw new Error("Checklist not found on list")

              const oldValue = _.unescape(element.innerHTML)
              const newValue = refEditChecklist.current?.value

              handleUpdateChecklist(oldValue, newValue)
            }}
          >
            Editar
          </Button>

          <LinkButton
            variant="primary"
            onClick={() => setChecklistMode({ mode: "view" })}
            style={{ marginLeft: 25, paddingTop: 8 }}
          >
            Cancelar
          </LinkButton>
        </ChecklistActionWrapper>
      )
    }

    const newMode = () => {
      return (
        <Fragment>
          <ChecklistItem width={width}>
            <TextField
              ref={refCreateChecklist}
              type={"string"}
              label={"Adicionar tarefa"}
              fullWidth={true}
            />
          </ChecklistItem>

          <ChecklistActionWrapper>
            <Button
              variant="primary"
              size="small"
              onClick={() => {
                if (!refCreateChecklist.current?.value) return

                if (!checklist) {
                  handleNewChecklist(refCreateChecklist.current?.value)
                  return
                }

                handleAddChecklist(refCreateChecklist.current?.value)
              }}
            >
              Adicionar
            </Button>
            <LinkButton
              variant="primary"
              onClick={() => setChecklistMode({ mode: "empty" })}
              style={{ marginLeft: 25, paddingTop: 8 }}
            >
              Cancelar
            </LinkButton>
          </ChecklistActionWrapper>
        </Fragment>
      )
    }

    const renderEmptyState = () => (
      <EmptyStateContainer style={{ marginTop: 20 }}>
        <img src={emptyChecklist} alt="Empty state" />
        <Typography variant="body3" style={{ color: "#53464F" }}>
          Você ainda não criou um template de checklist para esta etapa.
        </Typography>
        <div style={{ marginTop: 25 }}>
          <div data-onboarding={PendoEnum.MODEL_CHECKLIST_CREATE_BUTTON}>
            <Button
              size="large"
              variant="primary"
              onClick={() => {
                tracking.trackEvent({
                  name: `company_hiring_${subcategory}_checklistmodel_create_clicked`,
                })
                setChecklistMode({ mode: "new" })
              }}
            >
              Criar checklist
              <Icons name="IconPlus" />
            </Button>
          </div>
        </div>
      </EmptyStateContainer>
    )

    switch (checklistMode.mode) {
      case "view":
        return viewMode()
      case "update":
        return updateMode()
      case "create":
        return createMode()
      case "new":
        return newMode()
      case "empty":
        return renderEmptyState()
    }
  }, [checklistMode])

  const modeHasBanner = (mode: string) =>
    ["create", "update", "new"].includes(mode)

  return (
    <ChecklistContainer>
      {checklist &&
        checklist?.items?.map((item, idx) => {
          if (checklistMode.mode === "update" && idx === checklistMode.id)
            return (
              <ChecklistItem key={idx} width={width}>
                <span data-id={idx} style={{ display: "none" }}>
                  {item}
                </span>
                <TextField
                  ref={refEditChecklist}
                  type={"string"}
                  label={"Editar tarefa"}
                  defaultValue={item}
                  fullWidth={true}
                />
              </ChecklistItem>
            )

          return (
            <ChecklistItem key={idx} width={width}>
              <ChecklistWrapper>
                <Checkbox />
                <span data-id={idx}>{item}</span>
              </ChecklistWrapper>

              <ChecklistWrapper>
                <ChecklistIcon
                  name="IconPencil"
                  fill="transparent"
                  onClick={() =>
                    setChecklistMode({
                      mode: "update",
                      id: idx,
                    })
                  }
                />
                <ChecklistIcon
                  name="IconTrash"
                  fill="transparent"
                  onClick={() => {
                    const element = document.querySelector(`[data-id="${idx}"]`)
                    if (!element) throw new Error("Checklist not found on list")

                    const item = _.unescape(element.innerHTML)
                    handleDelete(item)
                  }}
                />
              </ChecklistWrapper>
            </ChecklistItem>
          )
        })}

      {renderActions()}

      {hasBanner && modeHasBanner(checklistMode.mode) && (
        <Banner
          type="info"
          icon="IconAlertCircle"
          message="As alterações feitas irão afetar todos os participantes desta etapa. Exceto as pessoas que receberem alterações individuais."
          style={{ marginTop: 25 }}
        />
      )}
    </ChecklistContainer>
  )
}

export default ManageChecklist
