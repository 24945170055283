import { useMemo } from "react"
import { useOutletContext } from "react-router-dom"

import { Table } from "./Table"
import dispatchToast from "../../../utils/dispatchToast"
import { Flow } from "../../../types"

import { Container, TextsContainer, ModelTitle, ModelSubtitle } from "./styles"

import { trpc } from "@api/client"

const hiringLabels = { clt: "CLT", pj: "PJ", internship: "Estágio" }

const ContractsTemplate = () => {
  const [flow] = useOutletContext<[flow: Flow]>()

  const hiringType = useMemo(() => flow.subcategory || "clt", [flow])

  const { data, isFetching: loading } = trpc.contract.getByCompany.useQuery(
    { flowId: flow?._id },
    {
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Erro ao buscar modelos, tente novamente mais tarde!.",
        })
      },
    },
  )

  return (
    <Container>
      <TextsContainer>
        <ModelTitle variant="body3">
          Modelos de contratos de assinatura
        </ModelTitle>
        <ModelSubtitle variant="body3">
          Crie modelos de contrato com campos personalizáveis e use os modelos
          prontos em qualquer admissão {hiringLabels[hiringType]}. Os dados do
          candidato serão preenchidos automaticamente.
        </ModelSubtitle>
      </TextsContainer>

      <Table
        loading={loading}
        flowId={flow?._id}
        data={data || []}
        filters={[
          {
            accessor: "status",
            label: "Status",
            options: [
              { label: "Publicado", value: "published" },
              { label: "Rascunho", value: "draft" },
            ],
            type: "checkbox",
          },
          {
            accessor: "createdAt",
            label: "Data de criação",
            options: [],
            type: "date",
          },
        ]}
      />
    </Container>
  )
}

export default ContractsTemplate
