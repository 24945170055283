import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"

import dispatchToast from "../../../utils/dispatchToast"

import {
  Button,
  DatePicker,
  dayjs,
  IconButton,
  Icons,
  Loader,
  SearchField,
  SelectField,
  Skeleton,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"

import {
  ButtonContainer,
  ContentContainer,
  FlexSpaceBetween,
  LineText,
  MultiSelectContainer,
  StyledContainedButton,
  StyledLinkButton,
  StyledPOneLine,
  StyledTextError,
  SubContainer,
  StyledTextArea2 as TextArea,
  Title,
  UploadFileName,
  UploadIconContainer,
  UploadPreview,
  UploadPreviewArea,
} from "./styles"

import { trpc } from "@api/client"
import { useIsFetching, useQueryClient } from "@tanstack/react-query"
import { HiringLimitDateEnum } from "bff/src/types/hiring"
import { useFormik } from "formik"
import {
  Banner,
  ConfirmationModal,
  HiringLimitDate,
  LimitDateRef,
  ProbationaryPeriod,
  ProbationaryRef,
  UploadMultiples,
} from "../../../components"
import { useBFF } from "../../../hooks"
import { HiringCard } from "../../../types"
import {
  currencyParser,
  formatCurrency,
  removeDropzoneTimestamp,
} from "../../../utils"
import i18n from "@i18n"
import { getQueryKey } from "@trpc/react-query"
import { useTracking } from "@utils/useTracking"

interface ProposedContent {
  card: HiringCard
  sendToCandidate: boolean
  setHasRefused: Dispatch<SetStateAction<boolean>>
}

const PJProposedContent = ({
  card,
  sendToCandidate,
  setHasRefused,
}: ProposedContent) => {
  const [confirmationSendModal, setConfirmationSendModal] =
    useState<boolean>(false)
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)
  const [proposedLetterModel, setProposedLetterModel] = useState<string>(
    card.modelProposedLetterId,
  )
  const [loadingOpenRemoveFile, setLoadingOpenRemoveFile] = useState<any>({
    loading: false,
    key: "",
  })
  const [dropZoneKey, setDropZoneKey] = useState(false)

  const queryClient = useQueryClient()
  const tracking = useTracking()

  const hiringCardQueryKey = getQueryKey(
    trpc.card.getHiringCardById,
    { cardId: card._id },
    "query",
  )
  const isHiringCardQueryFetching = useIsFetching(hiringCardQueryKey)

  const { hiring, candidate, token } = useBFF()
  const { mutateAsync: updateCard } = trpc.card.updateHiringCard.useMutation()

  const { mutateAsync: updateCandidate } = trpc.candidate.update.useMutation()

  const limitDateRef = useRef<LimitDateRef>(null)

  const {
    data: listModelProposedLetter,
    isInitialLoading: isLoadingAllModelProposedLetters,
  } = trpc.modelProposedLetter.getAllByFlowId.useQuery(
    {
      flowId: card.flowId,
    },
    {
      enabled: !!card.flowId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  )

  const { isInitialLoading: isLoadingModelProposedLetter } =
    trpc.modelProposedLetter.getById.useQuery(
      { modelId: proposedLetterModel },
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        enabled: !!proposedLetterModel,
        onError: (err) => {
          console.log(err)
          dispatchToast({
            content: "Houve um erro ao buscar o modelo de carta proposta",
            type: "error",
          })
        },
        onSuccess: (data) => {
          if (card.modelProposedLetterId !== proposedLetterModel) {
            formik.setValues({
              assignments: data.proposedLetter?.assignments || "",
              role: data.proposedLetter?.role,
              department: data.proposedLetter?.department,
              group: data.proposedLetter?.group,
              hiringType: "pj",
              salary: formatCurrency(data.proposedLetter?.salary) || "",
              hiringDate: card.candidate.proposalLetterInfo?.hiringDate,
              variableSalary: data.proposedLetter?.variableSalary || "",
              aboutCompany: data.proposedLetter?.aboutCompany || [],
              benefits: data.proposedLetter?.benefits || [],
            })

            updateFieldGlobally({
              values: {
                proposalLetterInfo: {
                  assignments: data.proposedLetter?.assignments,
                  role: data.proposedLetter?.role,
                  department: data.proposedLetter?.department,
                  hiringType: "pj",
                  group: data.proposedLetter?.group,
                  salary: data.proposedLetter?.salary,
                  variableSalary: data.proposedLetter?.variableSalary || "",
                  aboutCompany: data.proposedLetter?.aboutCompany || [],
                  benefits: data.proposedLetter?.benefits || [],
                },
              },
            })
            updateCard({
              flowCardId: card._id,
              modelProposedLetterId: proposedLetterModel,
            })
          }
        },
      },
    )

  const { data: groups, isInitialLoading: isGroupsLoading } =
    trpc.company.getGroups.useQuery(undefined, {
      enabled: !!card.companyId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    })

  const { data: departments, isInitialLoading: isDepartmentsLoading } =
    trpc.company.getDepartments.useQuery(undefined, {
      enabled: !!card.companyId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    })

  const { data: roles, isInitialLoading: isRolesLoading } =
    trpc.company.getRoles.useQuery(undefined, {
      enabled: !!card.companyId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    })

  const probationPeriodRef = React.useRef<ProbationaryRef>(null)

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const updateFieldGlobally = useCallback(
    async ({
      values,
    }: {
      values: Parameters<typeof updateCandidate>["0"]["fields"]
    }) => {
      await updateCandidate(
        {
          candidateId: card.candidateId || "",
          fields: values,
        },
        {
          onError: () => {
            dispatchToast({
              content: i18n.t("error.internalServerError"),
              type: "error",
            })
          },
        },
      )
    },
    [card.candidateId],
  )

  const validationSchema = yup.object({
    aboutCompany: yup
      .array()
      .of(
        yup.object().shape({
          key: yup.string().notRequired(),
          path: yup.string().notRequired(),
          type: yup.string().notRequired(),
          value: yup.string().notRequired(),
        }),
      )
      .nullable()
      .notRequired(),
    benefits: yup
      .array()
      .of(
        yup.object().shape({
          key: yup.string().notRequired(),
          path: yup.string().notRequired(),
          type: yup.string().notRequired(),
          value: yup.string().notRequired(),
        }),
      )
      .nullable()
      .notRequired(),
    assignments: yup.string().notRequired(),
    role: yup.object().required("Campo obrigatório/inválido").nullable(),
    department: yup.object().required("Campo obrigatório/inválido").nullable(),
    group: yup.object().required("Campo obrigatório/inválido").nullable(),
    hiringType: yup.string().required("Campo obrigatório/inválido").nullable(),
    salary: yup.string().required("Campo obrigatório/inválido").nullable(),
    hiringDate: yup
      .date()
      .typeError("Favor selecionar uma data válida")
      .required("Favor selecionar uma data")
      .nullable(),
  })

  const handleSendToCandidate = async (eventName: string) => {
    tracking.trackEvent({
      name: eventName,
    })
    const createdToken = await token.createToken.mutateAsync({
      candidateId: card.candidateId || "",
      cardId: card._id,
      category: "hiring",
      subcategory: "pj",
    })

    await candidate.sendProposalLetter.mutateAsync({
      columnId: card.columnId,
      flowCardId: card._id,
      candidateId: card.candidateId || "",
      subject: t("proposalLetter.proposalLetterNotification"),
      token: createdToken || "",
    })

    if (card.metadata?.hasSentProposalEmail) {
      await updateCard({
        flowCardId: card._id,
        metadata: {
          ...card.metadata,
          hasSentProposalEmail: true,
        },
      })
    }

    await updateCandidate({
      candidateId: card.candidateId,
      fields: {
        proposalLetterInfo: {
          proposalStatus: undefined,
        },
      },
    })

    await hiring.updateCardStatus.mutateAsync({
      newStatus: "Aguardando retorno do candidato",
      flowCardId: card._id,
      version: card.version,
    })

    dispatchToast({
      content: "Carta proposta enviada com sucesso!",
      type: "success",
    })
  }

  const handleAcceptPropoval = async () => {
    await hiring.acceptProposal.mutateAsync({
      candidateId: card.candidateId,
    })
    if (card.metadata?.hasSentProposalEmail) {
      await updateCard({
        metadata: { ...card.metadata, hasSentProposalEmail: true },
        flowCardId: card._id,
      })
    }

    await hiring.nextColumn.mutateAsync({
      flowCardId: card._id,
    })

    dispatchToast({
      content: "Proposta aceita com sucesso!",
      type: "success",
    })
  }

  const formSubmit = async () => {
    try {
      if (sendToCandidate) {
        await handleSendToCandidate("company_hiring_pj_proposal_send_clicked")
      } else {
        await handleAcceptPropoval()
      }
      queryClient.invalidateQueries()
    } catch (err: any) {
      dispatchToast({
        type: "error",
        content: "Algo aconteceu ao enviar a carta proposta",
      })
    }
  }

  const formik = useFormik({
    initialValues: {
      aboutCompany: card.candidate.proposalLetterInfo?.aboutCompany || [],
      benefits: card.candidate.proposalLetterInfo?.benefits || [],
      assignments: card.candidate.proposalLetterInfo?.assignments || "",
      role: card.candidate.proposalLetterInfo?.role,
      group: card.candidate.proposalLetterInfo?.group,
      department: card.candidate.proposalLetterInfo?.department,
      hiringType: card.candidate.proposalLetterInfo?.hiringType || null,
      salary: formatCurrency(card.candidate.proposalLetterInfo?.salary || ""),
      variableSalary: card.candidate.proposalLetterInfo?.variableSalary || "",
      hiringDate: card.candidate.proposalLetterInfo?.hiringDate,
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: any = {}

      const date = values?.hiringDate ? dayjs(values?.hiringDate) : null
      if (!date) errors.hiringDate = "Campo obrigatório/inválido"

      return errors
    },
    onSubmit: () => {},
  })

  const handleSubmit = async (eventName: string) => {
    tracking.trackEvent({
      name: eventName,
    })
    setIsSubmiting(true)
    await formik.handleSubmit()
    await limitDateRef.current?.submit()
    if (!probationPeriodRef.current) throw new Error("Form not defined")
    const probationPeriodErrors =
      await probationPeriodRef?.current?.validateForm()
    if (
      !formik.isValid ||
      !limitDateRef.current?.isValid ||
      Object.keys(probationPeriodErrors).length > 0
    ) {
      dispatchToast({
        content: "Verifique os campos do formulário",
        type: "error",
      })
      setIsSubmiting(false)
      return
    }

    await formSubmit()
    queryClient.invalidateQueries()
    setIsSubmiting(false)
  }

  const renderPreviewComponent = (fieldName) => {
    return card.attachments?.proposedLetter?.[fieldName]?.map(
      ({ key = "", value = "", path = "" }, index) => {
        const lastIndexName = key ? key.lastIndexOf("/") : ""
        const nameOfArchive =
          key && lastIndexName
            ? removeDropzoneTimestamp(key.substring(lastIndexName + 1))
            : "Nome não identificado"

        const filteredTemplate = card.attachments?.proposedLetter?.[
          fieldName
        ]?.filter((c) => c.path !== path)

        const removeValueTemplate =
          filteredTemplate?.map((f) => {
            return { key: f.key, path: f.path, type: f.type }
          }) || []

        return (
          <div key={index} style={{ marginTop: "24px" }}>
            {loadingOpenRemoveFile?.loading &&
            loadingOpenRemoveFile?.key === key ? (
              <Skeleton variant="rectangular" width="100%" height="114px" />
            ) : (
              <div
                role="presentation"
                onClick={async (e) => {
                  e.stopPropagation()
                }}
              >
                <UploadPreview>
                  <UploadPreviewArea>
                    <UploadIconContainer>
                      <Icons
                        name={"IconFile"}
                        style={{ fill: "transparent" }}
                      />
                    </UploadIconContainer>
                    <UploadFileName>
                      <StyledPOneLine numberOfLines={1}>
                        {nameOfArchive}
                      </StyledPOneLine>
                    </UploadFileName>
                  </UploadPreviewArea>

                  <div style={{ display: "flex" }}>
                    <IconButton
                      size="medium"
                      variant="filled"
                      icon="IconTrash"
                      onClick={async (e) => {
                        e.stopPropagation()
                        e.preventDefault()

                        const filteredAbout =
                          card.attachments?.proposedLetter?.aboutCompany?.map(
                            (a) => {
                              return {
                                key: a.key,
                                path: a.path,
                                type: a.type,
                              }
                            },
                          ) || []

                        const filteredBenefit =
                          card.attachments?.proposedLetter?.benefit?.map(
                            (a) => {
                              return {
                                key: a.key,
                                path: a.path,
                                type: a.type,
                              }
                            },
                          ) || []

                        let res = {
                          ...card.attachments,
                        }

                        if (fieldName === "aboutCompany") {
                          res = {
                            ...res,
                            proposedLetter: {
                              aboutCompany: removeValueTemplate,
                              benefit: filteredBenefit,
                            },
                          }
                        }

                        if (fieldName === "benefit") {
                          res = {
                            ...res,
                            proposedLetter: {
                              aboutCompany: filteredAbout,
                              benefit: removeValueTemplate,
                            },
                          }
                        }

                        setLoadingOpenRemoveFile({ loading: true, key: key })

                        await updateCard(
                          {
                            attachments: res,
                            flowCardId: card._id,
                          },
                          {
                            onSuccess: () => {
                              queryClient.invalidateQueries()
                            },
                            onError: () => setDropZoneKey(!dropZoneKey),
                          },
                        )

                        setLoadingOpenRemoveFile({ loading: false, key: key })
                      }}
                    />

                    <IconButton
                      size="medium"
                      variant="filled"
                      icon="IconDownload"
                      onClick={async () => {
                        setLoadingOpenRemoveFile({ loading: true, key: key })

                        const readFile = await fetch(value)
                        const fileToArrayBuffer = await readFile.arrayBuffer()
                        const res = URL.createObjectURL(
                          new Blob([fileToArrayBuffer]),
                        )
                        const a = document.createElement("a")
                        a.href = res
                        a.download = nameOfArchive
                        a.click()

                        setLoadingOpenRemoveFile({ loading: false, key: key })
                      }}
                      style={{ marginLeft: "10px" }}
                    />
                  </div>
                </UploadPreview>
              </div>
            )}
          </div>
        )
      },
    )
  }

  const handleRemoveFile = (file, fieldName) => {
    const files = [...formik.values[fieldName]]
    const indexToBeRemoved = files.findIndex(
      (fileFromArray) => fileFromArray.key === file.key,
    )
    if (indexToBeRemoved === -1) {
      dispatchToast({
        content: "Algo ocorreu ao remover o arquivo",
        type: "error",
      })
      return
    }

    files.splice(indexToBeRemoved, 1)
    formik.handleChange({
      target: { name: fieldName, value: files },
    })
  }

  if (isLoadingAllModelProposedLetters) {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
        <Skeleton variant="rectangular" width={"100%"} height={200} />
        <Skeleton variant="rectangular" width={"100%"} height={400} />
      </div>
    )
  }

  return (
    <ContentContainer>
      <SubContainer>
        <HiringLimitDate
          ref={limitDateRef}
          cardId={card._id}
          step={HiringLimitDateEnum.proposalLetter_Pj}
          limitDate={card.limitDates?.find(
            (limitDate) =>
              limitDate.step === HiringLimitDateEnum.proposalLetter_Pj,
          )}
          style={{
            paddingBottom: "24px",
            borderBottom: "1px solid #EBE6E9",
          }}
        />

        <Title
          style={{
            fontSize: "20px",
            lineHeight: "24px",
            color: "#53464F",
            marginTop: "40px",
          }}
        >
          Selecionar modelo
        </Title>
        <Typography
          variant="body4"
          style={{ fontWeight: 400, color: "#83727D", marginBottom: "24px" }}
        >
          Escolha um modelo e agilize o preenchimento dos campos da proposta.
        </Typography>
        <MultiSelectContainer>
          <SelectField
            label="Selecionar modelo"
            value={proposedLetterModel}
            onSelectChange={(e, { value }) => {
              tracking.trackEvent({
                name: "company_hiring_pj_proposal_selectmodel_clicked",
              })

              setProposedLetterModel(value)
            }}
            options={listModelProposedLetter
              ?.filter((m) => m.status === "published")
              .map((m) => {
                return { label: m.name, value: m._id }
              })}
            id={"proposedLetterModel"}
            name={"proposedLetterModel"}
            placeholder={"Selecionar modelo"}
            autoFocus={false}
            fullWidth
          />

          <StyledLinkButton
            variant="primary"
            onClick={() => {
              window.open(
                `/flows/settings/${card.flowId}/proposedLetter`,
                "_blank",
              )
            }}
          >
            Gerenciar modelos de carta proposta
          </StyledLinkButton>
        </MultiSelectContainer>
        <Banner
          icon="IconInfoCircle"
          type="info"
          title={"Editar campos"}
          message={
            "Ao selecionar um modelo, os campos de carta proposta serão preenchidos com as informações configuradas, porém você ainda poderá edita-las manualmente."
          }
          hasHideBanner={false}
          style={{ marginTop: "24px", marginBottom: "40px" }}
        />
        {isLoadingModelProposedLetter ? (
          <Skeleton variant="rectangular" width={"100%"} height={400} />
        ) : (
          <>
            <div>
              <LineText variant="body3" tag="p" style={{ color: "#83727D" }}>
                ou faça o preenchimento manual
              </LineText>
            </div>
            <div
              style={{
                paddingTop: "24px",
                paddingBottom: "24px",
                borderBottom: "1px solid #EBE6E9",
              }}
            >
              <Title
                style={{
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#53464F",
                }}
              >
                Sobre a empresa
              </Title>
              <Typography
                variant="body4"
                style={{ fontWeight: 400, color: "#83727D" }}
              >
                Inclua materiais de apresentação para que o candidato conheça
                melhor a empresa.
              </Typography>
              {renderPreviewComponent("aboutCompany")}
              <div style={{ marginTop: "24px" }}>
                <UploadMultiples
                  values={formik.values.aboutCompany || null}
                  label={"Sobre a empresa"}
                  accept={["jpg", "png", "pdf"]}
                  folder={`${card.companyId}/modelProposedLetter/${proposedLetterModel}/aboutCompany`}
                  onUpload={async (file) => {
                    tracking.trackEvent({
                      name: "company_hiring_pj_fileupload_about_clicked",
                    })

                    formik.handleChange({
                      target: {
                        name: "aboutCompany",
                        value: [...formik.values.aboutCompany, file],
                      },
                    })
                    await updateFieldGlobally({
                      values: {
                        proposalLetterInfo: {
                          aboutCompany: [...formik.values.aboutCompany, file],
                        },
                      },
                    })
                  }}
                  onRemove={async (file) => {
                    handleRemoveFile(file, "aboutCompany")
                  }}
                />
              </div>
            </div>

            <FlexSpaceBetween>
              <Title
                style={{
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#53464F",
                  marginTop: "24px",
                }}
              >
                {t("proposalContent.title")}
              </Title>
            </FlexSpaceBetween>
            <Typography
              variant="body4"
              style={{
                color: "#83727D",
                fontWeight: 400,
                marginBottom: "24px",
              }}
            >
              {t("proposalLetter.bidField")}
            </Typography>

            <SearchField
              style={{ marginBottom: "24px" }}
              label={"Cargo"}
              onSearchChange={(_, option) => {
                const value = { _id: option.value, name: option.label }
                formik.handleChange({
                  target: { name: "role", value },
                })
              }}
              value={
                formik.values.role
                  ? {
                      label: formik.values.role.name,
                      value: formik.values.role._id,
                    }
                  : null
              }
              loading={!roles || isRolesLoading}
              options={
                roles
                  ? roles.map((role) => ({
                      label: role.name,
                      value: role.id,
                    }))
                  : []
              }
              error={formik.touched.role && Boolean(formik.errors.role)}
              helperText={
                formik.touched.role && Boolean(formik.errors.role)
                  ? formik.errors.role?.toString()
                  : undefined
              }
              onBlur={async (e) => {
                await updateFieldGlobally({
                  values: {
                    proposalLetterInfo: {
                      role: formik.values.role,
                    },
                  },
                })
              }}
            />

            <SearchField
              style={{ marginBottom: "24px" }}
              label={"Departamento"}
              onSearchChange={(_, option) => {
                const value = { _id: option.value, name: option.label }
                formik.handleChange({
                  target: { name: "department", value },
                })
              }}
              value={
                formik.values.department
                  ? {
                      label: formik.values.department.name,
                      value: formik.values.department._id,
                    }
                  : null
              }
              loading={!departments || isDepartmentsLoading}
              options={
                departments
                  ? departments.map((department) => ({
                      label: department.name,
                      value: department.id,
                    }))
                  : []
              }
              error={
                formik.touched.department && Boolean(formik.errors.department)
              }
              helperText={
                formik.touched.department && Boolean(formik.errors.department)
                  ? formik.errors.department?.toString()
                  : undefined
              }
              onBlur={async (e) => {
                await updateFieldGlobally({
                  values: {
                    proposalLetterInfo: {
                      department: formik.values.department,
                    },
                  },
                })
              }}
            />

            <SearchField
              style={{ marginBottom: "24px" }}
              label={"Grupo"}
              onSearchChange={(_, option) => {
                const value = { _id: option.value, name: option.label }
                formik.handleChange({
                  target: { name: "group", value },
                })
              }}
              value={
                formik.values.group
                  ? {
                      label: formik.values.group.name,
                      value: formik.values.group._id,
                    }
                  : null
              }
              loading={!groups || isGroupsLoading}
              options={
                groups
                  ? groups.map((group) => ({
                      label: group.name,
                      value: group.id,
                    }))
                  : []
              }
              error={formik.touched.group && Boolean(formik.errors.group)}
              helperText={
                formik.touched.group && Boolean(formik.errors.group)
                  ? formik.errors.group?.toString()
                  : undefined
              }
              onBlur={async (e) => {
                await updateFieldGlobally({
                  values: {
                    proposalLetterInfo: {
                      group: formik.values.group,
                    },
                  },
                })
              }}
            />

            <div style={{ marginBottom: "24px" }}>
              <TextArea
                maxLength={10000}
                placeholder={"Atribuições e responsabilidades"}
                value={formik.values.assignments}
                onChange={(e: any) => {
                  const value = e.target.value
                  formik.handleChange({
                    target: { name: "assignments", value },
                  })
                }}
                onBlur={async (e) => {
                  await updateFieldGlobally({
                    values: {
                      proposalLetterInfo: {
                        assignments: formik.values.assignments,
                      },
                    },
                  })
                }}
              />
              {formik.touched.assignments && !!formik.errors.assignments ? (
                <StyledTextError>
                  <Icons fill="transparent" name="IconInfoCircle" size={18} />
                  {formik.errors.assignments}
                </StyledTextError>
              ) : (
                <></>
              )}
            </div>

            <div style={{ marginBottom: "24px" }}>
              <SelectField
                id={"hiringType"}
                name={"hiringType"}
                label={"Tipo de vínculo"}
                placeholder={"Selecione o tipo de vínculo"}
                value={formik.values.hiringType}
                options={[{ label: "PJ", value: "pj" }]}
                autoFocus={false}
                onSelectChange={async (_, { value }) => {
                  formik.handleChange({
                    target: { id: "hiringType", value },
                  })
                  await updateFieldGlobally({
                    values: {
                      proposalLetterInfo: { hiringType: value },
                    },
                  })
                }}
                error={
                  formik?.touched?.hiringType &&
                  Boolean(formik?.errors?.hiringType)
                }
                helperText={
                  formik?.touched?.hiringType && formik?.errors?.hiringType
                }
                fullWidth
              />
            </div>

            <div style={{ marginBottom: "24px" }}>
              <TextField
                id={"salary"}
                name={"salary"}
                label={"Valor a receber"}
                placeholder={"Digite o valor a receber"}
                value={formik.values.salary}
                onChange={(event: any) => {
                  event.target.value = currencyParser(event.target.value)

                  if (event.target.value === "R$0,00") {
                    event.target.value = ""
                    formik.handleChange({
                      target: { id: "salary", value: "" },
                    })
                    return
                  }

                  formik.handleChange({
                    target: { id: "salary", value: event.target.value },
                  })
                }}
                onBlur={async (e) => {
                  const parsedNumber = e.target.value
                    .replace(/[R$]|[.]/g, "")
                    .replace(",", ".")

                  const value = parsedNumber == "R$0,00" ? null : parsedNumber

                  if (value)
                    await updateFieldGlobally({
                      values: {
                        proposalLetterInfo: { salary: +value },
                      },
                    })
                }}
                error={
                  formik?.touched?.salary && Boolean(formik?.errors?.salary)
                }
                helperText={
                  formik?.touched?.salary && formik?.errors?.salary?.toString()
                }
                fullWidth
              />
            </div>

            <div style={{ marginBottom: "24px" }}>
              <TextArea
                maxLength={1000}
                placeholder={"Remuneração Variável"}
                value={formik.values.variableSalary}
                onChange={(e: any) => {
                  const value = e.target.value
                  formik.handleChange({
                    target: { name: "variableSalary", value },
                  })
                }}
                onBlur={async (e) => {
                  await updateFieldGlobally({
                    values: {
                      proposalLetterInfo: {
                        variableSalary: formik.values.variableSalary,
                      },
                    },
                  })
                }}
              />
              {formik.touched.variableSalary &&
              !!formik.errors.variableSalary ? (
                <StyledTextError>
                  <Icons fill="transparent" name="IconInfoCircle" size={18} />
                  {formik.errors.variableSalary}
                </StyledTextError>
              ) : (
                <></>
              )}
            </div>

            <div style={{ marginBottom: "24px" }}>
              <DatePicker
                id={"hiringDate"}
                name={"hiringDate"}
                label={"Data prevista de início"}
                placeholder={"Digite a data prevista de início"}
                value={formik.values.hiringDate}
                onDateChange={async (value) => {
                  const date =
                    value && dayjs(value).isValid() ? dayjs(value) : null
                  if (!date) return
                  const parsedValue = dayjs(value).isValid()
                    ? date?.toISOString()
                    : null

                  formik.handleChange({
                    target: { id: "hiringDate", value: parsedValue },
                  })

                  const currentHiringDate = card?.candidate?.proposalLetterInfo
                    ?.hiringDate
                    ? dayjs(
                        card?.candidate?.proposalLetterInfo?.hiringDate,
                      ).toISOString()
                    : null
                  if (!date || date?.toISOString() === currentHiringDate) return

                  await updateFieldGlobally({
                    values: {
                      proposalLetterInfo: {
                        hiringDate: parsedValue ?? undefined,
                      },
                    },
                  })
                }}
                error={
                  formik?.touched?.hiringDate &&
                  Boolean(formik?.errors?.hiringDate)
                }
                helperText={
                  formik?.touched?.hiringDate &&
                  formik?.errors?.hiringDate?.toString()
                }
                fullWidth
              />
            </div>

            <ProbationaryPeriod
              ref={probationPeriodRef}
              hiringDate={formik.values.hiringDate}
              value={card.candidate.probationPeriod}
              onChangeCallback={(values) => {
                updateFieldGlobally({
                  values: {
                    probationPeriod: values,
                  },
                })
              }}
            />

            <div
              style={{
                paddingTop: "24px",
                borderTop: "1px solid #EBE6E9",
              }}
            >
              <Title
                style={{
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#53464F",
                }}
              >
                Benefícios
              </Title>
              <Typography
                variant="body4"
                style={{ fontWeight: 400, color: "#83727D" }}
              >
                Inclua materiais sobre os benefícios oferecidos e deixe sua
                carta proposta mais atrativa.
              </Typography>
              {renderPreviewComponent("benefit")}
              <div style={{ marginTop: "24px" }}>
                <UploadMultiples
                  values={formik.values.benefits || null}
                  label={"Benefícios"}
                  accept={["jpg", "png", "pdf"]}
                  folder={`${card.companyId}/modelProposedLetter/${proposedLetterModel}/benefits`}
                  onUpload={async (file) => {
                    tracking.trackEvent({
                      name: "company_hiring_pj_fileupload_benefits_clicked",
                    })

                    formik.handleChange({
                      target: {
                        name: "benefits",
                        value: [...formik.values.benefits, file],
                      },
                    })
                    await updateFieldGlobally({
                      values: {
                        proposalLetterInfo: {
                          benefits: [...formik.values.benefits, file],
                        },
                      },
                    })
                  }}
                  onRemove={async (file) => {
                    handleRemoveFile(file, "benefits")
                  }}
                />
              </div>
            </div>

            {!sendToCandidate ? (
              <ButtonContainer
                style={{
                  marginTop: "40px",
                }}
              >
                <StyledContainedButton
                  type={"button"}
                  onClick={() => setHasRefused(true)}
                  disabled={isSubmiting}
                >
                  <span>{t("proposalLetter.bidDeclined")}</span>
                </StyledContainedButton>
                <Button
                  variant="primary"
                  size="large"
                  type={"submit"}
                  disabled={isSubmiting}
                  onClick={async () => {
                    await handleSubmit(
                      "company_hiring_pj_acceptproposal_clicked",
                    )
                  }}
                >
                  {isSubmiting ? (
                    <Loader size="small" variant="primary" />
                  ) : (
                    <>
                      <span>{t("proposalLetter.bidAccepted")}</span>
                    </>
                  )}
                </Button>
              </ButtonContainer>
            ) : (
              <ButtonContainer style={{ justifyContent: "flex-end" }}>
                <Button
                  variant="primary"
                  size="large"
                  type={"submit"}
                  style={{
                    marginBottom: "20px",
                    marginTop: "20px",
                    flexDirection: "row",
                  }}
                  disabled={isSubmiting || !!isHiringCardQueryFetching}
                  onClick={() => setConfirmationSendModal(true)}
                >
                  {isSubmiting ? (
                    <Loader size="small" variant="primary" />
                  ) : (
                    <>
                      <span>{t("proposalContent.buttonLabel")}</span>
                      <Icons
                        name="IconArrowRight"
                        fill="none"
                        style={{ color: "#fff" }}
                      />
                    </>
                  )}
                </Button>

                <ConfirmationModal
                  variant="primary"
                  isOpen={confirmationSendModal}
                  onClose={() => setConfirmationSendModal(false)}
                  title="Tem certeza que deseja enviar a carta proposta?"
                  description="Verifique se todas as informações foram preenchidas corretamente."
                  confirmButton={{
                    onClick: () =>
                      handleSubmit("company_hiring_pj_sendproposal_clicked"),
                    isLoading: isSubmiting,
                    text: "Prosseguir e enviar",
                    icon: "IconArrowRight",
                  }}
                />
              </ButtonContainer>
            )}
          </>
        )}
      </SubContainer>
    </ContentContainer>
  )
}

export default PJProposedContent
