import { useRef, useCallback, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import { Grid } from "@mui/material"

import { SettingPageTemplate } from "../../../../components/common/SettingPageTemplate"
import { WithoutSaveModal } from "../../WithoutSaveModal"
import { DraftSaveModal } from "../../DraftSaveModal"

import { Header, FormBasicInfo, FormBasicHandle } from "./components"

import dispatchToast from "../../../../utils/dispatchToast"

import {
  MainContainer,
  StyledSubtitle,
  StyledDescription,
  FormWrapper,
} from "./styles"

import { trpc } from "@api/client"

interface BasicInfoProps {
  data: any
  isFetching: boolean
}

export const BasicInfo = ({ data, isFetching }: BasicInfoProps) => {
  const [withoutSaveModelOpen, setWithoutSaveModelOpen] = useState(false)
  const [draftSaveModelOpen, setDraftSaveModelOpen] = useState(false)

  const formRef = useRef<FormBasicHandle>(null)

  const navigate = useNavigate()

  const { modelId, flowId = "" } = useParams<{
    modelId?: string
    flowId?: string
  }>()

  const isEdit = useMemo(() => !!modelId, [modelId])

  const { mutateAsync: createModelContract, isLoading: createModelLoading } =
    trpc.contract.create.useMutation({
      onSuccess: (data, variables) => {
        const modelContractId = data._id || ""
        if (variables.origin === "draft")
          return navigate(`/flows/settings/${flowId}/contracts`)

        navigate(
          `/flows/settings/${flowId}/contracts/edit/contract-content/${modelContractId}`,
        )
      },
      onError: (error) => {
        const errorCode = error.data?.code

        let errorMessage =
          "Ocorreu um erro ao criar o modelo de contrato, favor tentar novamente!"

        if (errorCode === "MODEL_CONTRACT_ALREADY_EXISTS_ERROR") {
          errorMessage = `Você já criou um modelo de contrato com esse nome!`
        }

        dispatchToast({ type: "error", content: errorMessage })
      },
    })

  const { mutateAsync: editModelContract, isLoading: editModelLoading } =
    trpc.contract.edit.useMutation({
      onSuccess: (_, variables) => {
        if (variables.origin === "draft")
          return navigate(`/flows/settings/${flowId}/contracts`)

        navigate(
          `/flows/settings/${flowId}/contracts/edit/contract-content/${modelId}`,
        )
      },
      onError: (error) => {
        const errorCode = error.data?.code

        let errorMessage =
          "Ocorreu um erro ao editar o modelo de contrato, favor tentar novamente!"

        if (errorCode === "MODEL_CONTRACT_ALREADY_EXISTS_ERROR") {
          errorMessage = `Você já criou um modelo de contrato com esse nome!`
        }

        dispatchToast({ type: "error", content: errorMessage })
      },
    })

  const handleSubmit = useCallback(
    (formValues: { name: string; description: string }) => {
      const persistedData = data

      if (!modelId) {
        createModelContract({
          ...formValues,
          flowId,
          origin: "new",
        })

        return
      }

      if (
        persistedData.name !== formValues.name ||
        persistedData.description !== formValues.description
      ) {
        editModelContract({
          ...formValues,
          modelContractId: modelId,
          origin: "new",
        })

        return
      }

      return navigate(
        `/flows/settings/${flowId}/contracts/edit/contract-content/${modelId}`,
      )
    },
    [modelId, flowId, data],
  )

  return (
    <SettingPageTemplate
      stepper={{
        steps: ["Informações básicas", "Contrato"],
        activeStep: 0,
      }}
      routes={[
        {
          label: "Configurações",
          route: `/flows/settings/${flowId}/contracts`,
        },
        {
          label: `${isEdit ? "Editar" : "Criar novo"} modelo de contrato`,
        },
      ]}
      footer={{
        cancelProps: {
          title: "Sair sem salvar",
          callback: () => setWithoutSaveModelOpen(true),
        },
        draftProps: {
          title: "Sair e salvar rascunho",
          disabled:
            (data?.status && data?.status !== "draft") ||
            createModelLoading ||
            editModelLoading,
          callback: async () => {
            const validatePromise = await formRef?.current?.validateForm()

            if (validatePromise && Object.keys(validatePromise).length) {
              const firstErrorElement = Object.keys(validatePromise)[0]

              formRef?.current?.setFieldErrors({
                [firstErrorElement]: validatePromise[firstErrorElement],
              })

              return
            }

            setDraftSaveModelOpen(true)
          },
        },
        confirmProps: {
          title: (
            <>
              Continuar
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          loading: createModelLoading || editModelLoading,
          disabled: createModelLoading || editModelLoading,
          callback: () => {
            formRef?.current?.handleSubmit()
          },
        },
      }}
    >
      <MainContainer>
        <Header isEdit={isEdit} />
        <Grid container spacing={2}>
          <Grid item sm={12} md={5} lg={4}>
            <StyledSubtitle variant="headline8">
              Informações básicas
            </StyledSubtitle>
            <StyledDescription variant="body3">
              Escolha um nome e descreva as características e objetivos do
              contrato que você está criando.
            </StyledDescription>
          </Grid>
          <Grid item sm={12} md={7} lg={8}>
            <FormWrapper>
              <FormBasicInfo
                ref={formRef}
                isEdit={isEdit}
                data={data}
                onSubmit={handleSubmit}
                loading={isFetching}
                readOnly={false}
              />
            </FormWrapper>
          </Grid>
        </Grid>
      </MainContainer>
      <WithoutSaveModal
        isOpen={withoutSaveModelOpen}
        onClose={() => setWithoutSaveModelOpen(false)}
        onSubmit={() => navigate(`/flows/settings/${flowId}/contracts`)}
      />
      <DraftSaveModal
        isOpen={draftSaveModelOpen}
        onClose={() => setDraftSaveModelOpen(false)}
        isLoading={createModelLoading || editModelLoading}
        onSubmit={async () => {
          const values = data
          const currentValues = formRef?.current?.getValue()

          if (!currentValues) return

          if (!modelId) {
            createModelContract({
              ...currentValues,
              flowId,
              origin: "draft",
            })
            return
          }

          if (
            values.name !== currentValues.name ||
            values.description !== currentValues.description
          ) {
            editModelContract({
              ...currentValues,
              modelContractId: modelId,
              origin: "draft",
            })
            return
          }

          return navigate(`/flows/settings/${flowId}/contracts`)
        }}
      />
    </SettingPageTemplate>
  )
}
