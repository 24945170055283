export default {
  translations: {
    page: {
      hiring: {
        signature: {
          formValidation: {
            missingCompanyEmployeeEmail:
              "Colaborador(a) não possui email cadastrado.",
            missingWitnessEmail: "Testemunha não possui email cadastrado.",
          },
          messages: {
            deleteSuccessfull: "Contrato de assinatura excluído com sucesso!",
            fileUploadFailure: "Algo aconteceu ao fazer o upload do arquivo.",
            genericSignatureFailure:
              "Ocorreu um problema ao enviar o contrato.",
            genericContractFailure: "Ocorreu um erro no contrato.",
            invalidContractModel:
              "Houve um problema ao validar modelo de contrato",
            invalidContractData:
              "Falha ao validar os dados do contrato. Entre em contato com o suporte.",
            missingCompanyEmployee:
              "Por favor, selecione um colaborador da empresa",
            missingCompanyEmployeeEmail:
              "Colaborador(a) {{employeeName}} não possui email cadastrado. Atualize seu cadastro pelo menu Equipe",
            missingModelContractFields:
              "Alguns camposs do contrato não foram preenchidos. Edite os dados para prosseguir com o envio do contrato.",
            missingInstitutionForm:
              "Por favor, preencha o tipo de assinatura da instituição de ensino",
            missinInstitutionData:
              "Por favor, preencha os campos da instituição de ensino",
            missingLimitDate: "Por favor preencha informações de prazo limite",
            missingWitnessData:
              "Por favor, preencha todos os dados da testemunha",
            missingWitnessEmail:
              "Testemunha {{employeeName}} não possui email cadastrado. Atualize seu cadastro pelo menu Equipe",
            missingModelOrTemplate:
              "Por favor, insira ou um modelo de contrato ou faça o upload de um contrato",
            resendSignatureFailure:
              "Ocorreu um problema ao reenviar o contrato.",
            resetFailure:
              "Ocorreu um problema ao reiniciar a etapa. Tente novamente mais tarde!",
          },
        },
        admissionalExam: {
          bannerTitle: "Solicitação enviada",
          bannerDescription:
            "Você pode dar seguimento com o processo de contratação e passar o candidato para a próxima etapa sem ter que aguardar o envio do anexo.",
          dateLimitTitle: "Prazo para realizar o exame",
          dateLimitDescription: "Inclua o prazo para a realização do exame",
          dateLimitLabel: "Data limite",
          guideTitle: "Guia do exame",
          guideDescription:
            "Faça o upload da guia do atestado de saúde ocupacional.",
          guideLabel: "Guia do exame",
          asoTitle: "Anexo do ASO",
          asoDescription:
            "Acompanhe o status do anexo e certifique-se de validar se o documento está legível e se nenhuma informação foi cortada da foto.",
          asoStatus: "Aguardando anexo do ASO",
          asoExamDate: "Data do exame",
          asoApproveButton: "Aprovar documento",
          asoReproveButton: "Reprovar documento",
          textAreaRefuseLabel: "Descreva o motivo da reprovação",
          asoCanWorkDescription:
            "Marque se a pessoa está apta ou inapta para o serviço de acordo com o resultado do exame:",
          asoCanWorkLabel: "Apto",
          asoCannotWorkLabel: "Inapto",
          buttonSendRequest: "Enviar solicitação do ASO ao candidato",
          buttonResendRequest: "Reenviar solicitação",
          toastFieldsNotFilled: "Por favor verifique os campos do formulário",
          toastResetStep: "Etapa reiniciada com sucesso!",
          toastSendRequest: "Exame admissional enviado com sucesso!",
          toastRefuseReason: "Descreva o motivo da recusa antes de reenviar.",
          toastResendSuccess: "Exame admissional reenviado com sucesso",
          formValidationError: "Campo obrigatório",
        },
        candidate: {
          messages: {
            editSuccessfull: "Dados do candidato atualizados com sucesso!",
            editFailure: "Houve um problema ao atualizar os dados do candidato",
          },
        },
      },
      resignation: {
        exam: {
          resetSuccessMessage: "Exame demissional resetado com sucesso!",
          requiredFieldError: "Campo obrigatório",
          formInvalidError: "Por favor verifique os campos do formulário",
          refuseReasonError: "Descreva um motivo de recusa",
          options: {
            noApply: {
              title: "Exame não se aplica",
              description:
                "Marque esta opção caso não seja necessário realizar o exame demissional",
            },
            examGuide: {
              title: "Guia do exame",
              description:
                "Faça o upload da guia do atestado de saúde ocupacional.",
            },
            alreadyHave: {
              title: "Já tenho o ASO",
              description:
                "Faça o upload direto do atestado de saúde ocupacional.",
            },
          },
          examGuide: {
            title: "Guia do exame",
            description:
              "Faça o upload da guia do atestado de saúde ocupacional.",
            uploadLabel: "Guia do exame",
            limitExamDateTitle: "Prazo para realizar o exame",
            limitExamDateDescription:
              "Inclua o prazo para a realização do exame",
            limitExamDateLabel: "Data limite",
            submitButtonLabel: "Enviar solicitação do ASO à pessoa",
            limitDateDescription:
              "Defina uma data limite para a pessoa dar uma resposta.",
            requestSent: "Solicitação enviada!",
            requestSentDescription:
              "Agora é só aguardar o anexo da ASO. Confira abaixo o agendamento encaminhado.",
            asoTitle: "Anexo da ASO",
            asoDescription:
              "Acompanhe o status do anexo e certifique-se de validar se o documento está legível e se nenhuma informação foi cortada da foto.",
            asoStatus: "Aguardando anexo da ASO",
          },
          validation: {
            successToast: "Exame demissional reenviado com sucesso!",
            approveLabel: "Aprovar documento",
            approveDescription:
              "Marque se a pessoa está apta ou inapta para o serviço de acordo com o resultado do exame:",
            radioAbleToWorkLabel: "Apto",
            radioNotAbleToWorkLabel: "Inapto",
            reproveLabel: "Reprovar documento",
            textAreaPlaceholder: "Descreva o motivo da reprovação",
            resendButtonText: "Reenviar solicitação",
          },
          manualAso: {
            title: "Anexo da ASO",
            description:
              "Faça o upload direto do atestado de saúde ocupacional.",
            uploadLabel: "ASO",
            dateTitle: "Data do exame",
            dateDescription: "Inclua a data que o exame foi realizado.",
            inputDateLabel: "Data do exame",
          },
        },
      },
      home: {
        modalMovePage: {
          title: "Mover candidatos para outra etapa",
          description:
            "Selecione a etapa para qual os candidatos selecionados irão se mover",
          button: {
            cancel: "Cancelar",
            submit: "Prosseguir",
          },
          selectLabel: "Selecionar etapa",
        },
        modalConfirmArchive: {
          title: "Tem certeza que deseja arquivar este candidato?",
          description: "Você poderá inclui-los novamente no fluxo de admissão.",
          button: {
            cancel: "Cancelar",
            submit: "Prosseguir",
          },
        },
        options: {
          updateFlow: "Atualizar fluxo",
          batchActions: "Histórico de ações em lote",
          exportData: "Exportar relatórios",
          ariaLabelKanban: "Visualizar em Kanban",
          ariaLabelList: "Visualizar em Lista",
          searchField: "Buscar por nome, e-mail ou cpf",
        },
        linkToExpireLabel: "Prazos de retorno expirando hoje",
        tooltipToExpireLabel:
          "As pessoas com prazo de retorno expirando hoje estão sinalizadas no card.",
        linkExpiredLabel: "Prazo de retorno expirado",
        tooltipExpiredLabel: `As pessoas com prazo de retorno expirados estão sinalizadas no card.`,
      },
      tab: {
        titleLabel: "Admissão",
        buttonLabel: "Adicionar pessoa",
        admissionLabel: "Admissões",
        modelLabel: "Modelos",
        automationLabel: "Automações",
      },
      cpfForm: {
        title: "Por favor, digite o cpf para verificação",
        backbuttonLabel: "VOLTAR",
        sendbuttonLabel: "ENVIAR",
      },
      proposedLetter: {
        currentState: "Fase atual",
        titleSend: "Carta Proposta",
        subtitleSend:
          "Configure as condições da proposta, faça o envio e acompanhe o status da resposta do candidato",
        responsibleLabel: "Responsáveis",
        companionLabel: "Acompanhantes",
        sendProposalTitle: "Envio da proposta",
        sendProposalSubtitle:
          "O candidato irá receber o link de proposta via e-mail e realizar o aceite em uma página web. Visualize abaixo como vai ficar!",
        emailProposalTitle: "E-mail da proposta",
      },
      submissionContent: {
        previewButtonLabel: "Pré-visualize o e-mail",
        modelEmailLabel: "Modelo do e-mail de proposta",
        modelEmailPlaceholder: "Selecione o email",
        subjectEmailLabel: "Assunto do e-mail",
        subjectEmailPlaceholder: "Digite o assunto",
        copyEmailLabel: "Inserir pessoas em cópia",
        copyEmailPlaceholder: "Digite o email",
        attachmentEmailTitle: "Enviar anexo junto ao e-mail",
        attachmentEmailColorSubtitle: "Selecione o arquivo",
        attachmentEmailSubtitle: "arraste e solte o aqui.",
        scheduleEmailTitle: "Agendar envio do e-mail",
        scheduleEmailSubtitle: "Escolha dia e hora para disparo do e-mail.",
        proposalButtonLabel: "Enviar proposta",
      },
      personalInfo: {
        name: "Nome Completo",
        email: "E-mail",
        phone: "Telefone",
        cpf: "CPF",
        dateLabel: "Data prevista da admissão",
        probationaryPeriod: {
          noPeriodLabel: "Período de experência",
          firstPeriodLabel: "Fim do primeiro período de experiência",
          secondPeriodLabel: "Fim do segundo período de experiência",
          value: "{{initialDate}} até {{finalDate}}",
          noValue: "Sem período de experiência",
          toBeExpired: "Vencimento em {{days}} dias",
          expired: "Vencido",
        },
      },
      documentsSend: {
        currentState: "Fase atual",
        titleSend: "Envio de documentos",
        subtitleSend:
          "Faça a solicitação dos documentos de admissão do seu candidato",
        editButtonLabel: "Editar fase",
        responsibleLabel: "Responsáveis",
        companionLabel: "Acompanhantes",
        secondTitle: "Pedido de documentos",
        toggleTitle: "Solicitar documentos ao candidato",
        thirdTitle: "E-mail de solicitação",
        buttonText: "Solicitar documentos ao candidato",
        emptyStateText: "Selecione um dos modelos de documentação",
      },
      documentsList: {
        title: "Lista de documentos",
        documentLabel: "Modelo de documentação",
        documentPlaceholder: "Selecione o modelo",
        datesCandidateTitle: "Documentos do candidato",
        datesCandidateSubtitle:
          "Lista de documentos que serão solicitados ao candidato na admissão",
        datesDependentsTitle: "Documentos de dependentes",
        datesDependentsSubtitle:
          "Podem ser considerados dependentes cônjuges ou companheiros(as), filhos não emancipados de qualquer condição, menores de vinte e um anos ou pessoas com deficiência.",
        previewProposal: "Pré-visualize a proposta",
        previewButtonText:
          "Pré-visualize a página de solicitação de documentos",
      },
      documentsValidation: {
        currentState: "Fase atual",
        title: "Validação de documentos",
        subtitle: "Revise e aprove a documentação dos seus candidatos",
        responsibleLabel: "Responsáveis",
        companionLabel: "Acompanhantes",
        secondTitle: "Reenvio de documentos",
        toggleTitle: "Solicitar documentos ao candidato",
        thirdTitle: "E-mail de solicitação",
      },
      proposalContent: {
        title: "Conteúdo da proposta",
        buttonLabel: "Enviar Proposta",
        buttonManualLabel: "Proposta aceita",
      },
      settings: {
        proposedLetterModel: {
          footerLeave: "Sair sem salvar",
          footerLeaveAndSave: "Sair e salvar",
          footerLeaveAndSaveDraft: "Sair e salvar rascunho",
          footerContinue: "Continuar",
          footerSubmit: "Concluir",
          footerGoBack: "Voltar",
          tableDescription:
            "Crie seus modelos de carta proposta utilizando critérios como cargo ou departamento, e agilize o preenchimento de informações como tipo de vínculo, atribuições e responsabilidades, remuneração e benefícios.",
          createModelTitle: "Criar modelo de carta proposta",
          editModelTitle: "Editar modelo de carta proposta",
          basicDataTitle: "Informações básicas",
          basicDataDescription:
            "Escolha um nome e descreva as características e objetivos da carta proposta que você está criando.",
          preview: {
            title: "Confira todos os detalhes da sua nova proposta de trabalho",
            description:
              "Vem aí o match perfeito? Estamos na torcida que sim! 🌟 Aqui você encontra todos os detalhes da sua proposta de trabalho. Em caso de dúvidas, procure o RH.",
            aboutCompanyLabel: "Sobre a empresa",
            benefitsLabel: "Benefícios",
            roleLabel: "Cargo",
            departmentLabel: "Departamento",
            groupLabel: "Grupo",
            flowTypeLabel: "Tipo de vínculo",
            assignmentsLabel: "Atribuições e responsabilidades",
            salaryLabel: "Remuneração fixa",
            variableSalaryLabel: "Remuneração variável",
            workloadLabel: "Carga horária",
            workshipLabel: "Período de trabalho",
          },
          basicDataForm: {
            title: "Nome e descrição",
            description:
              "Crie seus modelos de carta proposta utilizando critérios como cargo, departamento, filial, etc...",
            requiredFieldSpan: "campo obrigatório",
            inputName: "Nome do modelo",
            textareaDescription: "Descrição",
          },
          proposedInfoTitle: "Conteúdo da carta proposta",
          proposedInfoDescription:
            "Configure os campos do modelo para agilizar o preenchimento das informações da carta proposta dos candidatos.",
          proposedInfoPreviewLabel: "Pré-visualizar modelo",
          proposedInfoForm: {
            bannerTitle: "Preenchimento dos campos",
            bannerDescription:
              "Você pode optar por deixar um ou mais campos do modelo em branco, e preenche-los quando estiver na etapa de carta proposta de cada candidato.",
            aboutCompany: {
              title: "Sobre a empresa",
              description:
                "Inclua materiais de apresentação para que o candidato conheça melhor a empresa.",
              label: "Sobre a empresa",
            },
            errorHelperText: "Por favor preencha o campo acima",
            content: {
              title: "Conteúdo da proposta",
              description:
                "Preencha os campos abaixo de acordo com a oferta que será realizada à pessoa",
              whatIsVariableSalaryTitle: "O que é remuneração variável",
              whatIsVariableSalaryDescription:
                "Descreva os detalhes da remuneração variável, podendo ela ser: recompensa; bônus; comissão de vendas; PPR/PPL (Programa de Participação nos Resultados e Lucros); campanha de incentivos, participações acionárias, ou outros formatos de remuneração.",
              dontHaveVariableSalaryLabel: "Não possui remuneração variável",
              roleLabel: "Cargo",
              departmentLabel: "Departamento",
              groupLabel: "Grupo",
              assignmentsLabel: "Atribuições e responsabilidades",
              salaryLabel: "Remuneração fixa",
              variableSalaryLabel: "Remuneração variável",
              workloadLabel: "Carga horária",
              workshipLabel: "Período de trabalho",
            },
            benefits: {
              title: "Benefícios",
              description:
                "Inclua materiais sobre os benefícios oferecidos e deixe sua carta proposta mais atrativa.",
              label: "Benefícios",
            },
          },
          modalLeave: {
            title: "Deseja sair sem salvar o modelo?",
            description: "Você perderá todas as configurações do modelo.",
            confirmButton: "Sair sem salvar",
            cancelButton: "Voltar",
          },
          modalLeaveAndSave: {
            title: "Deseja sair e salvar o progresso da criação do modelo?",
            description:
              "O conteúdo será salvo e você poderá editar mais tarde.",
            confirmButton: "Sair e salvar",
            descriptionDraft:
              "O conteúdo será salvo como rascunho e você poderá editar mais tarde.",
            confirmButtonDraft: "Sair e salvar rascunho",
            cancelButton: "Voltar",
          },
        },
        notification: {
          title: "Notificações de admissão",
          description:
            "Configure os gatilhos de envio de notificações durante todo o processo de admissão",
          movimentation: {
            title: "Movimentação de pessoas",
            description:
              "Notifique a movimentação de pessoas entre as etapas no fluxo de admissão",
            subtitle: "Configurações gerais",
            selectStep: "Selecionar etapas",
            notifyLeader: "Notificar líderes",
            notifyFollowers: "Notificar acompanhantes",
            values: {
              yes: "Sim",
              no: "Não",
            },
          },
          commentary: {
            title: "Novos comentários",
            description:
              "Notifique os novos comentários em todas as etapas no fluxo de admissão",
            subtitle: "Configurações gerais",
            notifyLeader: "Notificar líderes",
            notifyFollowers: "Notificar acompanhantes",
            notifyMentions: "Notificar mencionados",
            values: {
              yes: "Sim",
              no: "Não",
            },
            stepsLabel: "Etapas afetadas",
          },
          expiration: {
            title: "Expiração dos prazos de resposta",
            description:
              "Notifique os prazos de resposta que estão para expirar das pessoas do fluxo de admissão",
            subtitle: "Configurações gerais",
            notifyLeader: "Notificar líderes",
            notifyFollowers: "Notificar acompanhantes",
            notifyBeforeDeadline: "Notificar antes do prazo expirar",
            notifyOnDeadline: "Notificar assim que o prazo expirar",
            values: {
              yes: "Sim",
              no: "Não",
            },
            stepsLabel: "Etapas afetadas",
          },
          probationaryPeriod: {
            title: "Término do período de experiência",
            description:
              "Configure quem será notificado sobre o término da experiência dos colaboradores.",
            subtitle: "Configurações gerais",
            notifyLeader: "Notificar líderes",
            notifyFollowers: "Notificar acompanhantes",
            notifyBeforeDeadline:
              "Notificar antes do período de experiência expirar",
            notifyOnDeadline:
              "Notificar assim que o período de experiência expirar",
            values: {
              yes: "Sim",
              no: "Não",
            },
          },
        },
        editNotification: {
          requiredField: "campo obrigatório",
          banner: {
            title:
              "As notificações serão enviadas para o e-mail cadastrado na plataforma da Flash",
            description:
              "Fique atento à sua caixa de entrada e à pasta de spam.",
          },
          footer: {
            leaveWithoutSave: "Sair sem salvar",
            save: "Salvar edição",
          },
          breadcrumbs: {
            notificationLabel: "Configurações de Gestão de fluxos",
            commentaryLabel: "Editar novos comentários",
            movimentationLabel: "Editar movimentação de pessoas",
            expirationLabel: "Editar expiração dos prazos de resposta",
            probationaryPeriodLabel:
              "Editar expiração do período de experiência",
          },
          movimentation: {
            title: "Editar movimentação de pessoas",
            description:
              "Edite as configurações de disparo das notificações de expiração dos prazos de resposta de gestão de fluxos",
            subtitle: "Configurações gerais",
            errorAtLeastOne:
              "É obrigatório que pelo menos uma das opções de notificações esteja selecionada.",
            successUpdate: "Notificações atualizada com sucesso",
            selectStep: "Selecionar etapas",
            notifyLeader: "Notificar líderes",
            notifyLeaderDescription:
              "Notifique os líderes vinculados ao candidato",
            notifyFollowers: "Notificar acompanhantes",
            notifyFollowersDescription:
              "Notifique os acompanhantes vinculados ao candidato",
          },
          commentary: {
            title: "Editar novos comentários",
            description:
              "Edite as configurações de disparo das notificações de movimentação de pessoas",
            subtitle: "Configurações gerais",
            errorAtLeastOne:
              "É obrigatório que pelo menos uma das opções de notificações esteja selecionada.",
            successUpdate: "Notificações atualizada com sucesso",
            selectStep: "Selecionar etapas",
            notifyLeader: "Notificar líderes",
            notifyLeaderDescription:
              "Notifique os líderes vinculados ao candidato",
            notifyFollowers: "Notificar acompanhantes",
            notifyFollowersDescription:
              "Notifique os acompanhantes vinculados ao candidato",
            notifyMentions: "Notificar mencionados",
            notifyMentionsDescription:
              "Notifique as pessoas mencionadas no campo de comentário do candidato",
          },
          expiration: {
            title: "Editar expiração dos prazos de resposta",
            description:
              "Edite as configurações de disparo das notificações de novos comentários",
            subtitle: "Configurações gerais",
            errorAtLeastOne:
              "É obrigatório que pelo menos uma das opções de notificações esteja selecionada.",
            successUpdate: "Notificações atualizada com sucesso",
            selectStep: "Selecionar etapas",
            notifyLeader: "Notificar líderes",
            notifyLeaderDescription:
              "Notifique os líderes vinculados ao candidato",
            notifyFollowers: "Notificar acompanhantes",
            notifyFollowersDescription:
              "Notifique os acompanhantes vinculados ao candidato",
            notifyBeforeDeadline: "Notificar antes do prazo expirar",
            notifyBeforeDeadlineDescription:
              "As notificações serão enviadas antes dos prazos de resposta expirarem",
            notifyBeforeInterval: "Dias de antecedência",
            notifyOnDeadline:
              "Notificar assim que o período de experiência expirar",
            notifyOnDeadlineDescription:
              "As notificações serão enviadas no momento em que prazos de resposta expirarem",
          },
          probationaryPeriod: {
            title: "Editar expiração do período de experiência",
            subtitle: "Configurações gerais",
            description:
              "Edite as configurações de disparo das notificações de expiração do período de experiência de gestão de fluxos",
            required: "campo obrigatório",
            banner: {
              title:
                "As notificações serão enviadas para o e-mail cadastrado na plataforma da Flash",
              description:
                "Fique atento à sua caixa de entrada e à pasta de spam.",
            },
            notifyLeader: "Notificar líderes",
            notifyLeaderDescription:
              "Notifique os líderes vinculados ao candidato",
            notifyFollowers: "Notificar acompanhantes",
            notifyFollowersDescription:
              "Notifique os acompanhantes vinculados ao candidato",
            notifyBeforeDeadline:
              "Notificar antes do período de experiência expirar",
            notifyBeforeDeadlineDescription:
              "As notificações serão enviadas antes dos prazos de resposta expirarem",
            notifyBeforeInterval: "Dias de antecedência",
            notifyOnDeadline:
              "Notificar assim que o período de experiência expirar",
            notifyOnDeadlineDescription:
              "As notificações serão enviadas no momento em que o período de experiencia expirar",
            errorAtLeastOne:
              "É obrigatório que pelo menos uma das opções de notificações esteja selecionada.",
            successUpdate: "Notificações atualizada com sucesso",
          },
        },
      },
      board: {
        lockedBlock:
          "Algumas colunas estão travadas. Para movimentar a coluna, desbloqueie as colunas travadas",
        hiddenBlocked:
          "Algumas colunas estão ocultas. Para movimentar a coluna, desbloqueie as colunas ocultas",
        cardInColumn:
          "Alguma das colunas possui cards. Por favor mova o card antes de mover a coluna",
        cardInLastColumn:
          "O card está na última coluna e não pode ser movimentado",
        cardsInOtherColumn: "Ocorreu um erro de conexão ao atualizar o card!",
        refreshBoardSuccess: "Board atualizado com sucesso!",
      },
      upload: {
        fileSelect: "Selecione seu arquivo",
      },
      addCandidateForm: {
        fullNamePlaceholder: "Digite o nome",
        legalNamePlaceholder: "Digite a razão social",
        cpfPlaceholder: "Digite o CPF",
        socialNamePlaceholder: "Digite o nome social",
        nationalityPlaceholder: "Informe a nacionalidade",
        cnpjPlaceholder: "Digite o CNPJ",
        phonePlaceholder: "Digite o telefone",
        emailPlaceholder: "Digite o e-mail",
        errorValidate: "Dado inválido",
        errorRequired: "Campo obrigatório",
        errorMaxLength: "Quantidade máxima de caracteres incorreta",
        errorMinLength: "Quantidade mínina de caracteres incorreta",
        errorPattern: "Use apenas letras",
        welcomeMesage:
          "Boas vindas a área do candidato. Aqui você cadastra todas as pessoas candidatas a suas vagas. Após o cadastro, lembre-se de atualizar o avanço de cada candidato no seu fluxo de admissão.",
        basicInfo: "Dados básicos",
        infoFill:
          "Preencha as informações do candidato conforme os campos abaixo.",
        newEmployeeError: "Alguns dados não estão corretos!",
        backButton: "Voltar",
        addCandidateButton: "Adicionar pessoa",
        inProgress: "Em andamento",
        candidateAddedSucess: "Candidato adicionado com sucesso",
        candidateAddedError:
          "Sentimos muito, houve um erro do nosso lado ao criar o novo candidato.",
        leaderAndFollowers: {
          title: "Líder e Acompanhantes",
          description:
            "Inclua pessoas que irão acompanhar o processo de admissão da pessoa",
          leaderPlaceholder: "Selecione líder",
          followersPlaceholder: "Selecione acompanhantes",
          invalidSearchTerm: "Digite ao menos 3 letras do nome da pessoa.",
          noOptionText: "Não foi encontrada nenhuma pessoa com esse nome.",
          loadingText: "Buscando colaborador...",
        },
      },
      documentTemplate: {
        section: {
          instructionBannerTitle: "Instruções de preenchimento",
          instructionBannerDescription:
            "Utilize o botão de mais opções na seção para incluir instruções de preenchimento para os documentos.",
        },
        fields: {
          instructionAddButton: "Incluir instruções de preenchimento",
          instructionEditButton: "Editar instruções de preenchimento",
        },
        instructionModal: {
          title: "Instruções de preenchimento",
          description:
            "Insira um texto guia de como encontrar ou como preencher o documento.",
          requiredText: "campo obrigatório",
          bodyTitle: "Informações",
          sectionLabel: "Seção",
          instructionPlaceholder: "Insira as instruções aqui...",
        },
      },
      candidate: {
        candidateTitle: "Candidato",
        candidateDescription:
          "Adicione as informações de recrutamento e gerencie o status da aprovação do seu candidato",
        recruitmentDataTitle: "Informações da candidatura",
        recruitmentDataDescription:
          "Cadastre e visualize as informações da vaga e do candidato",
        linkedinPlaceHolder: "Digite o link do linkedin",
        vacantJobLabel: "Link da vaga",
        vacantJobPlaceholder: "Digite o link da vaga",
      },
      documentValidation: {
        documentSend: "Documento não enviado",
        information: "Informações",
        notIncluded: "Não preenchido",
        documentStatus: "Status dos documentos",
        reasonField: "Por favor, escreva o motivo",
        maxCharacters: "Máximo 100 caracteres",
        resendDocNotification: "Por favor reenvie alguns documentos!",
        waitingCandidate: "Aguardando retorno do candidato",
        validatedSucess: "Documentos validados com sucesso",
        resendRequest: "Reenviar solicitação",
        next: "Próximo",
      },
      genericContent: {
        addTask: "Adicionar tarefa",
        taskPlaceholder: "Digite a tarefa",
        checklistTitle:
          "Crie um checklist para organizar e acompanhar a evolução de cada etapa dessa fase",
        taskDuplicated: "Existe uma tarefa com esse mesmo nome já criada",
        incorrectData: "Alguns dados não estão corretos!",
        checklistContractSignTitle:
          "Organize e gerencie abaixo sua lista de atividades e ganhe agilidade para acompanhar o fluxo de assinatura de contrato",
        checklistHiringExamTitle:
          "Organize e gerencie abaixo sua lista de atividades e ganhe agilidade para acompanhar as etapas do exame admissional",
        genericTitle: "Organize e gerencie abaixo sua lista de atividades",
      },
      historicContent: {
        welcomeSubtitle:
          "Dê boas-vindas ao seu novo colaborador. Use esse espaço para consultar todos os dados da admissão",
        filedSubtitle:
          "Use esse espaço para consultar as informações do candidato. Você também pode desarquivar esse perfil e adicionar em uma nova admissão.",
        documentPhoto: "Fotos do documento",
        documentUnsent: "Documento não enviado",
        notIncluded: "Não preenchido",
        linkedinPlaceholder: "Digite o link do linkedin",
        vacantJobLabel: "Link da vaga",
        vacantJobPlaceholder: "Digite o link da vaga",
        recruitmentDataDescription:
          "Cadastre e visualize as informações da vaga e do candidato",
        recruitmentDataTitle: "Informações da candidatura",
        candidateDocTitle: "Documentos do candidato",
        candidateDocSubtitle:
          "Lista de documentos solicitados ao candidato na admissão",
        dependentDocTitle: "Documentos de dependentes",
        dependentDocSubtitle:
          "Podem ser considerados dependentes cônjuges ou companheiros(as), filhos não emancipados de qualquer condição, menores de vinte e um anos ou inválidos.",
        proposalLetterTitle: "Carta proposta",
        proposalLetterDescription:
          "Visualize as informações e condições da carta proposta",
        proposalLetterArchives: "Arquivos",
        proposalLetterArchivesDescription: "Descricao de arquivos",
        admissionExamTitle: "Exame admissional",
        admissionExamDescription:
          "Revisão e aprovação da documentação das pessoas",
        signingContractTitle: "Assinatura do documento",
        signingContractDescription:
          "Verifique as informações sobre a assinatura do documento.",
      },
      proposalLetter: {
        rolePlaceholder: "Digite o cargo",
        roleLabel: "Cargo",
        departamentPlaceholder: "Digite o departamento",
        departamentLabel: "Departamento",
        hiringTypePlaceholder: "Selecione",
        hiringTypeLabel: "Tipo de vínculo",
        salaryPlaceholder: "R$0,00",
        salaryLabel: "Remuneração fixa",
        hiringDatePlaceholder: "Selecione",
        hiringDateLabel: "Data prevista de admissão",
        AcceptedProposal: "Proposta aceita com sucesso!",
        proposalLetterNotification: "Sua carta proposta chegou",
        candidateReturnAwaiting: "Aguardando retorno do candidato",
        letterDatesError: "Alguns dados não estão corretos!",
        bidField:
          "Preencha os campos abaixo de acordo com a oferta que será realizada ao candidato",
        bidDeclined: "Recusou a oferta",
        bidAccepted: "Aceitou a proposta",
      },
      exportData: {
        title: "Exportar relatório",
        description:
          "Exporte dados e documentos dos candidatos em uma o mais etapas desejadas. (Exporte informações de um candidato específico clicando no card do mesmo e selecionando o botão de ”Exportar relatório” na etapa de sua preferência.)",
        informationTitle: "Informações",
        informationDescription:
          "Selecione o tipo de informação que deseja exportar",
        dataOnlyTitle: "Apenas os dados",
        dataOnlyDescription:
          "Exportar apenas os dados do processo admissional.",
        documentOnlyTitle: "Apenas os documentos",
        documentOnlyDescription:
          "Exportar apenas os documentos enviados pelo candidato.",
        allDataTitle: "Dados e documentos",
        allDataDescription:
          "Exportar os dados admissionais e documentos enviados pelo candidato.",
        stagesTitle: "Etapas",
        stagesDescription: "Selecione as etapas do kanban que deseja exportar",
        stagesPlaceholder: "Selecione as etapas",
        stepperInfo: "Informações e etapas",
        stepperStages: "Etapas",
      },
      errors: {
        default: {
          title: "Página não encontrada",
          description:
            "A página que você esta procurando não existe ou não foi encontrada",
          buttonText: "Voltar para a página inicial",
        },
      },
    },
    error: {
      internalServerError:
        "Ocorreu um problema no sistema, por favor tente novamente mais tarde.",
    },
  },
}
