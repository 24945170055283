import { trpc } from "@api/client"
import { LinkButtonFitContent } from "@components/common/atoms/LinkButtonFitContent"
import { Flow } from "@customTypes/flow"
import { MassCandidateProcess } from "@customTypes/massCandidate"
import {
  Button,
  Icons,
  IconTypes,
  LinkButton,
  Loader,
  Typography,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2"
import { Grid } from "@mui/material"
import React, { useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useTheme } from "styled-components"
import { Header } from "../../../components"
import dispatchToast from "../../../utils/dispatchToast"
import { Importing, SendData } from "./screen"
import {
  Container,
  FooterButtonContainer,
  localStyles,
  SpinnerContainer,
  StyledDescription,
  StyledFooterBar,
  StyledPageContainer,
  StyledTitle,
  Title,
} from "./styles"

export enum Steps {
  SendData = 0,
  Importing = 1,
}
export interface IMassCandidateContext {
  flow: Flow
  process?: MassCandidateProcess
  refetchProcess: () => void
  setFooterButtons: (buttons: FooterButtonsType) => void
}

export const massCandidateContext = React.createContext<IMassCandidateContext>({
  flow: {} as Flow,
  refetchProcess: () => {},
  setFooterButtons: () => {},
})

const steps = [
  {
    title: "Envie os dados",
    text: "Envie os dados básicos dos candidatos por planilha ou arquivo de texto para realizar o cadastro em lote.",
  },
  {
    title: "Status das ações",
    text: "Acompanhe aqui o status do cadastro. Esse processo pode demorar alguns minutos, mas você pode fechar essa página e continuar usando a plataforma normalmente!",
  },
]

const routes = [
  {
    label: "Admissão",
    route: `/flows/hiring`,
  },
  {
    label: "Cadastrar candidatos",
  },
]

type ButtonFooterProps<T> = {
  props: Partial<T>
  text: string
  icon: IconTypes
  iconSide?: "right" | "left"
}

export type FooterButtonsType = {
  primary: ButtonFooterProps<React.ComponentProps<typeof Button>>
  secondary?: ButtonFooterProps<React.ComponentProps<typeof Button>>
  tertiary?: Omit<
    ButtonFooterProps<React.ComponentProps<typeof LinkButton>>,
    "icon"
  >
}

const AddMassCandidates = () => {
  const [footerButtons, setFooterButtons] = React.useState<FooterButtonsType>()

  const { flowId = "" } = useParams()
  const navigate = useNavigate()
  const isMobile = useMediaQuery("(max-width: 768px)")
  const theme = useTheme()

  const { data: flow, isInitialLoading: isFlowLoading } =
    trpc.flow.getOnlyFlowById.useQuery(
      { flowId },
      {
        enabled: !!flowId,
        onError: () => {
          dispatchToast({
            content: "Houve um problema ao buscar informações do fluxo",
            type: "error",
          })
          navigate("/flows/hiring")
        },
      },
    )

  const {
    data: process,
    refetch: refetchProcess,
    isInitialLoading: isLoadingProcess,
  } = trpc.massCandidate.findMassCandidateProcessByFlow.useQuery(
    { flowId },
    { enabled: !!flow },
  )

  const value: IMassCandidateContext = useMemo(
    () => ({
      flow: flow as Flow,
      process,
      refetchProcess,
      setFooterButtons: (buttons: FooterButtonsType) =>
        setFooterButtons(buttons),
    }),
    [flow, process],
  )

  const currentStep = !process ? Steps.SendData : Steps.Importing

  const step = React.useMemo(() => {
    switch (currentStep) {
      case Steps.Importing:
        return <Importing />
      case Steps.SendData:
        return <SendData />
      default:
        throw new Error("No step found")
    }
  }, [currentStep])

  if (!flow || isFlowLoading || isLoadingProcess) {
    return (
      <SpinnerContainer>
        <Loader variant="primary" size="large" />
      </SpinnerContainer>
    )
  }

  return (
    <massCandidateContext.Provider value={value}>
      <Container>
        <Header
          breadcrumbs={routes}
          steps={["Envie os Dados", "Status das ações"]}
          currentStep={currentStep}
        />

        <StyledPageContainer>
          <Title variant="headline6" style={{ marginTop: "40px" }}>
            Cadastrar candidatos
          </Title>

          <Grid container>
            <Grid item sm={12} md={5} lg={3}>
              <div style={{ marginRight: "24px", marginTop: "40px" }}>
                <StyledTitle variant="headline7">
                  {steps[currentStep].title}
                </StyledTitle>
                <div style={{ marginTop: "16px" }}>
                  <StyledDescription variant="body3">
                    {steps[currentStep].text}
                  </StyledDescription>
                </div>
              </div>
            </Grid>

            {step}
          </Grid>
        </StyledPageContainer>

        <StyledFooterBar>
          <FooterButtonContainer
            justifyContent={
              footerButtons?.tertiary ? "space-between" : "flex-end"
            }
          >
            {footerButtons?.tertiary ? (
              <LinkButtonFitContent
                color={theme.colors.neutral[50]}
                variant="primary"
                style={localStyles.cancelLinkButton}
                {...footerButtons.tertiary.props}
              >
                {footerButtons.tertiary.text}
              </LinkButtonFitContent>
            ) : (
              <></>
            )}

            <div style={{ display: "flex", gap: 20 }}>
              {footerButtons?.secondary ? (
                <Button
                  variant="secondary"
                  size={isMobile ? "small" : "large"}
                  style={{ alignSelf: "center" }}
                  {...footerButtons.secondary.props}
                >
                  {footerButtons?.primary.props.loading ? (
                    <Loader size="small" variant="secondary" />
                  ) : (
                    <>
                      {footerButtons.secondary?.iconSide === "left" &&
                      footerButtons.secondary.icon ? (
                        <Icons
                          name={footerButtons.secondary.icon}
                          fill="transparent"
                        />
                      ) : (
                        <></>
                      )}

                      <Typography variant="body3" style={{ fontWeight: 700 }}>
                        {footerButtons.secondary.text}
                      </Typography>

                      {!footerButtons.secondary.iconSide ||
                      (footerButtons.secondary.iconSide === "right" &&
                        footerButtons.secondary.icon) ? (
                        <Icons
                          name={footerButtons.secondary.icon}
                          fill="transparent"
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Button>
              ) : (
                <></>
              )}

              <Button
                size={isMobile ? "small" : "large"}
                variant="primary"
                style={{ marginLeft: "24px", alignSelf: "center" }}
                {...footerButtons?.primary.props}
              >
                {footerButtons?.primary.props.loading ? (
                  <Loader size="small" variant="secondary" />
                ) : (
                  <>
                    {footerButtons?.primary.iconSide === "left" &&
                    footerButtons?.primary.icon ? (
                      <Icons
                        name={footerButtons?.primary.icon}
                        fill="transparent"
                      />
                    ) : (
                      <></>
                    )}

                    <Typography variant="body3" style={{ fontWeight: 700 }}>
                      {footerButtons?.primary.text}
                    </Typography>

                    {footerButtons?.primary.icon &&
                    (!footerButtons?.primary.iconSide ||
                      footerButtons?.primary.iconSide === "right") ? (
                      <Icons
                        name={footerButtons?.primary.icon}
                        fill="transparent"
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Button>
            </div>
          </FooterButtonContainer>
        </StyledFooterBar>
      </Container>
    </massCandidateContext.Provider>
  )
}

export default AddMassCandidates
