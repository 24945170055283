import { useTranslation } from "react-i18next"
import { ConfirmationModal } from "../../../../components"
import { Flow } from "../../../../types"
import dispatchToast from "../../../../utils/dispatchToast"
import { trpc } from "@api/client"

export const ModalConfirmArchive = ({
  flow,
  cardId,
  cardVersion,
  isOpen,
  handleClose,
}: {
  flow: Flow
  cardId: string
  cardVersion: number
  isOpen: boolean
  handleClose: () => void
}) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page.home.modalConfirmArchive",
  })

  const { mutateAsync: moveCardColumn, isLoading } =
    trpc.card.changeHiringColumn.useMutation()

  const archivedColumn = flow.columns?.find(
    (column) => column.name === "Arquivado",
  )

  const handleSubmit = async () => {
    try {
      await moveCardColumn({
        flowCardId: cardId,
        newColumnId: archivedColumn?._id || "",
        newPosition: 0,
        version: cardVersion,
      })
      dispatchToast({
        content: "Candidato arquivado com sucesso!",
        type: "success",
      })
    } catch (err: any) {
      dispatchToast({
        content: "Houve um problema ao arquivar o candidato",
        type: "error",
      })
      console.error(err)
    }
  }
  return (
    <ConfirmationModal
      isOpen={isOpen}
      confirmButton={{
        text: t("button.submit"),
        onClick: handleSubmit,
        isLoading: isLoading,
      }}
      onClose={handleClose}
      title={t("title")}
      description={t("description")}
    />
  )
}
