import { useState } from "react"

import { Skeleton } from "@mui/material"

import { LinkButtonFitContent } from "@components/common/atoms/LinkButtonFitContent"
import { IconButton, Icons, Tag } from "@flash-tecnologia/hros-web-ui-v2"

import dispatchToast from "../../../utils/dispatchToast"
import {
  ShareText,
  StyledPOneLine,
  UploadFileName,
  UploadIconContainer,
  UploadPreviewArea,
  UploadPreviewContainer,
  localStyles,
} from "./styles"
import { useTheme } from "styled-components"

export const UploadPreview = ({
  uploadItem,
  onRemove,
  tag = { variant: "primary", label: "" },
  hasShareLink = false,
}: {
  uploadItem: any
  onRemove?: any
  hasShareLink?: boolean
  tag?: {
    variant: "primary" | "secondary" | "tertiary" | "error" | "disabled"
    label: string
  }
}) => {
  const theme = useTheme()
  const [loadingOpenRemoveFile, setLoadingOpenRemoveFile] = useState<any>({
    loading: false,
    key: "",
  })

  const isArray = Array.isArray(uploadItem)
  const array = !isArray ? [uploadItem] : (uploadItem as any)

  return array?.map(({ key = "", value = "", path = "" }, index) => {
    const lastIndexName = key ? key.lastIndexOf("/") : ""
    const nameOfArchive =
      key && lastIndexName
        ? key.substring(lastIndexName + 1)
        : "Nome não identificado"

    return (
      <div
        key={`${key}-preview`}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div key={index} style={{ marginTop: index === 0 ? "24px" : "0px" }}>
          {loadingOpenRemoveFile?.loading &&
          loadingOpenRemoveFile?.key === key ? (
            <Skeleton variant="rectangular" width="100%" height="114px" />
          ) : (
            <div
              role="presentation"
              onClick={async (e) => {
                e.stopPropagation()
              }}
            >
              <UploadPreviewContainer hasShareLink={!!hasShareLink}>
                <UploadPreviewArea>
                  <UploadIconContainer>
                    <Icons name={"IconFile"} style={{ fill: "transparent" }} />
                  </UploadIconContainer>
                  <UploadFileName>
                    <StyledPOneLine numberOfLines={1}>
                      {nameOfArchive}
                    </StyledPOneLine>
                  </UploadFileName>
                  {tag?.label && tag?.variant ? (
                    <Tag style={{ cursor: "auto" }} variant={tag?.variant}>
                      {tag?.label}
                    </Tag>
                  ) : (
                    <></>
                  )}
                </UploadPreviewArea>

                <div style={{ display: "flex" }}>
                  {onRemove ? (
                    <IconButton
                      size="medium"
                      variant="filled"
                      icon="IconTrash"
                      onClick={async (e) => {
                        e.stopPropagation()
                        e.preventDefault()

                        setLoadingOpenRemoveFile({ loading: true, key: key })
                        onRemove({ key, value, path })
                        setLoadingOpenRemoveFile({ loading: false, key: key })
                      }}
                    />
                  ) : (
                    <></>
                  )}

                  <IconButton
                    size="medium"
                    variant="filled"
                    icon="IconDownload"
                    onClick={async () => {
                      setLoadingOpenRemoveFile({ loading: true, key: key })

                      const readFile = await fetch(value)
                      const fileToArrayBuffer = await readFile.arrayBuffer()
                      const res = URL.createObjectURL(
                        new Blob([fileToArrayBuffer]),
                      )
                      const a = document.createElement("a")
                      a.href = res
                      a.download = nameOfArchive
                      a.click()

                      setLoadingOpenRemoveFile({ loading: false, key: key })
                    }}
                    style={{ marginLeft: "10px" }}
                  />
                </div>
              </UploadPreviewContainer>
            </div>
          )}
        </div>
        {hasShareLink ? (
          <div style={{ marginTop: "4px" }}>
            <LinkButtonFitContent
              color={theme.colors.neutral[40]}
              variant="primary"
              style={localStyles.linkButton}
              onClick={async () => {
                navigator.clipboard.writeText(value)
                dispatchToast({
                  content: "Link copiado",
                  type: "success",
                })
              }}
            >
              <>
                <ShareText variant="caption" style={{ fontWeight: 700 }}>
                  Compartilhar link
                </ShareText>
                <Icons
                  name="IconLink"
                  size={16}
                  style={{
                    fill: "transparent",
                    color: "#6B5B66",
                  }}
                />
              </>
            </LinkButtonFitContent>
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  })
}
