export enum SignedUrlCommandEnum {
  PUT = "put",
  GET = "get",
}

export type FileType = {
  path: string
  name?: string
  size?: number
}

export enum StorageGatewayModules {
  Hiring = "hiring",
}
