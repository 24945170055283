import { IconButtonProps } from "@flash-tecnologia/hros-web-ui-v2"
import * as SC from "./styles"

export interface IconButtonStyledProps {
  iconColor?: string
  backgroundColor?: string
}

/**
 * @deprecated - use PillButon
 * https://github.com/flash-tecnologia/hros-web-ui-v2/blob/main/src/components/PillButton/index.tsx
 */
export const IconButtonStyled = (
  props: IconButtonProps & IconButtonStyledProps,
) => {
  return (
    <SC.IconButton
      {...props}
      style={{
        ...props.style,
        ...(props.backgroundColor && {
          backgroundColor: props.backgroundColor,
        }),
      }}
    />
  )
}
