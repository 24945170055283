import { Checkbox, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { Accordion } from "@mui/material"
import styled from "styled-components"
import { DeprecatedModal } from "../../common"

const CloseRightContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-top: 21px;
  padding-right: 21px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    display: none;
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    flex-direction: column;
    overflow: auto;
  }
`

const RightContainer = styled.div`
  width: 100%;

  overflow: hidden auto;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    overflow: visible;
  }
`

const Title = styled.span`
  font-family: Cabinet Grotesk;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #53464f;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

const IconContainer = styled.div`
  display: inline-flex;
  cursor: pointer;
  padding: 16px;
  border: 1px solid #e8e3e6;
  border-radius: 50%;

  &:hover {
    opacity: 0.7;
  }
`

const TagContainer = styled.div`
  display: flex;

  padding: 4px 12px;

  background: rgba(254, 43, 143, 0.18);
  border-radius: 24px;
  margin-top: 4px;
  margin: 10px;
`

const ConfirmButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  background-color: #fb2a81;

  border-radius: 4px 16px 4px 4px;
  margin-top: 80px;
  margin-bottom: 50px;

  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    pointer-events: none;
  }

  p {
    color: #fff;
    font-weight: 700;
  }

  &:hover {
    opacity: 0.5;
  }
`

const CollapseContainer = styled.div<{ expanded: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;

  cursor: pointer;
  border: 1px solid #e8e3e6;
  border-radius: 8px;
  margin-top: 16px;

  ${({ expanded }) =>
    expanded
      ? "border-bottom: 0px; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;"
      : ""}

  &:hover {
    opacity: 0.7;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 48px 0px 48px;
  text-align: left;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

const FlexSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const HeaderEditContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

const TaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  border: 1px solid #b9acb4;
  padding: 19px 16px;
  border-radius: 12px;
  margin-bottom: 24px;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column;
  }
`

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  background: #f20d7a;
  border-radius: 12px;
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
  }
  &:hover {
    opacity: 0.5;
  }
`

const StyledContainedButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;

  border: 1px solid #fe2b8f;
  border-radius: 12px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #fe2b8f;
  }
  &:hover {
    opacity: 0.5;
  }
`

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-left: 4px;

  color: #83727d;

  :last-child {
    margin-bottom: 0px;
  }
`

const StepTitle = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.primary};
  max-width: 213px;
  margin: 0;
  padding: 0;
  margin-bottom: 22px;
`

const SubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #856f7e;
  max-width: 348px;
  margin: 0;
  padding: 0;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    margin-bottom: 40px;
  }
`

const AccordionChildren = styled.div`
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    text-align: left;
  }
`

const AccordionChildrenItems = styled.div`
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0px;
  }
`

const AccordionElement = styled.div<{ hasId: boolean }>`
  border-bottom: 1px solid #ebe5e9;
  opacity: ${({ hasId }) => (hasId ? 1 : 0.5)};
  pointer-events: ${({ hasId }) => (hasId ? "auto" : "none")};
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const SectionTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #856f7e;
  margin-top: 44px;
  margin-bottom: 24px;
`

const SectionSubTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #856f7e;
  margin-bottom: 24px;
`

const PreviewTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
`

const PreviewImageContainer = styled.div<{ url?: string }>`
  width: 45px;
  height: 45px;
  background-color: #ccc;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
`

const StyledPOneLine = styled.p`
  display: block;
  display: -webkit-box;
  line-height: 2;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`

const PreviewDocWithoutContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
  padding: 12px 24px 12px 12px;

  border: 1px solid #e8e3e6;
  border-radius: 4px;

  pointer-events: none;
  width: 100%;

  max-width: 300px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    max-width: 100%;
  }
`

const PreviewDocContainer = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  padding: 12px 24px 12px 12px;

  border: 1px solid #e8e3e6;
  border-radius: 4px;

  width: 100%;

  max-width: 300px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    max-width: 100%;
  }
`

const StyledModal = styled(DeprecatedModal)`
  && {
    .modal-container {
      border-radius: 16px;
      max-width: 95vw;
      max-height: 95vh;
      overflow: hidden;
    }
    .modal-content-area {
      padding: 0px;
      width: 95vw;
      height: 95vh;
    }
  }
`

const StyledAContainer = styled.a`
  cursor: pointer;
`

const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    border: 1px solid #ebe6e9;
    border-radius: 8px;
    box-shadow: none;

    margin-bottom: 40px;

    &::before {
      background-color: transparent;
    }
  }
  .MuiButtonBase-root {
    padding: 40px 44px;
  }
`

const StyledContainerIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 10px;

  width: 48px;
  height: 48px;

  background: ${({ theme }) => theme.colors.neutral[95]};
  border-radius: 40px;
`

const AccordionTitle = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[30]};
`

const AccordionDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

const ArchiveContainer = styled.div`
  margin-bottom: 24px;
  :first-child {
    margin-top: 12px;
  }
`

const ListContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 24px;
`

const ChildrenContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

const UploadPreview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
  padding-top: 32px;
  border: 1px solid #ebe6e9;
  border-radius: 10px;
`

const UploadPreviewArea = styled.div`
  display: flex;
  align-items: center;
`

const UploadIconContainer = styled.div`
  margin-right: 7px;
  border-radius: 4px;
  display: flex;
`

const UploadFileName = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #3b2e37;
`

const Divider = styled.div`
  border: 1px solid #ebe6e9;
  height: 1px;
  width: 100%;
  margin: 40px 0;
`

const ShareText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

const ChecklistItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: #53464f;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
`

const StyledCheckbox = styled(Checkbox)`
  &.MuiButtonBase-root {
    padding: 0px;
    margin-right: 10px;
  }
`

export const localStyles = {
  linkButton: {
    alignSelf: "center",
  },
}

export {
  AccordionChildren,
  AccordionChildrenItems,
  AccordionDescription,
  AccordionElement,
  AccordionTitle,
  ArchiveContainer,
  ButtonContainer,
  ChecklistItem,
  ChildrenContainer,
  CloseRightContainer,
  CollapseContainer,
  ConfirmButton,
  Container,
  ContentContainer,
  Divider,
  ErrorContainer,
  FlexColumn,
  FlexSpaceBetween,
  HeaderContainer,
  HeaderEditContainer,
  IconContainer,
  ListContainer,
  PreviewDocContainer,
  PreviewDocWithoutContainer,
  PreviewImageContainer,
  PreviewTextContainer,
  RightContainer,
  SectionSubTitle,
  SectionTitle,
  ShareText,
  StepTitle,
  StyledAccordion,
  StyledAContainer,
  StyledButton,
  StyledCheckbox,
  StyledContainedButton,
  StyledContainerIcon,
  StyledModal,
  StyledPOneLine,
  SubContainer,
  SubTitle,
  TagContainer,
  TaskContainer,
  Title,
  UploadFileName,
  UploadIconContainer,
  UploadPreview,
  UploadPreviewArea,
}
