import { S3File } from "./card"

export enum SignType {
  candidate = "candidate",
  company = "company",
  witness = "witness",
  institution = "institution",
  employee = "person",
}

export enum SignStatus {
  pending = "Pending",
  signed = "Signed",
}

export enum SignatureSignStatus {
  pending = "Pending",
  signed = "Signed",
  external = "External",
}

export type DumbSignInfoParam = { name?: string; email: string; phone?: string }
export type SignInfoParam = { id: string } & DumbSignInfoParam

export type Signature = {
  candidateId?: string
  employeeId?: string
  name: string
  email: string
  phone: string
  type: SignType
  status: SignatureSignStatus
  token?: string
  signedAt?: string
  signatureFile?: any
  ip?: string
  confirmationInfo?: {
    name?: string
    documentNumber: string
    email: string
  }
}

export interface ContractOutput extends S3File {
  version: number
}

export interface Contract {
  _id: string
  companyId: string
  flowCardId: string
  contractDuration?: string
  category: "hiring" | "resignation"
  template: S3File[]
  modelContract?: { custom: string; origin: string }[]
  status: SignStatus
  signature: Signature[]
  contractOutput: ContractOutput[]
  // bff prop
  lastContractOutput?: S3File
}
