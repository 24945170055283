import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { HiringCard } from "../../../types"
import { StatusCard } from "../../common"
import {
  CardStatusContainer,
  CardStatusColumn,
  CardStatusWrapper,
} from "./styles"
import { getColumnStatusOptions } from "../../../mock/status"

export const CardStatus = ({ card }: { card: HiringCard }) => {
  const columnStatus = getColumnStatusOptions(card.columnId)
  const statusInfo = columnStatus.status.find(
    (status) => status.name === card.status,
  )

  return (
    <CardStatusContainer>
      {statusInfo && (
        <CardStatusColumn>
          <Typography variant="body4" variantColor="#83727D">
            Status
          </Typography>

          <CardStatusWrapper>
            <StatusCard
              backgroundColor={statusInfo.colors.background}
              textColor={statusInfo.colors.text}
              description={statusInfo.name}
            />
          </CardStatusWrapper>
        </CardStatusColumn>
      )}

      {card.candidate.leader && (
        <CardStatusColumn>
          <Typography variant="body4" variantColor="#83727D">
            Líder
          </Typography>
          <CardStatusWrapper>
            <StatusCard description={card.candidate.leader.name} />
          </CardStatusWrapper>
        </CardStatusColumn>
      )}

      {card?.followers && card.followers.length > 0 && (
        <CardStatusColumn>
          <Typography variant="body4" variantColor="#83727D">
            Acompanhantes
          </Typography>
          <CardStatusWrapper>
            {card.followers
              ?.slice(0, 2)
              .map((follower, idx) => (
                <StatusCard key={idx} description={follower.name} />
              ))}

            {card.followers.length > 2 && (
              <StatusCard
                description={`+${(card.followers.length - 2).toString()}`}
              />
            )}
          </CardStatusWrapper>
        </CardStatusColumn>
      )}
    </CardStatusContainer>
  )
}
