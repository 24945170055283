import styled from "styled-components"

const StyledFooterBar = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.neutral.light.pure};

  padding: 16px 24px;
  z-index: 99;

  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const localStyles = {
  cancelLinkButton: { alignSelf: "center" },
}

export { localStyles, StyledButtonContainer, StyledFooterBar }
