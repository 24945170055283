export enum ReportTypeEnum {
  HIRING = "hiring",
  RESIGNATION = "resignation",
}

export enum ReportPayrollProviderEnum {
  TOTVS_RM = "TOTVS_RM",
  LG = "LG",
  QUESTOR = "QUESTOR",
}
