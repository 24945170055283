import { CircleIconContainer, WizardCardLink } from "../../../components"
import { Flow, HiringCard } from "../../../types"
import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import React from "react"
import dispatchToast from "../../../utils/dispatchToast"
import { Container, Row } from "./styles"
import { hiringCardContext } from "../../../components/screen/CardTemplate/Hiring"
import { useTheme } from "styled-components"
import { SendRequestContent, ValidateContent } from "./Content"
import { trpc } from "@api/client"
import i18n from "@i18n"

export const GenericContent = ({
  card,
  flow,
}: {
  card: HiringCard
  flow: Flow
}) => {
  const { setResetStep } = React.useContext(hiringCardContext)
  const theme = useTheme()
  const utils = trpc.useUtils()

  const { mutateAsync: resetAso, isLoading: isLoadingResetAso } =
    trpc.admissionalExam.resetAdmissionalExam.useMutation({
      onError: (err) => {
        dispatchToast({
          content:
            err.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
          type: "error",
        })
      },
      onSuccess: () => {
        utils.card.getHiringCardById.invalidate()
        dispatchToast({
          content: i18n.t("page.hiring.admissionalExam.toastResetStep"),
          type: "success",
        })
      },
    })

  React.useEffect(() => {
    setResetStep({
      canReset: !!card.admissionalExam?.status,
      confirmCallback: async () => {
        await resetAso({
          flowCardId: card._id,
        })
      },
      isLoading: isLoadingResetAso,
    })
  }, [card])

  const findEmailLink = card.emailLink?.find(
    (e) => !e.expired && ["resendAsoRequest", "asoRequest"].includes(e.name),
  )

  console.log(findEmailLink)
  return (
    <Container>
      {["sent", "resent"].includes(card.admissionalExam?.status || "") && (
        <Row style={{ gap: 0, textAlign: "center", alignItems: "center" }}>
          <CircleIconContainer
            backgroundColor={theme.colors.secondary[95]}
            color={theme.colors.secondary[50]}
          >
            <Icons name="IconCheck" fill="transparent" />
          </CircleIconContainer>
          <Typography
            variant="headline6"
            variantColor={theme.colors.neutral[30]}
          >
            Solicitação enviada!
          </Typography>
          <Typography
            variant="body2"
            variantColor={theme.colors.neutral[50]}
            style={{ maxWidth: 500, marginTop: 16 }}
          >
            Agora é só aguardar o anexo do ASO. Confira abaixo o agendamento
            encaminhado.
          </Typography>
        </Row>
      )}

      {findEmailLink && (
        <Row>
          <WizardCardLink
            multiplesEmailLinks={[{ emailLink: findEmailLink }]}
          />
        </Row>
      )}

      {!card.admissionalExam?.status ? (
        <SendRequestContent card={card} flow={flow} />
      ) : (
        <ValidateContent card={card} />
      )}
    </Container>
  )
}
