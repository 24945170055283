import { Typography, TextField } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import { Container } from "./styles"
import * as yup from "yup"
import { useFormik } from "formik"
import React, { useContext } from "react"
import { StepsEnum, massSignatureContext } from "../.."
import { useNavigate } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { globalContext } from "@context/GlobalContext"
import { trpc } from "@api/client"
import dispatchToast from "@utils/dispatchToast"
import { validateCPF } from "@utils/index"
import { Box } from "@components/common/atoms/Box"
import { useTranslation } from "react-i18next"
import i18n from "@i18n"

const requiredField = i18n.t(
  "page.hiring.massSignature.signing.validation.requiredField",
)
const validDocumentNumber = i18n.t(
  "page.hiring.massSignature.signing.validation.validDocumentNumber",
)
const validEmail = i18n.t(
  "page.hiring.massSignature.signing.validation.validEmail",
)

const validationSchema = yup.object({
  name: yup.string().required(requiredField),
  documentNumber: yup
    .string()
    .test("validCPF", validDocumentNumber, validateCPF)
    .required(requiredField),
  email: yup.string().email(validEmail).required(requiredField),
})

export const MassSignatureSigning = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const {
    validCandidates,
    setFooter,
    setCurrentStep,
    confirmationData,
    setConfirmationData,
  } = React.useContext(massSignatureContext)
  const queryClient = useQueryClient()

  const { mutateAsync: signManyContracts, isLoading: isMassSignatureLoading } =
    trpc.massSignature.signManyContracts.useMutation()

  const { employee } = useContext(globalContext)

  const [t] = useTranslation("translations", {
    keyPrefix: "page.hiring.massSignature.signing",
  })

  const formik = useFormik({
    initialValues: {
      name: confirmationData?.name ?? employee?.name ?? "",
      documentNumber:
        confirmationData?.documentNumber ?? employee?.documentNumber ?? "",
      email: confirmationData?.email ?? employee?.email ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setConfirmationData(values)
      try {
        const result = await signManyContracts({
          flowCardIds: validCandidates.map((c) => c.flowCardId),
          confirmationInfo: {
            name: values.name,
            documentNumber: values.documentNumber,
            email: values.email,
          },
        })

        queryClient.cancelQueries(["flows"])
        navigate("/flows/hiring")
        dispatchToast({
          content:
            result.contractsSigned > 1
              ? t("toast.successMany", {
                  replace: { contractsSigned: result.contractsSigned },
                })
              : t("toast.successOne"),
          type: "success",
        })
      } catch (err: any) {
        dispatchToast({
          content: err?.message ?? t("toast.error"),
          type: "error",
        })
      }
    },
    validateOnBlur: true,
    validateOnChange: true,
  })

  React.useEffect(() => {
    setFooter({
      cancelButton: {
        text: t("buttons.exit"),
        onClick: () => navigate("/flows/hiring"),
      },
      secondaryButton: {
        text: t("buttons.return"),
        icon: "IconArrowLeft",
        iconPosition: "left",
        onClick: () => setCurrentStep(StepsEnum.Contract),
      },
      primaryButton: {
        text: t("buttons.sign"),
        icon: "IconArrowRight",
        iconPosition: "right",
        loading: isMassSignatureLoading,
        onClick: () => formik.handleSubmit(),
      },
    })
  }, [formik, isMassSignatureLoading])

  return (
    <Container>
      <Box flexDirection="row" justifyContent="space-between">
        <Typography variant="headline8" variantColor={theme.colors.neutral[30]}>
          {t("personalData")}
        </Typography>
        <Typography variant="body3" variantColor={theme.colors.neutral[30]}>
          <span style={{ color: theme.colors.feedback.error[40] }}>*</span>{" "}
          {t("requiredField")}
        </Typography>
      </Box>

      <Box flexDirection="column" gap="xs1" $mt="xs">
        <div>
          <TextField
            type={"text"}
            label={t("fields.name")}
            name={"name"}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name?.toString()}
            required
            fullWidth
          />
        </div>
        <div>
          <TextField
            type={"text"}
            label={t("fields.documentNumber")}
            name={"documentNumber"}
            imaskProps={{ mask: "000.000.000-00" }}
            value={formik.values.documentNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.documentNumber &&
              Boolean(formik.errors.documentNumber)
            }
            helperText={
              formik.touched.documentNumber &&
              formik.errors.documentNumber?.toString()
            }
            required
            fullWidth
          />
        </div>
        <div>
          <TextField
            type={"text"}
            label={t("fields.email")}
            name={"email"}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email?.toString()}
            required
            fullWidth
          />
        </div>
      </Box>
    </Container>
  )
}
