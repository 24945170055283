import { SectionLoading } from "../../../screen/SectionLoading"

import {
  Button,
  Icons,
  Loader,
  Typography,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2"

import { LinkButtonFitContent } from "@components/common/atoms/LinkButtonFitContent"
import { useTheme } from "styled-components"
import { StyledButtonContainer, StyledFooterBar, localStyles } from "./style"

const FooterBar = ({
  steps,
  currentStep,
  loadingUrl = [],
  isLoading = false,
  cancelHandleClick,
  previousHandleClick,
  nextHandleClick,
}: {
  steps: any[]
  isLoading?: boolean
  currentStep: number
  loadingUrl?: string[]
  previousHandleClick: any
  cancelHandleClick?: any
  nextHandleClick: any
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)")
  const theme = useTheme()
  return (
    <SectionLoading url={loadingUrl}>
      {({ loading }) => (
        <StyledFooterBar>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <LinkButtonFitContent
              color={theme.colors.neutral[50]}
              variant="primary"
              style={localStyles.cancelLinkButton}
              onClick={() => {
                cancelHandleClick()
              }}
            >
              Cancelar
            </LinkButtonFitContent>
            <StyledButtonContainer>
              <Button
                size={isMobile ? "small" : "large"}
                variant="secondary"
                disabled={currentStep - 1 < 0}
                onClick={previousHandleClick}
                style={{ alignSelf: "center" }}
              >
                <Icons name="IconArrowLeft" />
                <Typography variant="body3" style={{ fontWeight: 700 }}>
                  Voltar
                </Typography>
              </Button>
              <Button
                size={isMobile ? "small" : "large"}
                variant="primary"
                disabled={loading || isLoading || currentStep > steps.length}
                style={{ marginLeft: "24px", alignSelf: "center" }}
                onClick={nextHandleClick}
              >
                {loading || isLoading ? (
                  <Loader size="extraSmall" variant="secondary" />
                ) : currentStep + 1 === steps.length ? (
                  <>
                    <Typography variant="body3" style={{ fontWeight: 700 }}>
                      Concluir
                    </Typography>
                    <Icons name="IconCheck" fill={"transparent"} />
                  </>
                ) : (
                  <>
                    <Typography variant="body3" style={{ fontWeight: 700 }}>
                      Continuar
                    </Typography>
                    <Icons name="IconArrowRight" />
                  </>
                )}
              </Button>
            </StyledButtonContainer>
          </div>
        </StyledFooterBar>
      )}
    </SectionLoading>
  )
}

export { FooterBar }
